.essentiel_entries {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  &_wrapper {
    width: 100%;
  }

  &__list-item {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 5px;
    transition: all 0.2s ease-in-out;

    @media (max-width: 768px) {
      padding: 2px;
    }

    &:hover,
    &:focus {
      background-color: $vnf-blue-a10;
    }

    &-content {
      width: 100%;
    }

    h3 {
      @include essentielSubtitle();
      margin-bottom: 5px;

      @media (max-width: 768px) {
        font-size: 1.8rem;
      }
    }

    p {
      @include paragraph();
      margin: 0;

      @media (max-width: 768px) {
        font-size: 1.6rem;
      }

      img {
        display: none;
      }
    }

    a {
      display: flex;
      flex-direction: row;
      gap: 14px;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      &[rel="external"] {
        &:after {
          content: "";
          display: block;
          background-image: url("/assets/images/icons/external-link.svg");
          background-size: 14px 14px;
          background-repeat: no-repeat;
          width: 15px;
          height: 14px;
          margin-top: 1px;
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 1px;
      background-color: $vnf-neutral-200;
      width: calc(100% - 40px);
      bottom: -15px;
      left: 30px;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }

  &.diamond {
    .essentiel_entries__list-item {
      &:before {
        content: url("/assets/images/icons/icons-shapes/diamond.svg");
        display: block;
      }
    }
  }

  &.triangle {
    .essentiel_entries__list-item {
      &:before {
        content: url("/assets/images/icons/icons-shapes/triangle.svg");
        display: block;
        padding: 3px 0 0 0;
      }
    }
  }


  &.circle {
    .essentiel_entries__list-item {
      &:before {
        content: url("/assets/images/icons/icons-shapes/circle.svg");
        display: block;
      }
    }
  }
}
