.child-page {
  width: 100%;

  @media (max-width: 768px) {
    padding-top: 83px;
  }

  app-slider-outils {
    margin-right: 100%;
  }

  .col-fullwidth {
    max-width: 800px;
    margin-right: 100%;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;

    .share-page {
      position: relative;

      .options {
        min-width: 300px;
        transform: translate(calc(-100% - 30px), 100% );

        @media (max-width: 768px) {
          transform: unset;
          min-width: unset;
        }
      }
    }
  }
}

app-page-fille {
  position: relative;

  app-breadcrumb {
    + app-flexible-text {
      img {
        height: 386px;
        object-fit: cover;
        max-width: 740px;
      }

      .flexible-text p {
        font-family: "Roboto", sans-serif;
        font-size: 2.1rem;
        line-height: 2.6rem;
        letter-spacing: 0.56px;
        color: #143477;
      }
    }
  }

  .bg-white {
    margin-bottom: 50px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }

    .flexible-content {
      overflow: visible;
      padding: 30px 0;
      box-sizing: content-box;
      margin: 0;

      &::before {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 14px;
        background: white;
        top: 0;
        transform: translate(-100%);

        @media (min-width: 768px) {
          width: 100%;
        }
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        height: 100%;
        width: 14px;
        background: white;
        transform: translateX(100%);

        @media (min-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  .flexible-push-page__wrapper.full-width {
    width: 100%;
    max-width: 100% !important;
  }
}
