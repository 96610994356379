.mes-services {
  margin: 30px;
  padding: 30px;
  display: block;
  background-color: white;
  height: calc(100vh - 60px);

  @media (max-width: 768px) {
    margin: 50px 0 0;
  }

  @media (max-width: 480px) {
    padding: 25px 20px;
  }

  .news-headline-hp {
    h2 {
      .vnf-icons-icon-modify {
        &:before {
          font-size: 3.4rem;
        }
      }
    }
  }

  .line-1 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.8rem;
    letter-spacing: 0.7px;
    color: $vnf-blue-a5;
  }

  .line-2 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.7px;
    color: $vnf-blue-a5;
  }

  &-list {
    display: grid;
    flex-wrap: wrap;
    gap: 20px;
    grid-template-columns: repeat(6,  1fr);
    margin-top: 55px;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3,  1fr);
    }


    @media (max-width: 576px) {
      grid-template-columns: repeat(2,  1fr);
    }
  }

  &-list__item {
    position: relative;
    display: flex;
    gap: 7px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 17px 15px 15px 15px;
    background-color: #D8F2D9;
    border-radius: 6px;
    height: 160px;

    @media (max-width: 768px) {
      flex: 0 1 auto;
    }

    &.favorite {
      .mes-services__btn-favorite {
        &:before {
          background-image: url('/assets/images/icons/star-full.svg');
        }
      }
    }
  }

  &__name {
    font-size: 1.2rem;
    color: $vnf-blue-a4;
    font-weight: 700;
    letter-spacing: 0.7px;
    text-align: center;
    line-height: 1.1;
    cursor: pointer;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: rgba(0, 192, 187, 0.3);
    border-radius: 100%;
    flex: 0 0 auto;
    cursor: pointer;

    .icon {
      font-size: 2.2rem;
      position: absolute;
      color: #3155A4;
    }
  }

  &__btn-favorite {
    position: absolute;
    top: 7px;
    right: 5px;
    font-size: 0;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-image: url('/assets/images/icons/star.svg');
      background-repeat: no-repeat;
    }
  }
}
