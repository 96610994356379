.module_select-list {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: $vnf-neutral-50;
  gap: 0;
  padding: 0;

  &--reverse {
    flex-direction: row-reverse;
  }

  > div {
    flex: 0 0 50%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    padding: 15px;
    justify-content: flex-start;
    align-content: flex-start;

    &--reverse {
      flex-direction: column-reverse;
    }

    > div {
      flex: 0 0 100%;
    }
  }

  &.select-list_heading {
    &:before {
      content: "";
      display: block;
      position: absolute;
      background-color: $vnf-grey-separation;
      width: 1px;
      height: calc(100% - 60px);
      top: 30px;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: 576px) {
        content: none;
      }
    }
  }

  .select-list_heading {
    &--content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      padding: 15px 0 15px;

      @media (min-width: 768px) {
        padding: 30px 15px 20px 30px;
      }

      @media (min-width: 1350px) {
        padding: 30px 15px 30px 30px;
      }

      .select-list {
        &--title {
          @include essentielTitle();
          max-width: 230px;
          margin: 0;
        }
      }
    }

    &--infographie {
      display: flex;
      justify-content: center;
      align-items: center;

      .select-list--title {
        display: flex;
        flex-direction: column;

        span {
          @include essentielTitle();
          font-weight: initial;
          color: $vnf-neutral-500;

          &.bold {
            font-weight: bold;
          }
        }
      }
    }

    &--list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 25px;

      &--hightlight {
        position: relative;
        box-shadow: 2px 2px 6px 0 #00000014;
        background-color: white;
        padding: 20px 15px 25px 15px;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size: 86px 64px;
        background-position: 20px 23px;
        width: 100%;

        @media (min-width: 1343px) {
          padding: 25px;
        }
      }

      &-interaction {
        border-bottom: 1px solid $vnf-neutral-200;
        padding: 10px 0 15px;
        margin: 0 0 -5px 0;

        @media (min-width: 1343px) {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        &-title {
          font-family: "Roboto", sans-serif;
          font-weight: 700;
          font-size: 2.2rem;
          margin-bottom: 15px;
        }
      }

      .select-list_heading--list-item {
        position: relative;
        display: flex;
        flex-direction: row;
        gap: 12px;

        &-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 30px;
        }

        h3 {
          @include essentielSubtitle();
          margin-bottom: 5px;
          font-size: 1.8rem;
        }

        p {
          @include paragraph();
          margin: 0;
          font-size: 1.6rem;

          @media (min-width: 768px) {
            font-size: 1.4rem;
          }
        }

        a {
          display: flex;
          flex-direction: row;
          gap: 14px;

          &:hover,
          &:focus {
            text-decoration: none;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }

          &[rel="external"] {
            &:after {
              content: "";
              display: block;
              background-image: url("/assets/images/icons/external-link.svg");
              background-size: 14px 14px;
              background-repeat: no-repeat;
              width: 15px;
              height: 14px;
              margin-top: 2px;
            }
          }
        }

        &:after {
          content: "";
          position: absolute;
          display: block;
          height: 1px;
          background-color: $vnf-neutral-200;
          width: calc(100% - 40px);
          bottom: -15px;
          left: 30px;
        }

        &:last-child {
          &:after {
            content: none;
          }
        }
      }

      &.diamond {
        .select-list_heading--list-item {
          &:before {
            content: url("/assets/images/icons/icons-shapes/diamond.svg");
            display: block;
          }
        }
      }

      &.triangle {
        .select-list_heading--list-item {
          &:before {
            content: url("/assets/images/icons/icons-shapes/triangle.svg");
            display: block;
            padding: 3px 0 0 0;
          }
        }
      }

      .select-implantation {
        width: 100%;

        @media (min-width: 1343px) {
          width: auto;
          margin-left: auto;

          .mat-mdc-form-field-type-mat-select {
            width: auto;

            .mat-mdc-form-field-infix {
              min-height: 37px;
            }

            .mat-mdc-floating-label {
              top: 19px;
            }

            .mdc-floating-label--float-above {
              transform: translateY(-186%) scale(1);
            }
          }
        }

        .mat-mdc-form-field-type-mat-select {
          width: 100%;
        }
      }
    }
  }

  &.select-list_teams {
    background-color: $vnf-neutral-50;

    .select-list_teams {
      &--visual {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &_img {
    @media (min-width: 768px) {
      padding: 0 0 0 15px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
