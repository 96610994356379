
.app {
  background-color: $vnf-grey-separation;
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  display: block;
  z-index: 0;

  @media (min-width: 768px) {
    max-width: 1920px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  > *:not(app-sidenav, router-outlet) {
    flex: 1;
    overflow: hidden;
  }

  &:has(.col-left + .col-right) {
    .col-left {
      @media (min-width: 1600px) {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }

    .col-right {
      @media (min-width: 1600px) {
        flex: 1;
        max-width: inherit;
      }
    }
  }
}

.main-layout {
  padding: 30px;
  max-width: 100%;
  overflow: hidden;

  /* Used when there is a searchbar */
  &.no-padding-top {
    padding-top: 0;
  }

  &:has(.page-fille) {
    padding: 20px 30px;
  }

  @media (max-width: 1342px) {
    max-width: 740px;
    padding: 77px 15px 54px;
    margin: 0 auto;

    &:has(.page-fille) {
      padding: 20px 15px;
    }
  }

  @media (max-width: 767px) {
    padding: 156px 14px 60px;

    &:has(.page-fille) {
      padding: 80px 14px 20px;
    }

    &:has(.article) {
      padding: 80px 14px 20px;
    }
  }
}

.main-layout, .layout {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  width: 100%;

  @media (max-width: 767px) {
    width: 100%;
  }

  &.gap-10 {
    column-gap: 10px;
  }

  .cols-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
    }

    @media (max-width: 1342px) {
      &.cols-news {
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .col-fullwidth {
    width: 100%;
    flex: 0 0 100%;
  }

  .col-left {
    max-width: 740px;
    flex: 0 0 740px;

    @media (max-width: 1342px) {
      max-width: 100%;
      flex: 1 0 auto;
    }
  }

  .bg-white {
    .col-left {
      max-width: calc(740px - 60px);
      flex: 0 0 calc(740px - 60px);
    }
  }

  .col-right {
    flex: 1 1 calc(100% - 770px);
    max-width: calc(100% - 770px);

    @media (max-width: 1342px) {
      max-width: 100%;
      flex: 1 0 auto;
    }
  }

  .col-center {
    background-color: #525252;
    max-width: 740px;
    flex: 0 0 740px;
    margin: 0 auto;
    height: 200px;
  }

  .col-half {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 30px;
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);

    @media (max-width: 767px) {
      flex: unset;
      max-width: 100%;
    }

    &.col-empty {
      display: none;
    }

    app-participation, app-events {
      height: 100%;
    }
  }

  .cols-container:has(.col-empty) {
    .col-half {
      max-width: unset;
      flex: 1 0 calc(50% - 15px);
    }
  }

  .col-tree {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 0 0 calc(33.33% - 7px);
    max-width: calc(33.33% - 7px);

    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .col-fourth {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 0 0 calc(25% - 7.5px);
    max-width: calc(25% - 7.5px);

    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .bg-white {
    position: relative;
    background-color: white;
    box-sizing: border-box;
  }

  .bg-lightgray {
    background-color: $vnf-grey-separation;
  }

  .bg-darkblue {
    background-color: $vnf-blue-a800;
  }

  .bg-darkerblue {
    background-color: #224592;
  }
}

app-flexibles {
  .layout:has(.bg-white) {
    + .layout:has(.bg-white) {
      margin-top: -50px;
    }
  }
}

.layout {
  > div {
    width: 100%;
  }

  &.layout-error {
    display: flex;
    justify-content: center;
    height: 100vh;
  }
}

.tag_list__child {
  margin-bottom: 20px;
}

.layout-fille {

  + .tag_list__child {
    margin-bottom: 0;
  }
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.j-center {
  justify-content: center;
}

.j-space-between {
  justify-content: space-between;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-24 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-60 {
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 60px;
  }
}

.mb-62 {
  margin-bottom: 62px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.cp-30 {
  padding: 15px;

  @media (min-width: 768px) {
    padding: 30px;
  }
}

.pt-0 {
  padding-top: 0
}

.pb-0 {
  padding-bottom: 0
}

[class*="col-"][class*=" cp-"] {
  box-sizing: content-box;
}
