.tag {
    display: inline-flex;
    width: fit-content !important;
    align-items: center;
    padding: 3px 4px;
    @include tag();

  &_list {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;

    &.vertical {
      flex-direction: column;
    }

    &__title {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.8rem;
      letter-spacing: 0.7px;
      color: $vnf-blue-a800;
      text-transform: uppercase;

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
  }

    &.bg-white {
      background-color: white;
      color: $vnf-blue-a700;
      transition: all 2ms ease-in-out;

      &:hover,
      &:focus {
        background-color: $vnf-blue-a700;
        color: white;
      }
    }

    &.bg-blue {
      background-color: $vnf-blue-a700;
      color: white;
    }

    &.bg-darkblue {
      background-color: $vnf-blue-a900;
      color: white;
    }

  &.tag-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid $vnf-neutral-100;
    color: $vnf-blue-a800;
    background-color: white;

    &.triangle {
      &:before {
        content: url("/assets/images/icons/icons-shapes/small-triangle.svg");
        margin-right: 6px;
        padding-top: 2px;
      }
    }

    &.circle {
      &:before {
        content: url("/assets/images/icons/icons-shapes/small-circle.svg");
        margin-right: 6px;
        padding-top: 2px;
      }
    }

    &.diamond {
      &:before {
        content: url("/assets/images/icons/icons-shapes/small-diamond.svg");
        margin-right: 6px;
      }
    }
  }
}

