
$vnf-blue-a900 : #143477;
$vnf-blue-a800 : #234BA3;
$vnf-blue-a700 : #3767CC;
$vnf-blue-a600 : #4D7FE8;
$vnf-blue-a500 : #5E92FF;
$vnf-blue-a400 : #79A4FF;
$vnf-blue-a300 : #6E9BFF;
$vnf-blue-a200 : #AEC8FF;
$vnf-blue-a100 : #C2D6FF;
$vnf-blue-a50 : #DFE9FF;
$vnf-blue-a10 : #EEF4FF;
$vnf-blue-a1 : #8FBCFF;
$vnf-blue-a2 : #5E92FF;
$vnf-blue-a4 : #3155A4;
$vnf-blue-a5 : #143477;
$vnf-blue-b700: #1978a6;
$vnf-blue-b800: #446bbb;
$vnf-blue-b900: #00628D;

$vnf-green-b300 : #86EC8B;
$vnf-green-b400 : #73e278;
$vnf-green-b800 : #28642B;
$vnf-green-a100 : #E5F7E6;

$vnf-orange-600 : #FF9900;

$vnf-violet-300: #8363FF;

$vnf-neutral-0: #FFFFFF;
$vnf-neutral-50: #F9F9F9;
$vnf-neutral-100: #EEEEEE;
$vnf-neutral-200: #E1E1E1;
$vnf-neutral-300: #CBCACA;
$vnf-neutral-400: #B2B2B2;
$vnf-neutral-500: #A3A3A3;
$vnf-neutral-600: #888888;
$vnf-neutral-700: #6D6D6D;
$vnf-neutral-800: #525252;
$vnf-grey-swiper-btn: #888888;
$vnf-grey-hr: #cbcaca;
$vnf-grey-select: #e9eef6;
$vnf-grey-separation: #eee;

$vnf-grey-a800: #3E6083;
$vnf-grey-b3: #767676;

$vnf-degrade-menu: linear-gradient(180deg, #00CD83 -7.26%, #67BCCE 42.69%, #419ADB 83.01%, #00CDB4 99.26%);
$vnf-degrade-header: linear-gradient(90.28deg, #00CD83 -6.81%, #67BCCE 43.3%, #419ADB 83.75%, #00CDB4 100.05%);
$vnf-degrade-card: linear-gradient(122.3deg, #1DCC95 20.88%, #4B9FDC 108.45%);
$vnf-degrade-simple: linear-gradient(180deg, #1DCC95 0%, #4B9FDC 100%);

$vnf-degrade-first-color: #1DCC95;
