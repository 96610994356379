.article {
  width: 100%;
  padding-bottom: 50px;

  &.important {
    .article__img {
      &:before {
        background: #FF9900;
      }
    }
  }

  .article__important {
    display: inline-block;
    position: relative;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 2.5rem;
    text-transform: uppercase;
    color: #234BA3;
    padding: 8px 1px 8px 14px;
    background-color: #F9F9F9;
    margin-top: 20px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 5px;
      background-color: #FF9900;
    }
  }

  &__body {
    margin-bottom: 50px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 30px;
  }

  .tag_list {
    flex-wrap: wrap;
  }

  &__title {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    h1 {
      font-family: "Museo", sans-serif;
      font-weight: 500;
      font-size: 3.5rem;
      line-height: 3.5rem;
      color: $vnf-blue-a800;
      display: flex;
      margin: 0;
      align-items: center;

      @media (max-width: 768px) {
        font-size: 2.3rem;
        line-height: 2.7rem;
      }
    }
  }

  &__date {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    min-width: 44px;
    height: 70px;
    background-color: $vnf-blue-a700;
    text-align: center;
    color: white;
    border-bottom: 5px solid $vnf-green-b300;

    time {
      display: flex;
      flex-direction: column;
    }
  }

  &__day {
    font-family: "Roboto", sans-serif;
    font-size: 2.7rem;
    line-height: 3.2rem;
  }

  &__month {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.4rem;
    text-transform: uppercase;
  }

  &__year {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.3rem;
    letter-spacing: 0.44px;
  }

  &__heading {
    margin-bottom: 20px;

    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  &__img {
    display: flex;
    position: relative;
    margin-bottom: 50px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }

    img {
      object-fit: cover;
      max-width: 740px;
      min-width: 100px;
      height: 386px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 100%;
      background: linear-gradient(180deg, #1DCC95 0%, #4B9FDC 100%);

      @media (max-width: 768px) {
        width: 9px;
      }
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-size: 2.1rem;
    line-height: 2.6rem;
    letter-spacing: 0.56px;
    color: $vnf-blue-a900;
    max-width: 740px;
  }


  .bg-white {
    margin-bottom: 50px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }

    .flexible-content {
      overflow: visible;
      padding: 30px 0;
      box-sizing: content-box;
      margin: 0;

      &::before {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 14px;
        background: white;
        top: 0;
        transform: translate(-100%);

        @media (min-width: 768px) {
          width: 100%;
        }
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        height: 100%;
        width: 14px;
        background: white;
        transform: translateX(100%);

        @media (min-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}

app-article {
  .main-layout {
    padding-top: 20px;
    overflow: visible;

    @media (max-width: 768px) {
      padding-top: 80px;
    }
  }
}
