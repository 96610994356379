@media (max-width: 390px) {
    .hide-xs-mobile {
      display: none;
    }
}

@media (min-width: 391px) {
    .hide-below-xs-mobile {
      display: none;
    }
}

@media (max-width: 576px) {
  .hide-s-mobile {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

@media (min-width: 768px) {
  .hide-below-mobile {
    display: none;
  }
}

.col-resume {
  .breves {
    @media (max-width: 767px) and (min-width: 577px) {
      margin-top: 30px;
    }
  }
}

.cols-tabs {
  &--list {
    display: flex;
    flex-direction: row;
    background-color: $vnf-blue-a700;
    margin: 0 -15px 15px;

    @media (min-width: 577px) {
      display: none;
    }
  }

  &--item {
    display: flex;
    flex: 0 0 25%;
  }

  &--selector {
    position: relative;
    font-family: "Roboto Condensed";
    color: #FFFFFF;
    font-size: 16px;
    width: 100%;
    line-height: 18px;
    padding: 15px 0 13px;

    &.active {
      &:after {
        content: '';
        height: 6px;
        width: 100%;
        background-color: $vnf-green-b300;
        position: absolute;
        bottom: 0;
        display: block;
        left: 0;
      }
    }
  }
}

.col-tab {
  display: none;

  @media (min-width: 577px) {
    display: block;
  }

  &.active {
    @media (max-width: 576px) {
      display: block;
    }
  }
}

.col-mobile {
  @media (min-width: 577px) {
    display: none;
  }

  @media (max-width: 576px) {
    &.active {
      display: block;
    }
  }
}
