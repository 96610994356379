.flexible-panel {
    margin-bottom: 30px;
    overflow: hidden;
    display: block;

    &.mat-accordion {
        .mat-expansion-panel {
            margin-bottom: 10px;

            &:not([class*="mat-elevation-z"]) {
                box-shadow: none;
            }

            &:first-of-type,
            &:last-of-type {
                border-radius: 0;
            }

            &-spacing {
                margin: 0 0 10px 0;
            }

            &-header {
                background: $vnf-blue-b700;
                color: white;
                padding: 10px 28px 10px 10px;
                height: inherit;
                min-height: 60px;

                @media (min-width: 768px) {
                    padding: 20px 28px 20px 20px;
                }

                &-title {
                    color: white;
                    font-family: "Roboto", sans-serif;
                    font-weight: 300;
                    font-size: 2.5rem;
                    line-height: 1.05;

                    @media (min-width: 768px) {
                        font-size: 3.1rem;
                    }
                }

                .ng-trigger {
                    svg {
                        display: none;
                    }

                    &:before {
                        @include icon-font();
                        content: "\e916";
                        display: flex;
                        color: #fff;
                        font-size: 2.5rem;
                        transform: rotate(90deg);
                    }
                }

                &.mat-expanded {
                    &:focus,
                    &:hover {
                        background: $vnf-blue-b700;
                    }
                }
            }

            &:not(.mat-expanded) {
                .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
                    background: $vnf-blue-b700;
                }
            }

            &-content {
                background: white;

                p {
                    @include flexText();
                }
            }

            &-body {
                padding: 20px;

                p,
                ul li,
                ol li {
                    color: $vnf-blue-a600
                }
            }
        }

        .mat-expansion-panel-body {
            background: #eee;
            border-color: #eee;

            .flexible-content {
                &:before {
                    background-color: #eee;

                    @media (min-width: 768px) {
                        right: 30px;
                    }
                }

                &:after {
                    background-color: #eee;
                }
            }

            &:has(.bg-white) {
                background: white;
                border-color: white;

                .flexible-content {
                    &:before,
                    &:after {
                        background-color: white;

                        @media (max-width: 768px) {
                            display: none;
                        }
                    }
                }

                .article {
                    .bg-white .flexible-content:before {

                        @media (min-width: 768px) {
                            right: 30px;
                        }
                    }
                }
            }
        }

      .flexible-text {
        margin-top: 15px;
        margin-bottom: 10px;
      }
    }

    .mat-expansion-panel:not([class*="mat-elevation-z"]) {
        box-shadow: none;
        border-radius: 0;
    }
}
