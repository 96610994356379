.news-headline-hp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;

    h2 {
      @include titleDecoIconAndUnderline();
    }

  &.back-button {
    @media (max-width: 768px) {
      justify-content: flex-start;
    }

    .back-button {
      display: none;

      @media (max-width: 768px) {
        display: block;
        width: 0;
        font-size: 0;

        &:before {
          font-family: 'vnf-icons-v2';
          content: '\e9af';
          color: #888888;
          font-size: 2.8rem;
        }
      }
    }

    h2 {
      @media (max-width: 768px) {
        margin: 0 0 25px 57px;
      }
    }
  }
}
