.suggestions {
  padding: 15px;
  background-color: #F9F9F9;

  > p {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: $vnf-blue-a800;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 35px;
  }

  &__image {
    overflow: hidden;
    height: 129px;
    width: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 6px 0 #00000014;
    border-radius: 6px;
    background-color: #FFFFFF;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    cursor: pointer;

    img {
      transition: all .25s linear;
    }

    &:hover,
    &:focus {
      box-shadow: 4px 4px 6px 0 #0000001F;

      .icon {
        color: $vnf-blue-a800;
      }

      img {
        transform: scale(1.04);
      }
    }

    .icon {
      font-size: 2.2rem;
      color: $vnf-blue-a400;
      margin: 8px auto 13px;
      transition: all 0.2s ease-in-out;
    }

    p {
      font-family: "Roboto", sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.7rem;
      color: $vnf-blue-a800;
      padding: 15px 20px 20px
    }

    img {
      width: 100%;
      height: 129px;
      object-fit: cover;
      transition: all 0.2s ease-in-out;
    }
  }
}
