.card {
  &__item {
    position: relative;
    background-color: white;
    box-shadow: 2px 2px 6px 0 #00000014;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    transition: all 0.2s ease-in-out;
    width: 100%;

    &:hover,
    &:focus {
      box-shadow: 4px 4px 6px 0 #0000001f;
    }

    &:has(a[rel="external"]) {
      .zoom--content {
        &:before {
          content: url("/assets/images/icons/external-link.svg");
          position: absolute;
          top: 12px;
          right: 12px;
        }
      }
    }

    &__content {
      padding: 10px 30px 10px 20px;
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .card__item__title {
        @include flexTitleL();
        font-size: 2rem;
        font-weight: 400;
        text-transform: none;
        margin-bottom: 15px;
        color: $vnf-blue-a800;
      }

      p {
        margin-bottom: 5px;
      }
    }

    &__image {
      position: relative;
      display: flex;
      width: auto;

      @media (max-width: 767px) {
          width: 100%;
          height: auto;
      }

      @media (min-width: 1343px) and (max-width: 1600px) {
          width: 100%;
          height: auto;
      }

      picture {
          display: block;
          width: 100%;
      }

      img {
          display: block;
          width: 100%;
          object-fit: cover;
          height: 130px;

          @media (min-width: 768px) and (max-width: 1342px) {
              height: 100%;
              width: 182px;
          }

          @media (min-width: 1343px) and (max-width: 1600px) {
              height: 130px;
          }

          @media (min-width: 1601px) {
              height: 100%;
              width: 182px;
          }
      }
    }

    &__link {
      font-size: 0;

      &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      &[rel="external"]::before {
        content: url(/assets/images/icons/external-link.svg);
        position: absolute;
        top: 7px;
        right: 7px;
      }
    }

    &__title {
      @include sectionTitle();
      font-size: 1.6rem;
      margin-bottom: 5px;
    }

    &__subtitle,
    &__text {
      @include paragraph();
      color: $vnf-blue-a800;
      margin: 0;
    }

    &__subtitle {
      font-weight: 700;
      margin-bottom: 5px;
    }
  }
}
