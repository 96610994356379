.flexible-push-page {
    &__wrapper {
        margin-bottom: 30px;

        &.full-width {
            max-width: inherit !important;
        }

        swiper-slide {
            max-width: 208px;
            padding-bottom: 10px;
        }

        @media (min-width: 992px) {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
        }
    }

    &-slider {
        position: relative;
        margin-bottom: 50px !important;

        .swiper-navigation {
            display: flex;
            position: absolute;
            gap: 5px;
            right: 21px;
            bottom: -35px;
        }

        .swiper-button-next {
            cursor: pointer;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                @include icon-font;
                content: "\e916";
                font-size: 2rem;
                color: $vnf-grey-swiper-btn;
            }
        }

        .swiper-button-prev {
            cursor: pointer;
            width: 32px;
            height: 32px;
            display: flex;
            transform: rotate(180deg);
            justify-content: center;
            align-items: center;

            &:after {
                @include icon-font;
                content: "\e916";
                font-size: 2rem;
                color: $vnf-grey-swiper-btn;
            }
        }

        .swiper-button-disabled {
            &:after {
                color: $vnf-neutral-300;
            }
        }
    }
}
