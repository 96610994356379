.collaborateurs {
  margin: 30px;
  padding: 40px 30px;
  display: block;
  background-color: #F9F9F9;

  @media (max-width: 1263px) {
    padding: 40px 10px;
  }

  @media (max-width: 768px) {
    margin: 10px;
    padding: 0;
  }

  .news-headline-hp {
    h2 {
      .vnf-icons-icon-collabs {
        &:before {
          font-size: 2.7rem;
        }

        @media (max-width: 768px) {
          position: initial;
          display: flex;
          justify-content: center;

          &:before {
            font-size: 1.4rem;
          }
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding-left: 0;

        &:after {
          content: none;
        }
      }
    }

    @media (max-width: 768px) {
      background-color: #EEEEEE;
      padding-top: 60px;
    }
  }

  &-back__btn {
    display: none;
    font-size: 0;

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 70px;
      left: 20px;
      font-size: 0;
      width: 25px;
      height: 25px;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
        border-left: 2px solid #ADACAC;
        border-bottom: 2px solid #ADACAC;
      }

      &:before {
        transform: rotate(45deg);
      }
    }
  }

  &-filters {
    &__btn-open {
      position: absolute;
      top: 70px;
      right: 60px;
      display: flex;
      gap: 14px;
      align-items: center;
      font-family: "Roboto Condensed", sans-serif;
      line-height: 1.8rem;
      letter-spacing: 0.7px;
      color: #ADACAC;
      text-transform: uppercase;
      font-size: 1.6rem;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 0;
        top: 70px;
        width: 21px;
        right: 20px;
      }

      &:before {
        content: url('/assets/images/icons/parameters.svg');
        display: block;
        width: 21px;
        height: 23px;
      }
    }

    &__aside {
      width: 381px;
      position: fixed;
      right: 0;
      top: 0;
      background-color: white;
      height: 100vh;
      padding: 20px 25px 15px 50px;
      transform: translateX(100%);
      transition: all 0.2s ease-in-out;
      box-shadow: 1px 1px 4px 0px #00000040;
      overflow: auto;
      z-index: 1;

      @media (max-width: 768px) {
        height: calc(100vh - 60px);
      }

      &.active {
        transform: translateX(0);
      }

      @media (max-width: 768px) {
        top: 60px;
      }
    }

    &__title {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 1.6rem;
      line-height: 1.8rem;
      letter-spacing: 0.7px;
      font-weight: 700;
      text-transform: uppercase;
      color: $vnf-blue-a2;
    }

    &__type {
      font-family: "Roboto Condensed", sans-serif;
      color: $vnf-blue-a2;
      font-size: 1.6rem;
      line-height: 1.8rem;
      letter-spacing: 0.7px;
      font-weight: 700;
      margin-top: 30px;
      padding-bottom: 13px;
      border-bottom: 1px solid #EEEEEE;
    }

    &__btn-close {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0;
      width: 25px;
      height: 25px;
      position: absolute;
      top: 15px;
      right: 20px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 15px;
        height: 1px;
        background-color: #5E92FF;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &__radio-group {
      display: flex;
      flex-direction: column;

      label {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 400;
        line-height: 1.8rem;
        font-size: 1.6rem;
        letter-spacing: 0.7px;
        color: $vnf-blue-a5;
      }

      .mat-mdc-radio-checked {
        &:hover,
        &:focus {
          label {
            color: $vnf-blue-a2;
          }
        }
      }

      .mat-mdc-radio-button .mdc-radio {
        padding: 6px;
      }

      .mat-mdc-radio-button .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
        border-color: #5E92FF;
        border-width: 1px;
      }

      .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
        border-color: #5E92FF;
        border-width: 1px;
      }

      .mat-mdc-radio-button .mdc-radio__native-control:enabled:focus:checked + .mdc-radio__background .mdc-radio__outer-circle,
      .mat-mdc-radio-button .mdc-radio__native-control:enabled:focus:checked + .mdc-radio__background .mdc-radio__inner-circle,
      .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]) ~ .mdc-radio__background .mdc-radio__outer-circle,
      .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
      .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle,
      .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle, .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle {
        border-color: #5E92FF;
      }
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 19px));
    gap: 29px 28px;

    @media (max-width: 1263px) {
      grid-template-columns: repeat(2, calc(50% - 5px));
      gap: 10px;
    }

    @media (max-width: 890px) {
      grid-template-columns: repeat(1,100%);
    }

    @media (max-width: 768px) {
      padding: 14px;
      background-color: #F9F9F9;
      gap: 10px;
    }

    &__content {
      flex: 1 1 calc(100% - 55px);
    }

    &__item {
      display: flex;
      gap: 8px;
      background-color: white;
      padding: 16px;
    }

    &__image {
      background: linear-gradient(180deg, #00cd83 -7.26%, #67bcce 42.69%, #419adb 83.01%, #00cdb4 99.26%) !important;
      height: 48px;
      width: 48px;
      border-radius: 100%;
      display: inline-block;
      flex: 1 0 48px;
      img {
        border-radius: 100%;
      }
    }

    &__name {
      font-family: "Roboto", sans-serif;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.8rem;
      color: #143477;
      margin-bottom: 5px;
    }

    &__position {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 1.2rem;
      color: #143477;
      margin-bottom: 0;
    }

    &__dt {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: #8fbcff;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    &__email {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #1976d2;
      text-decoration: underline;
      margin-bottom: 5px;
      word-break: break-word;
    }

    &__phones {
      display: flex;
      align-items: center;

      span {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: $vnf-blue-a5;
      }

      &-pro {
        + .collaborateurs-list__phones-perso {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            margin: 0 10px;
            display: inline-block;
            height: 17px;
            width: 2px;
            background-color: $vnf-green-b300;
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    position: relative;
    width: fit-content;
    margin: 30px auto;

    &--info {
      color: #9e9e9e;
      display: flex;
      align-items: center;
    }
  }
}

.reset-filters {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: 1px;
  margin-top: 30px;
  text-transform: uppercase;
  color: #5E92FF;
  padding: 10px 5px;

  &:before {
    opacity: 0;
    background-color: currentColor;
    border-radius: inherit;
    bottom: 0;
    color: inherit;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .2s cubic-bezier(.4,0,.6,1);
  }

  &:hover,
  &:focus {
    &:before {
      opacity: .08;
    }
  }
}
