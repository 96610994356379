.breves {
  position: relative;
  background-color: $vnf-neutral-50;
  padding: 15px 0 18px 15px;
  column-gap: 10px;
  margin-bottom: 20px;
  flex-direction: column;
  flex: 1;
  display: block;
  min-width: 100%;
  overflow: hidden;
  margin-right: -14px;

  @media (min-width: 768px) {
    margin-right: 0;
    padding: 15px 15px 18px 15px;
  }

  @media (min-width: 1343px) {
    flex-direction: row;
    padding: 15px 0 0 0;
  }

  @media (min-width: 1343px) {
    flex-direction: row;
  }

  h3 {
    width: 100%;

    @media (min-width: 1343px) {
      padding: 0 15px;
    }
  }

  .breve {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 2px 2px 6px 0 #00000014;
    padding: 15px 20px 10px;
    border-radius: 6px;
    background-color: white;

    @media (min-width: 1343px) {
      margin: 0 15px
    }

    h3 {
      width: 100%;
    }

    &--date {
      display: block;
      margin-bottom: 5px;
      @include date();
    }

    &--paragraph {
      @include cardTextMedium();

      margin: 0;
      font-family: "Museo Regular", sans-serif;
    }

    &--tag {
      margin-bottom: 5px;

      + {
        .breve--paragraph {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    &--modale-opener {
      font-size: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      &:before {
        content: '';
        position: absolute;
        right: 12px;
        top: 19px;
        background-color: $vnf-blue-a400;
        width: 14px;
        height: 2px;
      }

      &:after {
        content: '';
        position: absolute;
        right: 18px;
        top: 13px;
        background-color: $vnf-blue-a400;
        height: 14px;
        width: 2px;
      }
    }
  }

  .swiper-container {
    height: auto;
    max-width: 100%;
    overflow: hidden;

    &.vertical {
      &::part(container) {
        position: static;
        height: 325px;

        @media (min-width: 1720px) {
          height: 150px;
        }
      }

      .swiper-item {
        width: 100%;

        @media (min-width: 1720px) {
          display: flex;
          width: 100%;
        }

        .breve {
          width: calc(100% - 30px);
          height: calc(50% - 7px);

          @media (min-width: 1720px) {
            max-width: 50%;
            height: 100%;
            margin: 0 15px 0 5px;
          }

          &:first-child {
            margin-bottom: 10px;

            @media (min-width: 1720px) {
              margin: 0 5px 0 15px;
            }
          }

          &:only-child {
            @media (min-width: 1720px) {
              max-width: calc(50% - 20px);
            }
          }
        }
      }
    }
  }

  .swiper-item {
    padding-bottom: 10px;
    padding-top: 5px;
    width: 270px;
  }
}

.cols-news {
  @media (max-width: 576px) {
    h3 {
      display: none;
    }
    .breves {
      margin-right: 0;
      padding: 0;
      background-color: transparent;
    }
    .breve {
      margin-bottom: 10px;
    }
  }
}
