.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.dots-loader {
  display: flex;
  justify-content: space-around;
  width: 60px;
}

.dots-loader div {
  width: 15px;
  height: 15px;
  background-color: #3498db;
  border-radius: 50%;
  animation: dots-loader 1.2s linear infinite;
}

.dots-loader div:nth-child(1) {
  animation-delay: -0.4s;
}

.dots-loader div:nth-child(2) {
  animation-delay: -0.2s;
}

.dots-loader div:nth-child(3) {
  animation-delay: 0s;
}

@keyframes dots-loader {

  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}