.flexible-img {
    padding-bottom: 30px;
    max-width: 740px;

    &-slider {
        margin-bottom: 30px;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    figure {
        figcaption {
            background: $vnf-blue-a900;
            color: white;
            width: 100%;
            display: block;
            padding: 10px;

            p {
                @include paragraph();
                color: white;
                margin-bottom: 3px;
            }

          time {
            font-weight: 300;
            padding-right: 5px;
          }
        }
    }
}

.flexible-img-slider {
  max-width: 740px;

    .flexible-img {
        background: none;
    }

  picture,
  img {
    height: 265px;
    object-fit: cover;
  }

  figcaption {
    height: 71px;

    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
