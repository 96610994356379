.tools {
  display: flex;
  flex-direction: column;
  background-color: $vnf-neutral-50;
  padding: 30px 15px;

  @media (min-width: 768px) {
    padding: 30px;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &--item-wrapper {
      display: contents;
    }

    &--item {
      position: relative;
      flex: 0 0 calc(50% - 15px);
      padding: 20px 20px 20px 30px;
      box-shadow: 2px 2px 6px 0 #00000014;
      border-radius: 6px;
      background: white;
      transition: all 0.2s ease-in-out;

      &:hover,
      &:focus {
        box-shadow: 4px 4px 6px 0px #0000001F;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 10px;
        height: 100%;
        background-color: $vnf-violet-300;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }

    &--title {
      @include essentielSubtitle();
      color: $vnf-blue-a800;

      @media (max-width: 768px) {
        font-size: 1.8rem;
      }
    }

    &--text {
      @include paragraph();

      @media (max-width: 768px) {
        font-size: 1.6rem;
      }
    }

    &--link {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    swiper-container {
      swiper-slide {
        padding-bottom: 10px;
      }
    }

    &:hover,
    &:focus {
      .swiper-button-prev,
      .swiper-button-next {
        &:after {
          @include icon-font;
          content: "\e916";
          font-size: 2rem;
          color: $vnf-grey-swiper-btn;
        }

        &.swiper-button-disabled {
          cursor: initial;
          &:after {
            color: $vnf-neutral-300;
          }
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      &:hover,
      &:focus {
        &:after {
          color: $vnf-neutral-800;
        }
      }
    }
  }

  p, .tag {
    margin-bottom: 5px;
  }

  .btn {
    margin-top: 20px;
    margin-left: auto;

    @media (max-width: 768px) {
      font-size: 1.8rem;
      padding: 15px 20px;
      margin-right: auto;
    }
  }

  &.tools-3-cols {
    padding: 0;
    background: none;

    .tools_list {
      gap: 20px 25px;

      &--item {
        background: white;

        @media (min-width: 768px) {
          flex: 0 0 calc(50% - 13px);
        }

        @media (min-width: 1343px) {
          flex: 0 0 calc(33.333% - 17px);
        }
      }
    }
  }

  &.tools-2-cols {
    padding: 0;
    background: none;

    .tools_list {
      gap: 20px 25px;

      &--item {
        flex: 0 0 calc(50% - 13px);
      }
    }
  }

  &-slider {
    max-width: 740px;
    width: 100%;
    background-color: transparent;
    padding: 0;
    margin-bottom: 40px;

    &:hover,
    &:focus {
      .tools_list--item {
        transition: all 0.3s ease-in-out;
        box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);
      }
    }

    @media (max-width: 1342px) {
      max-width: 800px;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }

    .tools_list {
      gap: 15px;
    }

    .tools_list--title {
      font-size: 2.2rem;
      margin-bottom: 10px;
    }

    .tools_list--text {
      line-height: 1.5;
    }

    .tag {
      font-size: 1.6rem;

      &_list {
        margin-bottom: 20px;
        flex-wrap: wrap;
      }
    }

    swiper-container {
      max-width: 740px;

      @media (max-width: 1342px) {
        max-width: unset;
        width: 100%;
      }

      &::part(wrapper) {
        // padding-bottom: 40px;
      }

      &::part(bullet),
      &::part(bullet-active){
        background-color: $vnf-neutral-400;
      }
    }

    .swiper-navigation {
      position: relative;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-pagination {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      order: 4;

      &-bullet {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;

        @media (max-width: 768px) {
          width: 48px;
          height: 48px;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $vnf-neutral-400;
        }
      }

      &-bullet-active {
        &:after {
          content: '';
          width: 8px;
          height: 8px;
        }
      }
    }

    .swiper-button-next {
      display: flex;
      width: 34px;
      height: 34px;
      transform: rotate(0deg);
      justify-content: center;
      align-items: center;
      order: 8;
      cursor: pointer;

      @media (max-width: 768px) {
        width: 48px;
        height: 48px;
      }
    }

    .swiper-button-prev {
      display: flex;
      width: 34px;
      height: 34px;
      transform: rotate(-180deg);
      justify-content: center;
      align-items: center;
      order: 2;
      cursor: pointer;

      @media (max-width: 768px) {
        width: 48px;
        height: 48px;
      }
    }

    .swiper-button-disabled {
      &:after {
        color: $vnf-neutral-300;
      }
    }

    .tools_list--item {
      padding: 20px 20px 20px 30px;

      @media (min-width: 768px) {
        padding: 40px 40px 35px 50px;
      }
    }
  }
}

app-slider-outils {
  max-width: 100%;
}

app-flexible-push-outils {
  .tools_list--item {
    flex: 0 0 calc(33.333% - 15px);
  }
}

@media (min-width: 1343px) and (max-width: 1730px) {
  .col-right {
    .tools {
      .tools_list--item {
        flex: 0 1 100%;
      }
    }
  }
}
