.flexible-reporting {
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 100% !important;

  @media (max-width: 1342px) {
    flex-direction: column;
  }

  &:hover {
    .flexible-reporting-img {
      picture, img {
        transform: scale(1.04);
      }
    }
  }

  &-content {
    padding: 30px;
    background-color: #3155A4;
    width: 678px;
    transform: translate(-13%, 15%);
    margin-bottom: 15%;

    @media (max-width: 1342px) {
      width: 100%;
      transform: translate(0, 0);
    }
  }

  &-img {
    overflow: hidden;
    border-radius: 6px;
    picture, img {
      object-fit: cover;
      transition: all 0.2s ease-in-out;

      @media (max-width: 1342px) {
        width: 100%;
      }
    }
  }

  &-title {
    font-family: "Museo Bold", sans-serif;
    font-weight: 700;
    color: white;
    font-size: 3rem;
    line-height: 36px;
    letter-spacing: 0.6px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    &:after {
      content: "";
      display: block;
      height: 4px;
      width: 24px;
      background: #86EC8B;
      transform: rotate(-11deg);
      margin: 30px 0;
    }
  }

  &-text {
    font-family: "Roboto", sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 23px;
    color: white;
    letter-spacing: 0.6px;

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  &-link {
    font-size: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &[rel="external"] {
      &:before {
        content: '';
        background: url(/assets/images/icons/external-link.svg) no-repeat;
        background-size: cover;
        display: block;
        width: 17px;
        height: 16px;
        position: absolute;
        top: 14px;
        right: 14px;
        left: unset;

        @media (max-width: 1342px) {
          top: 360px;
        }
      }
    }
  }
}
