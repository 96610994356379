@keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }
  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}

.search-headline {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  h2 {
    @include titleDecoIconAndUnderline();
    margin: 0;
    padding-top: 2px;
    padding-left: 15px;
    letter-spacing: 0.7px;

    &::after {
      left: 17px;
    }
  }

  .search-filter-opener {
    display: none;
    font-size: 1.6rem;
    color: $vnf-neutral-600;
    letter-spacing: -0.6px;

    span {
      padding-right: 10px;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }
}

.search-results {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__content {
    padding: 6px 40px 6px 60px;
  }

  &__item {
    position: relative;
    border-radius: 6px;
    background-color: white;
    box-shadow: 2px 2px 6px 0 #00000014;
    padding: 15px 15px;
    transition: all 0.2s ease-in-out;

    &.loading {
      overflow: hidden;
      background-image: none !important;
      pointer-events: none;
      cursor: unset;
      min-height: 133px;

      &::before {
        content: "";
        position: absolute;
        background: linear-gradient(
                        90deg,
                        transparent,
                        rgba(255, 255, 255, 0.9),
                        transparent
        );
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        animation: loading 1.5s infinite;
      }

      .search-results__content {
        border-radius: 5px;

        span, p {
          user-select: none;
          border-radius: 3px;
          background: #EEE !important;
          color: #EEE !important;
        }
      }
    }

    &.cursor-pointer {
      cursor: pointer;
    }

    &:hover,
    &:focus {
      box-shadow: 4px 4px 6px 0px #0000001F;
    }

    &[data-type="news"] {
      background-image: url('/assets/images/icons/icons-search/news.svg');
      background-repeat: no-repeat;
      background-position: 21px 21px;
      background-size: 29px 29px;
    }

    &[data-type="document"] {
      background-image: url('/assets/images/icons/icons-search/document.svg');
      background-repeat: no-repeat;
      background-position: 21px 21px;
      background-size: 29px 29px;
    }

    &[data-type="page-identity"] {
      background-image: url('/assets/images/icons/icons-search/menu-identity.svg');
      background-repeat: no-repeat;
      background-position: 21px 21px;
      background-size: 29px 29px;
    }

    &[data-type="page-cap"] {
      background-image: url('/assets/images/icons/icons-search/menu-cap.svg');
      background-repeat: no-repeat;
      background-position: 21px 21px;
      background-size: 29px 29px;
    }

    &[data-type="page-daily"] {
      background-image: url('/assets/images/icons/icons-search/menu-daily.svg');
      background-repeat: no-repeat;
      background-position: 21px 21px;
      background-size: 29px 29px;
    }
  }

  &__loading-card {
    min-height: 100px;
    width: 100%;
    background-color: blue;
  }

  &__title {
    word-break: break-all;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2.1rem;
    margin-top: 5px;
  }


  &__date, &__score {
    color: $vnf-neutral-600;
  }

  &__date,
  &__score,
  &__text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    display: inline;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__options {
    position: absolute;
    top: 10px;
    width: 34px;
    height: 34px;
    right: 10px;
    font-size: 0;
    border-radius: 50%;

    &:before {
      @include icon-font();
      font-size: 1.8rem;
      content: '\e9e8';
      color: $vnf-blue-a400;
    }

    &:hover {
      background-color: #eef4ff;
    }
  }

  .pagination {
    display: inline-flex;
    position: relative;
  }

  .options {
    left: unset;
    right: 40px;
    top: 10px;
    transform: unset;

    @media (max-width: 768px) {
      transform: unset;
      max-width: unset;
      right: 0;
      top: unset;
      left: 0;
      width: 100vw;
    }
  }
}
