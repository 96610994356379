.module_essentiel {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 60px;
  padding: 30px;
  background-color: white;
  align-items: center;

  > div {
    flex: 0 0 calc(50% - 30px);
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 30px;
    padding: 15px;

    &--reverse {
      flex-direction: column-reverse;
    }

    > div {
      flex: 0 0 100%;
    }
  }

  &.essentiel_heading {
    &:before {
      content: '';
      display: block;
      position: absolute;
      background-color: $vnf-grey-separation;
      width: 1px;
      height: calc(100% - 60px);
      top: 30px;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: 576px) {
        content: none;
      }
    }
  }

  .essentiel_heading {
    &--icon_title {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 25px;
      background-color: $vnf-blue-a10;
      padding: 55px 0;
      width: 100%;

      .essentiel {
        &--title {
          @include essentielTitle();
          max-width: 230px;
          margin: 0;
        }
      }

      @media (max-width: 576px) {
        &:before {
          content: '';
          position: absolute;
          bottom: -15px;
          height: 1px;
          width: 100%;
          left: 0;
          transform: translateY(-50%);
          background-color: $vnf-grey-separation;
        }
      }
    }

    &--infographie {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $vnf-blue-a50;
      min-height: 200px;
      position: relative;

      @media (max-width: 576px) {
        &:before {
          content: '';
          position: absolute;
          bottom: -15px;
          height: 1px;
          width: 100%;
          left: 0;
          transform: translateY(-50%);
          background-color: $vnf-grey-separation;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      .essentiel--title {
        display: flex;
        flex-direction: column;

        span {
          @include essentielTitle();
          font-weight: initial;
          color: $vnf-neutral-500;

          &.bold {
            font-weight: bold;
          }
        }
      }
    }
  }

  &_content {
    &_main {
      order: 5;

      @media (min-width: 768px) {
        order: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        order: 1;
        padding: 60px 30px 30px 30px;
      }
    }

    &_secondary {
      padding: 0;
      order: 1;
      min-height: 310px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (min-width: 768px) {
        padding: 30px;
        order: 5;
      }
    }
  }
}
