.flexible-similar-articles {
  position: relative;
  background-color: #F9F9F9;
  padding: 15px;
  max-width: 100% !important;

  @media (max-width: 768px) {
    padding: 25px 15px 30px;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 14px;
    background: #F9F9F9;
    top: 0;
    transform: translate(-100%);

    @media (min-width: 768px) {
      width: 100%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 14px;
    background: #F9F9F9;
    transform: translateX(100%);

    @media (min-width: 768px) {
      width: 100%;
    }
  }

  &__title {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 2.5rem;
    font-weight: 400;
    color: $vnf-blue-a800;
  }

  &__others {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.1rem;
    color: $vnf-blue-a800;
    margin: 30px 0 10px;
  }

  &__news {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    swiper-slide {
      flex: 0 0 330px;
    }

    .news-secondary {
      max-width: 330px;
      width: 100%;

      .news-secondary-title {
        min-height: 145px;
      }

      &-link {
        font-size: 0;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:hover,
      &:focus {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  &__on-pictures {
    display: flex;
    flex-direction: column;

    swiper-container {
      width: 100%;
    }

    swiper-slide {
      max-width: unset;
      padding-bottom: 10px;
      padding-top: 3px;
      flex: 0 0 255px;
    }

    .on-pictures__card {
      flex: 0 0 calc(25% - 17px);box-shadow: 2px 2px 6px 0px #00000014;


      @media (max-width: 1343px) {
        flex: unset;
      }

      &-img {
        position: relative;
        overflow: hidden;
        height: 145px;
      }

      img {
        width: 100%;
        object-fit: cover;
        transition: all 0.2s ease-in-out;
      }

      &-link {
        font-size: 0;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:hover,
      &:focus {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  .swiper-navigation {
    display: flex;
    gap: 5px;

    @media (min-width: 391px) {
      left: unset;
      right: 21px;
      transform: unset;
    }

    @media (min-width: 768px) {
      right: 35px;
      transform: unset;
      justify-content: flex-end;
    }

    @media (min-width: 1343px) {
      display: none;
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .swiper-button-next {
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      @include icon-font;
      content: "\e916";
      font-size: 2rem;
      color: $vnf-grey-swiper-btn;
    }
  }

  .swiper-button-prev {
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    transform: rotate(180deg);
    justify-content: center;
    align-items: center;

    &:after {
      @include icon-font;
      content: "\e916";
      font-size: 2rem;
      color: $vnf-grey-swiper-btn;
    }
  }

  .swiper-button-disabled {
    cursor: initial;

    &:after {
      color: $vnf-neutral-300;
    }
  }

  .news-secondary-tags-container {
    left: 16px;
    gap: 7px;
  }

  &-img-wrapper {
    position: relative;
    height: 190px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    overflow: hidden;
  }
}
