.flexible-datavisualisation {
    &-interaction {
        height: 30px;
        display: flex;
        justify-content: space-between;
    }

    &-link {
        font-size: 0;
        display: block;
        height: 30px;
        width: 30px;
        position: absolute;
        top: 3px;
        right: 3px;

        &:before {
            content: "";
            position: absolute;
            right: 8px;
            top: 14px;
            background-color: $vnf-blue-a400;
            width: 14px;
            height: 2px;
          }

          &:after {
            content: "";
            position: absolute;
            right: 14px;
            top: 8px;
            background-color: $vnf-blue-a400;
            height: 14px;
            width: 2px;
          }
    }

    &-opener {
        font-size: 0;
        display: block;
        height: 30px;
        width: 30px;
        position: absolute;
        top: 3px;
        left: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            @include icon-font();
            content: "\e917";
            color: $vnf-blue-a400;
            font-size: 2rem;
            position: relative;
        }
    }

    &-img {
        display: flex;
        justify-content: center;

        img {
            display: block;
            margin-bottom: 10px;
            max-width: 100%;
            height: auto;
        }
    }

    &-legend {
        p {
            //@include cardTextSmall();
            @include essentielTitle();
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.style-1 {
        p {
            font-family: 'Roboto', sans-serif;
            font-size: 1.4rem;
            font-weight: 400;
            position: relative;
            text-transform: none;
            color: $vnf-blue-a700;

            &.line-1 {
                padding-left: 18px;

                &:before {
                    content: "";
                    display: block;
                    width: 11px;
                    height: 11px;
                    background: $vnf-blue-a500;
                    position: absolute;
                    left: 0;
                    top: 2px;
                    border-radius: 50%;
                }
            }

            &.line-2 {
                padding-left: 18px;

                &:before {
                    content: "";
                    display: block;
                    width: 11px;
                    height: 11px;
                    background: $vnf-green-b400;
                    position: absolute;
                    left: 0;
                    top: 2px;
                    border-radius: 50%;
                }
            }
        }
    }

    &.style-2 {
        p {
            font-family: 'Roboto', sans-serif;
            font-size: 1.6rem;
            text-transform: none;
            font-weight: 400;
            line-height: 1.05;
            color: $vnf-blue-a700;

            &.line-1 {
                font-size: 7rem;
                font-weight: 500;
            }

            &.line-2 {
                font-weight: 700;
            }
        }
    }

    &.style-3 {
        p {
            font-family: 'Roboto', sans-serif;
            text-transform: none;
            font-size: 1.6rem;
            line-height: 1.05;
            font-weight: 400;
            color: $vnf-blue-a700;

            &.line-1 {
                font-size: 2.9rem;
                font-weight: 500;
            }
        }
    }
}

.card-item {
    &:has(.flexible-datavisualisation) {
        height: 100%;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
}