.flexible-push-portrait {
  max-width: 100% !important;

  &--pushs {
    display: grid;
    grid-template-columns: 50% 50%;

    @media (max-width: 1342px) {
      grid-template-columns: 100%;
    }
  }

  &--push {
    display: flex;
    transform: translateY(-100px);
    width: 100%;
    margin-top: 140px;

    @media (max-width: 1342px) {
      transform: translateY(0);
      margin-top: 100px;

      &:first-child {
        margin-top: 0;
      }
    }

    @media (max-width: 768px) {
      margin-top: 70px;

      &:first-child {
        margin-top: 0;
      }
    }

    &-content {
      background-color: $vnf-blue-a900;
    }

    &:nth-child(odd) {
      justify-content: left;
    }

    &:nth-child(even) {
      justify-content: right;
    }

    &:nth-child(4n+2) {
      .flexible-push-portrait--push-content {
        background-color: $vnf-green-b800;
      }
    }

    &:nth-child(4n+3) {
      .flexible-push-portrait--push-content {
        background-color: $vnf-grey-a800;
      }
    }

    &:nth-child(4n+4) {
      .flexible-push-portrait--push-content {
        background-color: $vnf-blue-b900;
      }
    }

    @media (max-width: 768px) {
      transform: translateY(0);
      width: 100%;
    }

    &-content {
      position: relative;
      max-width: 540px;
      width: calc(100% - 170px);
      color: white;
      padding: 50px 55px 50px 30px;
      min-height: 360px;

      @media (max-width: 1600px) {
        max-width: 400px;
      }

      @media (max-width: 768px) {
        padding: 50px 25px 50px 25px;
        max-width: unset;
        width: 100%;
      }

      .flexible-push-portrait--push-img {
        position: absolute;
        top: -40px;
        right: 0;
        transform: translateX(80%);
        border-radius: 6px;

        @media (max-width: 768px) {
          display: none;
        }

        img {
          display: block;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .line-name {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 2.0rem;
        line-height: 2.2rem;
        margin-bottom: 15px;
      }

      .line-assignment {
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 2.0rem;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      .line-text {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.9rem;
      }
    }

    &:nth-child(even) {
      margin-right: 0;
      margin-left: auto;
      transform: translateY(0);

      @media (max-width: 768px) {
        margin-right: auto;
        margin-left: auto;
      }

      .flexible-push-portrait--push-content {
        padding: 50px 25px 50px 60px;

        @media (max-width: 768px) {
          padding: 50px 25px 50px 25px;
        }
      }

      .flexible-push-portrait--push-img {
        right: unset;
        left: 0;
        transform: translateX(-80%);

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}
