.document-request {
  margin: 0 auto;
  max-width: 740px;
  width: 100%;

  &__form {
    display: flex;
    flex-direction: column;

    .mat-mdc-text-field-wrapper {
      background-color: white;
    }
  }

  &__infos {
    margin-bottom: 30px;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.8rem;
    letter-spacing: 0.7px;
    color: #143477;
    margin-bottom: 1.2rem;
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: 0.7px;
    margin: 0;
    color: #143477;
  }

  &__select {
    margin-bottom: 10px;
  }

  &__label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input{
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin-bottom: 10px;

    &::placeholder {
      color: $vnf-neutral-600;
    }
  }

  .mdc-text-field--filled {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &__textarea {
    margin-bottom: 30px;
  }
}

.dialog-confirm-email {
  position: relative;
  min-height: 100px;

  .mat-mdc-dialog-header {
    .mat-mdc-dialog-close-button {
      margin-left: auto;
    }
  }

  .mat-mdc-dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
