.flexible_push_service {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &_item {
        display: flex;
        background: white;
        color: $vnf-blue-a600;
        position: relative;
        flex: 0 1 100%;

        @media (min-width: 768px) {
            flex: 0 1 calc(50% - 10px);
        }

        &:has(a[rel="external"]) {
            &:before {
                content: url('/assets/images/icons/external-link.svg');
                position: absolute;
                top: 12px;
                right: 12px;
            }
        }
    }

    &_img {
        width: 102px;
        min-height: 102px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $vnf-degrade-card;

        span {
            color: white;
            font-size: 3rem;
        }
    }

    &_title {
        @include flexTitleL();
        font-size: 1.9rem;
        margin-bottom: 5px;
    }

    &_content {
        flex: 1;
        padding: 10px 10px 0;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    &_link {
        font-size: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
    }

    p {
        font-size: 1.6rem;
    }
}