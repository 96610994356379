.zooms {
  padding-bottom: 30px;

  .zoom {
    position: relative;
    background-color: white;
    box-shadow: 2px 2px 6px 0 #00000014;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    transition: all 0.2s ease-in-out;

    .zoom--image {
      img {
        transition: all .25s linear;
      }
    }

    &:hover,
    &:focus {
      box-shadow: 4px 4px 6px 0 #0000001F;

      .zoom--image {
        img {
          transform: scale(1.04);
        }
      }
    }

    &:has(a[rel="external"]) {
      .zoom--content {
        &:before {
          content: url('/assets/images/icons/external-link.svg');
          position: absolute;
          top: 12px;
          right: 12px;
        }
      }
    }

    &--content {
      padding: 15px 30px 15px 20px;
      height: 151px;
      position: relative;
      width: 100%;
      padding-top: 30px;
    }

    &--image {
      flex: 0 0 100%;
      position: relative;
      width: 100%;
      max-width: 271px;
      max-height: 151px;
      display: flex;
      z-index: 10;
      overflow: hidden;

      img {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        transition: all 0.2s ease-in-out;
      }

      picture {
        display: block;
        width: 100%;
      }
    }

    &--link {
      font-size: 0;

      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    &--modale-opener {
      font-size: 0;

      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 12;
      }
    }

    &--title {
      @include sectionTitle();
      font-size: 1.6rem;
      margin-bottom: 5px;
    }

    &--subtitle,
    &--text {
      @include paragraph();
      margin: 0;
    }

    &--subtitle {
      font-weight: 700;
      margin-bottom: 5px;
    }

    @media (max-width: 1342px) {
      flex-direction: column;

      &--image {
        max-width: unset;

        img {
          border-bottom-left-radius: 0;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
      }
    }
  }

  &.zooms--video {
    .zoom {
      &--content {
        display: flex;
        align-items: center;
        height: 151px;
        padding-top: 15px;

      }

      &--text,
      h3 {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.357;
        display: flex;
        align-items: center;
        gap: 13px;

        @media (max-width: 768px) {
          font-size: 1.6rem;
        }

        &:before {
          content: url('/assets/images/icons/video-play.svg');
        }
      }
    }
  }
}
