.service-intermediaire {
  padding: 40px;

  @media (max-width: 768px) {
    padding: 80px 15px 30px;
  }

  &__chapo {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 400;
    color: #3767CC;
    margin-bottom: 30px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }

    &__item {
      flex: 1 1 50%;
      padding: 15px;
      background-color: white;
    }

    &__img {
      display: block;
      max-width: 220px;
      height: auto;
      width: 100%;
      margin: 0 auto 10px;
    }
  }
}
