.card-item {
    min-height: 242px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 2px 2px 6px 0 #00000014;
    padding: 20px;
    border-radius: 6px;
    width: 100%;
    background: white;
    margin-bottom: 20px;

    &-title {
        @include flexTitleXL();
        color: $vnf-blue-a800;
    }

    &-footer {
        padding: 0 0 20px;
        display: flex;
        justify-content: center;

        @media (min-width: 768px) {
            justify-content: flex-end;
        }
    }
}