.flexible-text {
    overflow: hidden;

    h1 {
        @include flexTitleXXL();
        font-size: 2.5rem;
        line-height: 1.4;
        position: relative;
        padding-bottom: 25px;
        margin-bottom: 25px;

        @media (min-width: 1343px) {
            margin-bottom: 21px;
        }

        &:after {
          bottom: 0;
          position: absolute;
          left: 0;
          font-size: 1.1rem;
          color: $vnf-green-b300;
          content: "\e91e";
          @include icon-font();
        }
    }

    h2 {
        @include flexTitleXL();
        color: $vnf-blue-a800;
    }

    h3 {
        @include flexTitleL();
        color: $vnf-blue-a800;
    }

    p {
        @include flexText();
        color: $vnf-blue-a800;
        b,
        strong {
            font-weight: 700;
        }
    }

    ul {
        margin-bottom: 20px;
        overflow: hidden;

        li {
            @include flexText();
            position: relative;
            padding-left: 30px;
            color: $vnf-blue-a800;

            &:before {
                content: "";
                width: 10px;
                height: 10px;
                background-color: $vnf-blue-a800;
                border-radius: 50%;
                position: absolute;
                top: 7px;
                left: 10px;
            }
        }

        ul {
          margin-top: 20px;

          li {
            &:before {
              content: "";
              width: 3px;
              height: 3px;
            }
          }
        }
    }

    ol {
        counter-reset: ordered;
        margin-bottom: 20px;
        overflow: hidden;

        li {
            @include flexText();
            list-style-type: none;
            padding-left: 30px;
            position: relative;
            color: $vnf-blue-a800;

            &::before {
                content: counter(ordered);
                counter-increment: ordered;
                display: inline-block;
                margin-left: 0;
                margin-top: 0;
                padding: 0;
                position: absolute;
                left: 9px;
                top: 0;
            }
        }
    }

    a {
        color: $vnf-blue-a700;
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .chapo {
        font-size: 2.1rem;
        color: $vnf-blue-a800;

        p {
            font-size: 2.1rem;
            color: $vnf-blue-a800;
        }
    }

    .chapo-introduction {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      line-height: 2.6rem;
      font-size: 2.1rem;
      letter-spacing: 0.056rem;
      color: $vnf-blue-a800;
    }

    .intertitre {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      line-height: 3.8rem;
      letter-spacing: 0.05rem;
      font-size: 3rem;
      color: $vnf-blue-a800;
    }

    .titre-section {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      line-height: 3rem;
      letter-spacing: 0.048rem;
      font-size: 2.3rem;
      color: $vnf-blue-a800;
    }

    .sous-titre-section {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      line-height: 2.3rem;
      letter-spacing: 0.053rem;
      font-size: 1.9rem;
      color: $vnf-blue-a800;
      margin-bottom: 20px;
    }

    .texte-courant {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      line-height: 2.3rem;
      letter-spacing: 0.017rem;
      font-size: 1.6rem;
      color: $vnf-blue-a800;
    }
}

app-page-fille {
  .flexible-text {
    h2 {
      margin-top: 50px;
    }
  }

  .col-left {
    h2 {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

app-page-article {
  flexible-text {
    h1 {
      font-family: "Museo", sans-serif;
      font-weight: 500;
    }
  }
}
