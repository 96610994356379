.options {
  display: none;
  position: absolute;
  bottom: calc(100%);
  left: calc(100%);
  z-index: 100;
  box-shadow: 2px 2px 6px 0px #00000014;
  width: max-content;

  @media (max-width: 768px) {
    &:after {
      content: '\e9a5';
      @include icon-font();
      font-size: 1.8rem;
      position: absolute;
      top: -40px;
      right: 10px;
      width: 30px;
      height: 30px;
      color: white;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--agenda {
    right: 0;
    transform: unset;
    width: fit-content;
    left: unset;
    top: 50px;
  }

  @media (max-width: 768px) {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: unset;
    z-index: 1000;
  }

  &-opener {
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 40px;
    height: 40px;
    right: 5px;
    font-size: 0;
    background-color: transparent;
    border-radius: 100px;
    z-index: 1;

    /* Plus proche du bord quand c'est un agenda */
    &--agenda {
      top: 5px;
      right: 5px;
    }

    &:before {
      @include icon-font();
      font-size: 1.8rem;
      content: '\e9e8';
      color: $vnf-blue-a400;
    }

    &:hover,
    &:focus {
      background-color: #EEF4FF;
    }
  }


.options_list {
  box-shadow: 2px 2px 6px 0px #00000014;
    &--item {
      a, button {
        display: block;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.2;
        letter-spacing: 0.0275rem;
        background-color: #F0F5FF;
        color: $vnf-blue-a800;
        padding: 15px;
        width: 100%;
        text-align: left;

        &:focus,
        &:hover {
          color: white;
          background-color: $vnf-blue-a800 !important;
        }
      }

      border-top: 1px solid $vnf-grey-separation;

      &:first-child {
        border-top: none;
      }
    }


    @media (max-width: 768px) {
      &:before {
        position: fixed;
        content: "";
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: -1;
      }
    }
  }

  &.active {
    display: block;

    .options--mobile_closer {
      @media (max-width: 768px) {
        font-size: 0;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;

        &:before {
          @include icon-font();
          font-size: 1.8rem;
          content: '\e9e8';
          color: $vnf-blue-a400;
        }
      }
    }
  }

  &--mobile_closer {
    display: none;
  }
}

.pagination {
  &--options {
    position: relative;
  }
  .options_list {
    &--item {
      border-top: 0;
      a, button {
        font-family: 'Roboto', sans-serif;
        line-height: 1;
        font-size: 1.3rem;
        padding-left: 20px;
        padding-right: 20px;

        &:not(.active) {
          background-color: #FFFFFF;
        }
      }
    }
  }
}
