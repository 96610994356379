// Define a mixin to handle different status modifiers
@mixin event-status($status, $color, $bgColor) {
  &--#{$status} {
    background-color: $bgColor;
    color: $color;
  }
}

.text-limited--three-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  max-height: 4.5em;
}

.text-limited--two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  max-height: 3em;
}

.timeline {
  position: relative;
  max-width: 100%;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: auto;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
    color: $vnf-blue-a800;
  }

  &__title {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin: unset;
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: unset;
  }

  &::after {
    content: '';
    z-index: 0;
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #ffffff;
  }

  &__wrapper {
    overflow: hidden;
    max-width: 100%;
    position: relative;
    z-index: 2;
  }

  &__months {
    padding-inline: 50px;
    width: fit-content;
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    transition: transform 0.5s ease;
    min-height: 100px;

    @media (max-width: 1200px) {
      pointer-events: none;
    }
  }

  &__month {
    position: relative;
    min-width: 320px;
    flex: 0 0 auto;
    display: flex;
    min-height: 100px;
    padding: 5px;
    justify-content: center;
    border-bottom: 3px solid #ffffff;

    &::after {
      content: '';
      width: 3px;
      height: 14px;
      background-color: #ffffff;
      position: absolute;
      bottom: -14px;
      left: 0;
    }

    &-label {
      height: 48px;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: -71px;
      left: 0;
      color: $vnf-blue-a800;
      font-weight: 700;
      transform: translateX(-50%);
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 20px;

      span {
        display: inline-block;
      }
    }
  }

  &__date {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 5px 5px 20px 5px;

    &::before {
      content: '';
      position: absolute;
      width: 22px;
      height: 22px;
      bottom: -18px;
      border-radius: 50%;
      background: linear-gradient(to bottom, #1DCC95, #4B9FDC);
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      bottom: -13px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }

  &__events {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__event {
    font-family: "Roboto Condensed", sans-serif;
    position: relative;
    background-color: white;
    margin-bottom: 15px;
    text-align: start;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    width: 135px;
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &:hover {
      box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }

    &:last-child::after {
      content: '';
      position: absolute;
      bottom: -9px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid white;
    }

    &--single {
      padding: 34px 10px 20px 10px; /* Padding for spacing */

      @media (max-width: 1200px) {
        pointer-events: auto;
      }
    }

    &--multiple {
      padding: 10px 29px 10px 10px;

      @media (max-width: 1200px) {
        pointer-events: auto;
      }

      .timeline__event-description, .timeline__event-status, .timeline__event-date {
        display: none;
      }
    }
  }

  &__event-content {
    &::before {
      content: '';
      position: absolute;
      right: 12px;
      top: 19px;
      background-color: $vnf-blue-a400;
      width: 14px;
      height: 2px;
    }

    &::after {
      content: '';
      position: absolute;
      right: 18px;
      top: 13px;
      background-color: $vnf-blue-a400;
      height: 14px;
      width: 2px;
    }
  }

  &__event-name {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: start;
    color: $vnf-blue-a800;
  }

  &__event-status {
    @include event-status(prive, #fff, #3767CC);
    @include event-status(public, #fff, #1A871F);
    font-family: "Roboto Condensed", sans-serif;
    font-size: 13px;
    font-weight: 700;
    padding: 0 4px;
    text-transform: uppercase;
    width: fit-content;
  }

  &__event-description {
    font-size: 14px;
    color: #3467CC;
    font-weight: 400;
  }

  &__event-date {
    font-size: 14px;
    line-height: 18px;
    color: #888888;
  }

  &__nav {
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    font-size: 50px;
    cursor: pointer;
    z-index: 3;
    width: 60px;
    color: #888888;
    outline: none;
    background: none;

    &:hover {
      background-color: transparent;
      color: $vnf-blue-a800;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}
