.flexible-highlight-card {

    &-inner {
        border-left: 16px solid $vnf-blue-a500;
        margin-bottom: 30px;
        box-shadow: 2px 2px 6px 0 #00000014;
        background: white;
        max-width: 740px;
        width: 100%;
        padding: 20px 20px 25px 20px;

        @media (min-width: 768px) {
            padding: 20px 40px 25px 40px;
        }
    }

    &.full-width {
        width: calc(100% - 60px) !important;
        max-width: inherit !important;

        .flexible-highlight-card-inner {
            width: 100% !important;
            max-width: inherit !important;
        }
    }

    &-title {
        @include essentielSubtitle();
        color: $vnf-blue-a800;
        font-size: 2.1rem;
        margin-bottom: 5px;
    }

    &-content {
        margin-bottom: 5px;
        overflow: hidden;

        p {
            @include flexText();
            color: $vnf-blue-a800;
            font-size: 2.1rem;
            line-height: 1.3;
            margin-bottom: 15px;

            &:has(strong, b) {
                margin-bottom: 5px;
            }
        }
    }

    .btn {
        font-size: 1.3rem;
        color: $vnf-blue-a800;
        padding: 5px 15px;
    }
}
