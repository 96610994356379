.flexible-arche {
  max-width: 1163px !important;
  min-height: 240px;
  width: auto;
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 20px;

  &.full {
    max-width: 100% !important;
    margin-inline: 0 !important;
  }

  &--title {
    font-size: 44px;
    color: white;
    font-weight: 300;
    padding: 40px 105px;
    position: relative;
    width: 100%;
    margin: auto;
    transform: translateY(-15px);

    @media (max-width: 768px) {
      font-size: 31px;
      padding: 40px 30px;
    }

    &:after {
      bottom: 0;
      position: absolute;
      left: 115px;
      font-size: 2.85rem;
      color: $vnf-green-b300;
      content: "\e92c";
      @include icon-font();

      @media (max-width: 768px) {
        left: 30px;
      }
    }
  }

  &--background {
    position: absolute;
    display: flex;
    min-height: 240px;
    width: 100%;
    max-width: 1696px;

    &.background-darker-blue {
      background-color: $vnf-blue-a900;
    }

    &.background-blue {
      background-color: $vnf-blue-a700;
    }

    &.background-white {
      background-color: white;

      .flexible-arche--title {
        color: $vnf-blue-a800;
      }
    }
  }

  &--container {
    margin: 0 10%;
    position: relative;
    z-index: 1;
  }
}
