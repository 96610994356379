.bando_interation_top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
    margin-bottom: 20px;

    @media (min-width: 1343px) {
        margin-bottom: 30px;
    }

    &_back {
        font-size: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            @include icon-font();
            content: "\e91a";
            color: $vnf-grey-swiper-btn;
            font-size: 3rem;

        }
    }

    &_share {
        font-size: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            @include icon-font();
            content: "\e91b";
            color: $vnf-grey-swiper-btn;
            font-size: 1.9rem;

        }
    }
}