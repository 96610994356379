
body {
  font-family: 'Roboto', sans-serif;
}

.child-page h1 {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
  color: $vnf-blue-a800;
  letter-spacing: 0.35px;
  line-height: 3.8rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 3.2rem;
  }

  &:after {
    content: "";
    display: block;
    height: 4px;
    width: 24px;
    background: #86EC8B;
    transform: rotate(-11deg);
    position: absolute;
    bottom: -27px;
  }
}

h3 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.17;
  color: $vnf-blue-a800;
}

@mixin sectionTitle {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.07rem;
  line-height: 1;
  text-transform: uppercase;
  color: $vnf-blue-a800;
}

@mixin btn {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.125;
  text-align: center;
  padding: 7px 20px;
  letter-spacing: 0.0625rem;
  text-decoration: none;
  display: inline-flex;
  transition: 0.2s ease-in-out all;
}

@mixin btn-xl {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 18.75px;
  text-align: center;
  padding: 7px 20px;
  text-decoration: none;
  display: inline-flex;
  text-transform: uppercase;
}

@mixin cardTitleXL {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 1;
  color: white;
}

@mixin flexTitleXXL {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 2.3rem;
  line-height: 1.2;
  color: $vnf-blue-a800;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 3.5rem;
    line-height: 1.086;
    margin-bottom: 20px;
  }
}

@mixin flexTitleXL {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 3rem;
  line-height: 1.27;
  color: $vnf-blue-a900;
  margin-bottom: 20px;
}

@mixin flexTitleL {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 2.3rem;
  line-height: 1.17;
  color: $vnf-blue-a900;
  margin-bottom: 20px;
}

@mixin flexText {
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.44;
  color: $vnf-blue-a900;
  margin-bottom: 20px;
}

@mixin cardText {
  color: $vnf-blue-a800;
  font-family: "Museo", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.17;
  overflow-wrap: break-word;
}

@mixin cardTextSmall {
  color: $vnf-blue-a800;
  font-family: "Museo", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  overflow-wrap: break-word;
}

@mixin cardTextMedium {
  color: $vnf-blue-a800;
  font-family: "Museo", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  overflow-wrap: break-word;
}

@mixin essentielTitle {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.167;
  text-align: center;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  color: $vnf-blue-a800;
}

@mixin essentielSubtitle {
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.125;
}

@mixin titleDecoIconAndUnderline {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 1.172;
  letter-spacing: 0.03182rem;
  color: $vnf-blue-a600;
  position: relative;
  padding: 0 0 18px 57px;
  margin: 0 0 25px 0;

  &::first-letter {
    letter-spacing: 0.25rem;
  }

  &:after {
    @include icon-font();
    content: "\e992";
    color: #86EC8B;
    position: absolute;
    bottom: 0;
    left: 57px;
  }

  [class^="vnf-icons-"],
  [class*=" vnf-icons-"] {
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      color: $vnf-blue-a600;
      font-size: 4.4rem;
    }
  }
}

@mixin titleFormStep {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 25.78px;
  color: $vnf-blue-a600;
  padding-bottom: 15px;

  &:after {
    content: "";
    display: block;
    height: 4px;
    width: 24px;
    background: #86EC8B;
    transform: rotate(-11deg);
    position: absolute;
    bottom: 0;
  }
}

@mixin personalItem {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 2.0rem;
  color: white;
}

@mixin personalDropdownTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.8rem;
  letter-spacing: 0.7px;
  color: $vnf-blue-a500;
}

@mixin date {
  color: $vnf-neutral-700;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.167;
}

@mixin tag {
  text-transform: uppercase;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 0.03375rem;
  line-height: 1;
}

@mixin paragraph {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.21;
  color: $vnf-blue-a800;
}

.error-message {
  color: #d32f2f;
  font-size: 14px;
}

pre {
  margin-bottom: 30px;
  font-size: 2.5rem;
}
