.list__card {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        max-width: 100%;
        margin-bottom: 30px;
    }

    &__item {
        position: relative;
        padding: 20px 20px 10px 30px;
        box-shadow: 2px 2px 6px 0 #00000014;
        border-radius: 6px;
        background: white;
        width: 100%;
        transition: all 0.2s ease-in-out;

      &:hover,
      &:focus {
        box-shadow: 4px 4px 6px 0px #0000001F;
      }

        &__link {
            position: absolute;
            font-size: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;

          &[rel="external"] {
            &:before {
              content: url('/assets/images/icons/external-link.svg');
              position: absolute;
              top: 7px;
              right: 7px;
            }
          }
        }

        @media (min-width: 768px) and (max-width: 1342px) {
            flex: 0 1 calc(50% - 10px);
            max-width: calc(50% - 10px);
        }

        @media (min-width: 1730px) {
            flex: 0 1 calc(50% - 10px);
            max-width: calc(50% - 10px);
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 10px;
            height: 100%;
            background-color: $vnf-blue-a100;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }
    }

    &__text {
        max-width: 100%;

        p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .list__card__title {
        @include essentielTitle();
        text-transform: uppercase;
        text-align: left;
        font-size: 1.6rem;
        margin-bottom: 5px;
    }
}
