.bloc_title_select {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 15px 15px 10px 15px;
    background: $vnf-neutral-50;

    @media (min-width: 768px) {
        padding: 30px 30px 20px 30px;
    }

    &_title {
        @include flexTitleXL();
        position: relative;
        color: $vnf-blue-a800;
        width: 100%;
        margin-bottom: 10px;
        padding-bottom: 20px;

        @media (min-width: 768px) {
            width: auto;
            flex: 1;
        }

        &:after {
            content: "";
            display: block;
            height: 4px;
            width: 24px;
            background: #86EC8B;
            transform: rotate(-11deg);
            position: absolute;
            bottom: 7px;
        }
    }

    &_select {
        background: transparent;
        width: 100%;

        @media (min-width: 768px) {
            width: auto;
        }
    }

    .mat-mdc-form-field {
        width: 100%;

        @media (min-width: 768px) {
            width: auto;
        }
    }

    & ~ div {
        width: 100%;
        max-width: inherit;
        padding: 15px 15px 0 15px;
        background: $vnf-neutral-50;

        @media (min-width: 768px) {
            padding: 30px 30px 0 30px;
        }
    }

    app-flexibles {
        display: block;
        width: 100%;

      .flexible-content:not(.mat-mdc-tab-group) {
            max-width: 740px;
        }

        // Exception in "app-flexible-onglets": no max-width applied to this content when it is inside "app-flexibles"
        app-flexible-onglets {
            .flexible-content {
                max-width: inherit;
            }
        }
    }
}
