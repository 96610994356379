.mat-mdc-form-field-type-mat-select {
    .mdc-text-field--filled {
        overflow: visible;
        border: 1px solid $vnf-grey-select;
        border-bottom-right-radius: var(--mdc-filled-text-field-container-shape);
        border-bottom-left-radius: var(--mdc-filled-text-field-container-shape);
        padding: 0 18px 0 13px;

        &.mdc-text-field--focused {
            border: 1px solid $vnf-blue-a700;
        }

        .mdc-floating-label {
            background: white;

            &--float-above {
                transform: translateY(-220%) scale(1);
            }
        }

        .mat-mdc-form-field-infix {
            display: flex;
        }

        .mat-mdc-select {
            &-trigger {
                height: 100%;
            }

            &[aria-expanded="true"] {
                .mat-mdc-select-arrow {
                    margin-left: 5px;

                    svg {
                        transform: translate(-50%, -50%) rotate(180deg);
                    }
                }
            }
        }

        .mat-pseudo-checkbox {
            display: none;
        }
    }

    &.tiny-select {
        .mdc-floating-label,
        .mdc-text-field--filled {
            background: $vnf-neutral-50;
        }

        .mdc-text-field--filled {
            border-width: 2px !important;

            .mdc-floating-label--float-above {
                transform: translateY(-170%) scale(1);
            }
        }

        .mat-mdc-form-field-flex {
            .mdc-floating-label {
                top: calc(var(--mat-form-field-container-tiny-height) / 2);
            }
        }

        .mat-mdc-form-field-infix {
            min-height: var(--mat-form-field-container-tiny-height);
        }
    }
}

.mat-mdc-select-panel {
    .mat-pseudo-checkbox {
        display: none;
    }
    .mat-mdc-option {
        transition: 0.25s all;

        &:hover,
        &-selected,
        &:focus {
            color: white;
            transition: 0.25s all;

            &:hover,
            &:focus {
                background: $vnf-blue-a800;
                color: white;
            }
        }
    }
}
