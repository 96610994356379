.flexible-video {
  display: flex;

  &:hover,
  &:focus {
    .thumbnail {
      transform: scale(1.05);
    }
  }
}

.thumbnail-container {
  cursor: pointer;
  position: relative;
  max-width: 740px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 50px solid white;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
}

.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  transition: all 0.2s ease-in-out;
}

.video-frame {
  max-width: 740px;
  height: 416px;
}

.video-container {
  width: 100%;
}

.video-frame iframe {
  border: none;
  width: 100%;
}
