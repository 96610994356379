.participation {
  position: relative;
  border-radius: 6px;
  background-color: white;
  box-shadow: 2px 2px 6px 0px #00000014;
  padding-left: 20px;

  @media (max-width: 480px) {
    height: auto;
  }

  .tag {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: linear-gradient(180deg, #1DCC95 0%, #4B9FDC 100%);
    width: 20px;
    height: 100%;
  }

  &:hover,
  &:focus {
    box-shadow: 4px 4px 6px 0px #0000001F;

    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
    }
  }

  &__item {
    position: relative;
    padding: 54px 15px 54px 35px;

    @media (max-width: 768px) {
      padding: 54px 15px 68px 35px;
    }
  }

  &__participate {
    font-family: "Roboto Condensed", sans-serif;
    color: $vnf-blue-a800;
    font-size: 1.8rem;
    font-weight: 400;

    &:before {
      content: url('/assets/images/icons/icons-titles/flag.svg');
      width: 33px;
      height: 39px;
      display: inline-block;
      margin-right: 8px;
    }
  }

  &__title {
    font-family: "Museo", sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
    color: $vnf-blue-a800;
    margin: 0;
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    color: $vnf-blue-a800;
  }

  swiper-container::part(pagination) {
    width: auto;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 768px) {
      bottom: 20px;
    }
  }

  swiper-container::part(bullet-active),
  swiper-container::part(bullet) {
    background: $vnf-neutral-400;
  }

  .swiper-container::part(bullet-active) {
    width: 8px;
    height: 8px;
    padding: 4px;
    border: 9px solid white;
    box-sizing: border-box;
    margin: 0;
  }

  .swiper-container::part(bullet) {
    width: 5px;
    height: 5px;
    padding: 3px;
    border: 10px solid white;
    box-sizing: border-box;
    margin: 0;
    transform: translateY(2px);
  }

  .swiper-button-next {
    display: none;
    cursor: pointer;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 32px;
    height: 32px;
    transform: translateX(30px) rotate(0deg);
    justify-content: center;
    align-items: center;
    z-index: 998;

    @media (max-width: 768px) {
      display: flex;
      bottom: 20px;
    }

    &:after {
      @include icon-font;
      content: "\e916";
      font-size: 2rem;
      color: $vnf-neutral-400;
    }
  }

  .swiper-button-prev {
    display: none;
    cursor: pointer;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 32px;
    height: 32px;
    transform: translateX(-43px) rotate(-180deg);
    justify-content: center;
    align-items: center;
    z-index: 998;

    @media (max-width: 768px) {
      display: flex;
      bottom: 20px;
    }

    &:after {
      @include icon-font;
      content: "\e916";
      font-size: 2rem;
      color: $vnf-neutral-400;
    }
  }

  .swiper-button-disabled {
    &:after {
      color: $vnf-neutral-300;
    }
  }

  .participation__list.one-slide {
    .participation__item {
      padding: 15px 15px 30px 35px !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: none !important;
    }
  }

  &--home {
    .participation {
      margin-bottom: 30px;

      &__item {
        padding: 15px 15px 65px 35px !important;
      }
    }
  }

  &_page--container {
    display: flex;
    justify-content: center;
    width: 100%;

    .btn {
      font-size: 1.8rem;
    }
  }
}

.col-left {
  .participation {
    &__item {
      @media (min-width: 1343px) {
        padding: 25px 35px 35px 35px;
      }
    }
  }
}
