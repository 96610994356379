.modale-email-title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 2.0rem;
  line-height: 2.3rem;
  color: $vnf-blue-a800;
  padding: var(--mat-dialog-content-padding);
}

.modale-timeline-event-title-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px 30px;
}

.modale-timeline-event-title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 2.3rem;
  color: $vnf-blue-a800;
  text-transform: uppercase;
  margin: 0 0 0.5rem;
}

.modale-timeline-event-description {
  font-size: 16px;
}

.modale-timeline-event-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.document-dialog {
  margin-top: 15px;

  p {
    width: fit-content;
    word-break: break-word;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-size: 2.0rem;
    font-weight: 700;
    line-height: 2.344rem;
    color: $vnf-blue-a800;
    padding: var(--mat-dialog-content-padding);
    word-break: break-word;
    margin: 0;
  }

  &__label {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.3rem;
    min-height: 1.3rem;
    letter-spacing: 0.54px;
    background-color: $vnf-blue-a800;
    color: white;
    margin-bottom: 3px;
    text-transform: uppercase;
    padding: 2px 4px;
  }

  &__info {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.8rem;
    min-height: 1.8rem;
    margin-bottom: 15px;
  }
}

.pdf-viewer {
  height: 52vh;
  width: 800px;
  @media (max-width: 1342px) {
    width: 600px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
