hr {
    border: none;
    border-top: 1px solid #B2B2B2;
    height: 1px;
}

.article {
  hr {
    width: 100vw;
    position: absolute;
    right: 0;
    max-width: 1850px;
  }
}
