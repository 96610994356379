.main-layout {
    .flexible-content {
        margin-bottom: 15px;

        @media (min-width: 768px) {
            margin-bottom: 20px;
        }
    }

    .bg-white {
        width: 100%;
    }

  app-breadcrumb {
    + flexible-text {
      .bg-white {
        background-color: transparent;
      }
    }
  }
}


