@font-face {
  font-family: 'vnf-icons-v2';
  src:  url('../../fonts/fonticons/vnf-icons-v2.eot');
  src:  url('../../fonts/fonticons/vnf-icons-v2.eot') format('embedded-opentype'),
  url('../../fonts/fonticons/vnf-icons-v2.woff2') format('woff2'),
  url('../../fonts/fonticons/vnf-icons-v2.ttf') format('truetype'),
  url('../../fonts/fonticons/vnf-icons-v2.woff') format('woff'),
  url('../../fonts/fonticons/vnf-icons-v2.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'vnf-icons-v2' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="vnf-icons-"],
[class*=" vnf-icons-"] {
  @include icon-font;
}

.vnf-icons-ico-courriers:before {
  content: "\e9ea";
}
.vnf-icons-ico-medeplacer:before {
  content: "\e9eb";
}
.vnf-icons-ico-meformer:before {
  content: "\e9ec";
}
.vnf-icons-icon-vertical-dots:before {
  content: "\e9e8";
}
.vnf-icons-icon-demanderservicerh:before {
  content: "\e9e6";
}
.vnf-icons-icon-m-reglages-outline:before {
  content: "\e9e5";
}
.vnf-icons-icon-m-accessibility:before {
  content: "\e9c5";
}
.vnf-icons-icon-m-maison-eclusiere-outline:before {
  content: "\e9d4";
}
.vnf-icons-icon-m-site-activite-outline:before {
  content: "\e9e4";
}
.vnf-icons-icon-m-escales-de-croisiere-outline:before {
  content: "\e9e3";
}
.vnf-icons-icon-m-site-plaisance-outline:before {
  content: "\e9d5";
}
.vnf-icons-icon-m-site-patrimonial-outline:before {
  content: "\e9d6";
}
.vnf-icons-icon-m-attentionSliderAlert-solid:before {
  content: "\e9d7";
}
.vnf-icons-icon-m-verrou-solid:before {
  content: "\e9d8";
}
.vnf-icons-icon-m-verrou-outline:before {
  content: "\e9d9";
}
.vnf-icons-icon-m-check-2-outline:before {
  content: "\e9da";
}
.vnf-icons-icon-m-check-outline:before {
  content: "\e9db";
}
.vnf-icons-icon-m-presse-outline:before {
  content: "\e9dc";
}
.vnf-icons-icon-m-accessibilite-solid:before {
  content: "\e9dd";
}
.vnf-icons-icon-m-upload-2-outline:before {
  content: "\e9de";
}
.vnf-icons-icon-m-caddy-solid:before {
  content: "\e9df";
}
.vnf-icons-icon-m-caddy-outline:before {
  content: "\e9e0";
}
.vnf-icons-icon-m-euros-solid:before {
  content: "\e9e1";
}
.vnf-icons-icon-m-euros-outline:before {
  content: "\e9e2";
}
.vnf-icons-icon-m-flip-outline:before {
  content: "\e9d2";
}
.vnf-icons-icon-menu-dragg-solid:before {
  content: "\e9d1";
}
.vnf-icons-icon-m-phone-solid:before {
  content: "\e9cc";
}
.vnf-icons-icon-m-arbres-outline:before {
  content: "\e9ca";
}
.vnf-icons-icon-m-multi-actvites-outline:before {
  content: "\e9c9";
}
.vnf-icons-icon-m-multi-site-outline:before {
  content: "\e9c8";
}
.vnf-icons-icon-m-vagues-outline:before {
  content: "\e9c7";
}
.vnf-icons-icon-m-facebook-solid:before {
  content: "\e9d0";
}
.vnf-icons-icon-m-instagram-solid:before {
  content: "\e93f";
}
.vnf-icons-icon-m-linkedin-solid:before {
  content: "\e948";
}
.vnf-icons-icon-m-twitter-solid:before {
  content: "\e964";
}
.vnf-icons-icon-m-menu-a-outline:before {
  content: "\e9a4";
}
.vnf-icons-icon-loupe-outline:before {
  content: "\e977";
}
.vnf-icons-icon-m-engrenage-solid:before {
  content: "\e9ad";
}
.vnf-icons-icon-m-fleche-a-droite-outline:before {
  content: "\e9ac";
}
.vnf-icons-icon-m-croix-fermer-solid:before {
  content: "\e9ae";
}
.vnf-icons-icon-m-fleche-b-gauche-outline:before {
  content: "\e9af";
}
.vnf-icons-icon-m-bateau-face-a-solid:before {
  content: "\e92a";
}
.vnf-icons-icon-m-fleche-a-bas-outline:before {
  content: "\e92b";
}
.vnf-icons-icon-special-slash-identifiant-solid:before {
  content: "\e92c";
}
.vnf-icons-icon-m-dossier-solid:before {
  content: "\e920";
}
.vnf-icons-icon-m-attention-outline:before {
  content: "\e9cb";
}
.vnf-icons-icon-m-fleche-a-haut-outline:before {
  content: "\e924";
}
.vnf-icons-icon-m-boussole-solid:before {
  content: "\e929";
}
.vnf-icons-icon-m-grue-a-solid:before {
  content: "\e928";
}
.vnf-icons-icon-m-equerre-solid:before {
  content: "\e92d";
}
.vnf-icons-icon-m-information-solid:before {
  content: "\e936";
}
.vnf-icons-icon-m-position-c-outline:before {
  content: "\e937";
}
.vnf-icons-icon-m-flux-rss-outline:before {
  content: "\e938";
}
.vnf-icons-icon-m-moins-rond-solid:before {
  content: "\e939";
}
.vnf-icons-icon-m-plus-rond-solid:before {
  content: "\e93a";
}
.vnf-icons-icon-m-signe-dt-solid:before {
  content: "\e93b";
}
.vnf-icons-icon-m-triangle-bas-solid:before {
  content: "\e93c";
}
.vnf-icons-icon-m-document-solid:before {
  content: "\e93d";
}
.vnf-icons-icon-m-telephone-outline:before {
  content: "\e93e";
}
.vnf-icons-icon-m-email-solid:before {
  content: "\e921";
}
.vnf-icons-icon-m-vue-grille-outline:before {
  content: "\e926";
}
.vnf-icons-icon-m-filtres-a-outline:before {
  content: "\e922";
}
.vnf-icons-icon-fleche-a-gauche-outline:before {
  content: "\e91f";
}
.vnf-icons-icon-m-fleche-b-droite-outline:before {
  content: "\e925";
}
.vnf-icons-icon-m-lien-externe-outline:before {
  content: "\e9c3";
}
.vnf-icons-icon-m-information-outline:before {
  content: "\e927";
}
.vnf-icons-icon-m-double-fleche-bas-outline:before {
  content: "\e923";
}
.vnf-icons-icon-m-actualite-outline:before {
  content: "\e9c0";
}
.vnf-icons-icon-m-france-outline:before {
  content: "\e9c1";
}
.vnf-icons-icon-m-position-b-solid:before {
  content: "\e9bd";
}
.vnf-icons-icon-m-dezoom-outline:before {
  content: "\e9bc";
}
.vnf-icons-icon-m-zoom-outline:before {
  content: "\e9ba";
}
.vnf-icons-icon-m-minimise-outline:before {
  content: "\e9cf";
}
.vnf-icons-icon-m-maximise-outline:before {
  content: "\e9b6";
}
.vnf-icons-icon-m-triangle-droite-solid:before {
  content: "\e9b7";
}
.vnf-icons-icon-m-triangle-gauche-solid:before {
  content: "\e9b3";
}
.vnf-icons-icon-m-maison-solid:before {
  content: "\e9b0";
}
.vnf-icons-icon-m-moins-outline:before {
  content: "\e92e";
}
.vnf-icons-icon-m-plus-outline:before {
  content: "\e92f";
}
.vnf-icons-icon-m-telechargement-outline:before {
  content: "\e930";
}
.vnf-icons-icon-m-actualite-solid:before {
  content: "\e931";
}
.vnf-icons-icon-m-redimensionner-outline:before {
  content: "\e932";
}
.vnf-icons-icon-m-fleche-a-haut-bas-solid:before {
  content: "\e933";
}
.vnf-icons-icon-m-bat-bateau-mouvant-solid:before {
  content: "\e934";
}
.vnf-icons-icon-m-traffic-solid:before {
  content: "\e935";
}
.vnf-icons-icon-m-double-fleche-haut-outline:before {
  content: "\e9c2";
}
.vnf-icons-icon-m-fleche-b-haut-outline:before {
  content: "\e9bf";
}
.vnf-icons-icon-m-fleche-b-bas-outline:before {
  content: "\e9be";
}
.vnf-icons-icon-m-play-store-solid:before {
  content: "\e9b9";
}
.vnf-icons-icon-m-app-store-solid:before {
  content: "\e9bb";
}
.vnf-icons-icon-m-facebook-like-solid:before {
  content: "\e9b8";
}
.vnf-icons-icon-m-oeil-outline:before {
  content: "\e9b5";
}
.vnf-icons-icon-m-oeil-solid:before {
  content: "\e9b4";
}
.vnf-icons-icon-annonce-femme-outline:before {
  content: "\e9b2";
}
.vnf-icons-icon-annonce-homme-outline:before {
  content: "\e9b1";
}
.vnf-icons-icon-attention-solid:before {
  content: "\e940";
}
.vnf-icons-icon-diamant-solid:before {
  content: "\e941";
}
.vnf-icons-icon-m-antenne-outline:before {
  content: "\e942";
}
.vnf-icons-icon-m-arborescence-outline:before {
  content: "\e943";
}
.vnf-icons-icon-m-arborescence-solid:before {
  content: "\e944";
}
.vnf-icons-icon-m-avibat-outline:before {
  content: "\e945";
}
.vnf-icons-icon-m-avibat-solid:before {
  content: "\e946";
}
.vnf-icons-icon-m-bat-bateau-arret-solid:before {
  content: "\e947";
}
.vnf-icons-icon-m-batiment-outline:before {
  content: "\e9c6";
}
.vnf-icons-icon-m-batiment-solid:before {
  content: "\e949";
}
.vnf-icons-icon-m-bat-pont-mobile-ferme-solid:before {
  content: "\e94a";
}
.vnf-icons-icon-m-bat-pont-mobile-ouvert-solid:before {
  content: "\e94b";
}
.vnf-icons-icon-m-bat-stationnement-solid:before {
  content: "\e94c";
}
.vnf-icons-icon-m-calendrier-marrees-outline:before {
  content: "\e94d";
}
.vnf-icons-icon-m-calques-outline:before {
  content: "\e94e";
}
.vnf-icons-icon-m-carte-outline:before {
  content: "\e94f";
}
.vnf-icons-icon-m-carte-solid:before {
  content: "\e950";
}
.vnf-icons-icon-m-cgu-outline:before {
  content: "\e951";
}
.vnf-icons-icon-m-cloche-outline:before {
  content: "\e952";
}
.vnf-icons-icon-m-cloche-solid:before {
  content: "\e953";
}
.vnf-icons-icon-m-corbeille-outline:before {
  content: "\e954";
}
.vnf-icons-icon-m-corbeille-solid:before {
  content: "\e955";
}
.vnf-icons-icon-m-crayon-outline:before {
  content: "\e956";
}
.vnf-icons-icon-m-crayon-solid:before {
  content: "\e957";
}
.vnf-icons-icon-m-croix-fermer-outline:before {
  content: "\e958";
}
.vnf-icons-icon-m-deconnexion-outline:before {
  content: "\e959";
}
.vnf-icons-icon-m-detail-outline:before {
  content: "\e95a";
}
.vnf-icons-icon-m-dialogue-outline:before {
  content: "\e95b";
}
.vnf-icons-icon-m-dialogue-solid:before {
  content: "\e95c";
}
.vnf-icons-icon-m-document-outline:before {
  content: "\e95d";
}
.vnf-icons-icon-m-dossier-outline:before {
  content: "\e95e";
}
.vnf-icons-icon-m-double-fleche-droite-outline:before {
  content: "\e95f";
}
.vnf-icons-icon-m-double-fleche-gauche-outline:before {
  content: "\e960";
}
.vnf-icons-icon-m-drapeau-position-outline:before {
  content: "\e961";
}
.vnf-icons-icon-m-email-outline:before {
  content: "\e962";
}
.vnf-icons-icon-m-engrenage-outline:before {
  content: "\e963";
}
.vnf-icons-icon-m-erreur-outline:before {
  content: "\e9ce";
}
.vnf-icons-icon-m-erreur-solid:before {
  content: "\e965";
}
.vnf-icons-icon-m-filtres-b-outline:before {
  content: "\e966";
}
.vnf-icons-icon-m-filtres-b-solid:before {
  content: "\e967";
}
.vnf-icons-icon-m-fleche-b-bas-droite-outline:before {
  content: "\e968";
}
.vnf-icons-icon-m-fleche-b-haut-droite-outline:before {
  content: "\e969";
}
.vnf-icons-icon-m-hauteurs-libre-outline:before {
  content: "\e96a";
}
.vnf-icons-icon-m-imprimante-outline:before {
  content: "\e96b";
}
.vnf-icons-icon-m-liens-outline:before {
  content: "\e96c";
}
.vnf-icons-icon-m-liste-outline:before {
  content: "\e96d";
}
.vnf-icons-icon-m-liste-solid:before {
  content: "\e96e";
}
.vnf-icons-icon-m-livre-etiquette-outline:before {
  content: "\e96f";
}
.vnf-icons-icon-m-livre-outline:before {
  content: "\e970";
}
.vnf-icons-icon-m-localisation-outline:before {
  content: "\e971";
}
.vnf-icons-icon-m-localisation-solid:before {
  content: "\e972";
}
.vnf-icons-icon-m-loupe-solid:before {
  content: "\e973";
}
.vnf-icons-icon-m-maison-outline:before {
  content: "\e974";
}
.vnf-icons-icon-m-mentions-legales-outline:before {
  content: "\e975";
}
.vnf-icons-icon-m-menuautre-outline:before {
  content: "\e976";
}
.vnf-icons-icon-m-menuautre-solid:before {
  content: "\e9cd";
}
.vnf-icons-icon-m-menu-b-outline:before {
  content: "\e978";
}
.vnf-icons-icon-m-menu-b-solid:before {
  content: "\e979";
}
.vnf-icons-icon-m-moins-rond-outline:before {
  content: "\e97a";
}
.vnf-icons-icon-m-montre-outline:before {
  content: "\e97b";
}
.vnf-icons-icon-m-mouillages-debits-outline:before {
  content: "\e97c";
}
.vnf-icons-icon-m-note-outline:before {
  content: "\e97d";
}
.vnf-icons-icon-m-pause-outline:before {
  content: "\e97e";
}
.vnf-icons-icon-m-personnes-outline:before {
  content: "\e97f";
}
.vnf-icons-icon-m-planning-outline:before {
  content: "\e980";
}
.vnf-icons-icon-m-play-outline:before {
  content: "\e981";
}
.vnf-icons-icon-m-plus-rond-outline:before {
  content: "\e982";
}
.vnf-icons-icon-m-position-a-outline:before {
  content: "\e983";
}
.vnf-icons-icon-m-position-a-solid:before {
  content: "\e984";
}
.vnf-icons-icon-m-position-b-outline:before {
  content: "\e985";
}
.vnf-icons-icon-m-profil-outline:before {
  content: "\e986";
}
.vnf-icons-icon-m-profil-solid:before {
  content: "\e987";
}
.vnf-icons-icon-m-quai-outline:before {
  content: "\e988";
}
.vnf-icons-icon-m-question-outline:before {
  content: "\e989";
}
.vnf-icons-icon-m-question-solid:before {
  content: "\e98a";
}
.vnf-icons-icon-m-reglages-half-outline:before {
  content: "\e98b";
}
.vnf-icons-icon-m-reglages-solid:before {
  content: "\e98c";
}
.vnf-icons-icon-m-regles-outline:before {
  content: "\e98d";
}
.vnf-icons-icon-m-reseau-outline:before {
  content: "\e98e";
}
.vnf-icons-icon-m-reseau-solid:before {
  content: "\e98f";
}
.vnf-icons-icon-m-restrictions-outline:before {
  content: "\e990";
}
.vnf-icons-icon-m-sas-indispos-outline:before {
  content: "\e991";
}
.vnf-icons-icon-m-slash-identifiant-solid:before {
  content: "\e992";
}
.vnf-icons-icon-m-spyscroll-outline:before {
  content: "\e993";
}
.vnf-icons-icon-m-succes-outline:before {
  content: "\e994";
}
.vnf-icons-icon-m-success-solid:before {
  content: "\e995";
}
.vnf-icons-icon-m-suggestion-outline:before {
  content: "\e996";
}
.vnf-icons-icon-m-triangle-bas-outline:before {
  content: "\e997";
}
.vnf-icons-icon-m-triangle-droite-outline:before {
  content: "\e998";
}
.vnf-icons-icon-m-triangle-haut-bas-solid:before {
  content: "\e999";
}
.vnf-icons-icon-m-triangle-haut-outline:before {
  content: "\e99a";
}
.vnf-icons-icon-m-triangle-haut-solid:before {
  content: "\e99b";
}
.vnf-icons-icon-m-upload-outline:before {
  content: "\e99c";
}
.vnf-icons-icon-m-volet-gauche-outline:before {
  content: "\e99d";
}
.vnf-icons-icon-m-voyage-outline:before {
  content: "\e99e";
}
.vnf-icons-icon-m-voyage-solid:before {
  content: "\e99f";
}
.vnf-icons-icon-m-youtube-solid:before {
  content: "\e9a0";
}
.vnf-icons-icon-m-zone-outline:before {
  content: "\e9a1";
}
.vnf-icons-icon-reportage-metier-femme-outline:before {
  content: "\e9a2";
}
.vnf-icons-icon-reportage-metier-homme-outline:before {
  content: "\e9a3";
}
.vnf-icons-icon-s-attention-solid:before {
  content: "\e9d3";
}
.vnf-icons-icon-s-croix-solid:before {
  content: "\e9a5";
}
.vnf-icons-icon-s-erreur-solid:before {
  content: "\e9a6";
}
.vnf-icons-icon-s-information-solid:before {
  content: "\e9a7";
}
.vnf-icons-icon-s-lien-externe-outline:before {
  content: "\e9a8";
}
.vnf-icons-icon-s-oeil-barre-solid:before {
  content: "\e9a9";
}
.vnf-icons-icon-s-oeil-solid:before {
  content: "\e9aa";
}
.vnf-icons-icon-s-succes-solid:before {
  content: "\e9ab";
}
.vnf-icons-m-check-big:before {
  content: "\e9c4";
}
.vnf-icons-icon-missing-file:before {
  content: "\e9e7";
}
.vnf-icons-icon-diagonal:before {
  content: "\e91e";
}
.vnf-icons-icon-bookmark:before {
  content: "\e91a";
}
.vnf-icons-icon-apps:before {
  content: "\e91b";
}
.vnf-icons-icon-dialogs:before {
  content: "\e91c";
}
.vnf-icons-icon-link:before {
  content: "\e91d";
}
.vnf-icons-icon-flash:before {
  content: "\e919";
}
.vnf-icons-icon-accessibility:before {
  content: "\e917";
}
.vnf-icons-icon-news:before {
  content: "\e912";
}
.vnf-icons-icon-chevron:before {
  content: "\e916";
}
.vnf-icons-icon-play:before {
  content: "\e918";
}
.vnf-icons-icon-home:before {
  content: "\e914";
}
.vnf-icons-icon-home-solid:before {
  content: "\e915";
}
.vnf-icons-icon-collabs:before {
  content: "\e900";
}
.vnf-icons-icon-collabs-solid:before {
  content: "\e901";
}
.vnf-icons-icon-community:before {
  content: "\e902";
}
.vnf-icons-icon-community-solid:before {
  content: "\e903";
}
.vnf-icons-icon-document:before {
  content: "\e904";
}
.vnf-icons-icon-document-solid:before {
  content: "\e905";
}
.vnf-icons-icon-it:before {
  content: "\e906";
}
.vnf-icons-icon-it-solid:before {
  content: "\e907";
}
.vnf-icons-icon-menu-cap:before {
  content: "\e908";
}
.vnf-icons-icon-menu-cap-solid:before {
  content: "\e909";
}
.vnf-icons-icon-menu-daily:before {
  content: "\e90a";
}
.vnf-icons-icon-menu-daily-solid:before {
  content: "\e90b";
}
.vnf-icons-icon-menu-identity:before {
  content: "\e90c";
}
.vnf-icons-icon-menu-identity-solid:before {
  content: "\e90d";
}
.vnf-icons-icon-modify:before {
  content: "\e90e";
}
.vnf-icons-icon-modify-solid:before {
  content: "\e90f";
}
.vnf-icons-icon-moving:before {
  content: "\e910";
}
.vnf-icons-icon-moving-solid:before {
  content: "\e911";
}
.vnf-icons-icon-news-solid:before {
  content: "\e913";
}
