app-sidenav {
  position: relative;
  z-index: 1000;
}

.vnf-sidenav {
  position: sticky;
  top: 0;
  background: $vnf-degrade-menu;
  width: 93px;
  height: 100vh;
  transition: all 0.2s ease-in-out;

  &-toggle-container {
    background-color: #19c995;
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 93px;
    transition: width 0.2s ease-in-out;
    z-index: 1;

    &.scrolled {
      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        bottom: -10px;
        width: 100%;
        height: 12px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.23) -40%, rgba(102, 102, 102, 0) 50%);
      }
    }
  }

  &-menu-container {
    display: flex;
    padding-top: 40px;
    flex-direction: column;
    position: relative;
    gap: 50px;
    padding-bottom: 20px;
  }

  &-toggle {
    position: absolute;
    z-index: 1;
    right: 6px;
    top: 6px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &-logo {
    &-app {
      display: none;
    }

    &-vnf {
      display: block;
      margin: 0 auto;
      width: 100%;
      padding: 0 14px;
    }
  }

  &-logout {
    width: fit-content;
    margin: auto;
    padding: 7px 10px;
  }

  #icon-shutters {
    rect {
      transition: all 0.2s ease-in-out;
      width: 3px;
    }
  }

  &.sidenav-deployed {
    width: 215px;
    overflow-y: auto;
    overflow-x: hidden;

    .vnf-sidenav-toggle-container {
      width: 215px;

      &:before {
        width: 215px;
      }
    }

    .vnf-sidenav-menu-container {
      &:before {
        width: 215px;
      }
    }

    .vnf-sidenav-logo-app {
      display: block;
      margin: 0 auto;
      width: 100%;
      padding: 0 14px;

      &-link {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .vnf-sidenav-icon {
      margin-left: 0;
      flex: 0 0 40px;
    }

    .vnf-sidenav-list {
      li {
        a {
          font-size: 1.5rem;

          @media (max-width: 390px) {
            font-size: 2.0rem;
          }
        }
      }

      &-toggle {
        text-align: left;
        padding: 0 35px;

        &:before {
          content: '';
        }
      }
    }

    #icon-shutters {
      rect {
        width: 8px;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;

    li {
      display: none;
      flex-direction: row;

      &:first-child {
        margin-top: 17px;
      }

      a {
        display: flex;
        height: 40px;
        width: 100%;
        align-items: center;
        font-family: "Roboto", sans-serif;
        font-size: 0;
        line-height: 1;
        font-weight: 400;
        color: white;
        text-decoration: none;
        padding: 10px;

        .vnf-icons-icon-modify {
          width: 40px;
          height: 40px;
          background-color: #FFFFFF1A;
          border-radius: 100%;
        }

        &.active {
          font-weight: 500;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            display: block;
            width: 3px;
            height: 30px;
            background-color: white;
          }

          .vnf-icons-icon-accessibility {
            &:before {
              content: "\e917";
              color: #fff;
            }
          }

          .vnf-icons-icon-home {
            &:before {
              content: '\e915';
            }
          }

          .vnf-icons-icon-news {
            &:before {
              content: '\e913';
            }
          }

          .vnf-icons-icon-menu-identity {
            &:before {
              content: '\e90d';
            }
          }

          .vnf-icons-icon-menu-cap {
            &:before {
              content: '\e909';
            }
          }

          .vnf-icons-icon-menu-daily {
            &:before {
              content: '\e90b';
            }
          }

          .vnf-icons-icon-collabs {
            &:before {
              content: '\e901';
            }
          }

          .vnf-icons-icon-modify {
            &:before {
              content: '\e90f';
            }
          }
        }
      }
    }

    &-toggle {
      position: relative;
      font-weight: 700;
      color: white;
      padding: 0;
      width: 100%;
      font-size: 1.6rem;
      line-height: 1.17;

      @media (max-width: 390px) {
        font-size: 2.4rem;
      }

      &:before {
        content: none;
        position: absolute;
        left: 12px;
        transform: translateY(-70%) rotate(-135deg);
        display: block;
        width: 9px;
        height: 9px;
        border-bottom: 1px solid white;
        border-left: 1px solid white;
        top: 50%;
        transition: transform 0.2s ease-in-out;
      }

      &.open {
        + ul {
          li {
            display: flex;
          }
        }

        &:before {
          transform: translateY(-70%) rotate(-45deg);
        }

        + .vnf-sidenav-list {
          max-height: unset;
        }
      }
    }
  }

  .vnf-sidenav-vnf-link {
    font-size: 0;
    margin-bottom: 100px;
  }

  &:not(.sidenav-deployed) {
    a {
      &.vnf-sidenav-vnf-link {
        position: relative;
        font-size: 0;

        .vnf-sidenav-menu-item {
          color: white;
          top: 50%;
          transform: translate(90%, -50%);
        }
      }
    }

    .vnf-sidenav-icon {
      margin: 0 auto;
    }
  }

  &-icon {
    margin-left: auto;
    font-size: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 17px
  }
}

.top-bar {
  display: none;

  &-toggle {
    margin-top: 10px;
    margin-left: 10px;
  }

  .vnf-sidenav-logo-app {
    display: none;

    @media (max-width: 768px) {
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);
      right: 50%;
    }
  }
}

@media (max-width: 768px) {
  .top-bar {
    display: block;
    height: 60px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    background: $vnf-degrade-header;
  }

  .vnf-sidenav {
    position: fixed;
    z-index: 1;
    transform: translateX(-100%);
    width: 100%;
    max-width: 390px;
    margin-top: 0;
    top: 60px;
    padding-top: 15px;

    &-toggle-container {
      display: none;
    }

    &-list-container {
      display: none;
    }

    &-vnf-link {
      display: none;
    }
  }

  .vnf-sidenav.sidenav-deployed {
    transform: translateX(0);
    width: 100%;

    .vnf-sidenav {
      &-list-container {
        display: block;
      }

      &-vnf-link {
        display: block;
      }
    }
  }
}

.vnf-sidenav-logo-mobile,
.vnf-sidenav-close-mobile {
  display: none;
}

.vnf-sidenav-logo-mobile {
  margin: 20px 0 5px;
}

.vnf-sidenav-close-mobile {
  justify-content: center;
  align-items: center;
  font-size: 0;
  width: 40px;
  height: 40px;
  margin-left: 10px;

  &:after,
  &:before {
    content: '';
    width: 24px;
    height: 2px;
    display: block;
    position: absolute;
    background-color: white;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
}

@media (max-width: 390px) {
  .vnf-sidenav {
    z-index: 2;
    top: 0;
  }

  .vnf-sidenav-logo-mobile,
  .vnf-sidenav-close-mobile {
    display: flex;
  }
}

div[class^="ng-scrollbar-thumb"] {
  border-radius: 3px !important;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.vnf-sidenav:hover {
  div[class^="ng-scrollbar-thumb"] {
    opacity: 1;
  }
}

.vnf-sidenav-menu-tooltip {
  font-family: "Roboto", sans-serif;
  display: none;
  position: absolute;
  right: 0;
  font-size: 1.4rem;
  background-color: black;
  border-radius: 5px;
  padding: 10px;
  transform: translate(90%, 0%);
  white-space: nowrap;
  color: white;

  &.active {
    display: block;
  }

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid black;
  }
}
