.breadcrumb {
    display: block;
    width: 100%;

    &_list {
        display: none;
        flex-wrap: wrap;
        margin-bottom: 20px;

        @media (min-width: 768px) {
            display: flex;
        }
    }

    &_item {
        @include paragraph();

        &:last-child {
            &:after {
                display: none;
            }
        }

        &:after {
            content: ">";
            padding: 0 10px;
        }

        &-active {
            @include paragraph();
        }
    }

    a {
        @include paragraph();

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

app-breadcrumb {
    display: block;
    width: 100%;
}

app-page-parente,
app-page-fille {
  position: relative;

  app-breadcrumb {
    position: absolute;
    top: 0;
    padding-top: 5px;
    padding-left: 60px;
    padding-right: 30px;
    pointer-events: none;
  }

  .breadcrumb_item {
    pointer-events: auto;
  }
}
