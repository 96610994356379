.flexible-push-reporting {
  max-width: 1163px !important;
  width: 100%;
  position: relative;

  &--with-title {
    max-width: 100% !important;

    .flexible-push-reporting--title {
      &:after {
        left: 0;
      }
    }
  }

  &--title {
    position: relative;
    font-size: 44px;
    font-weight: 300;
    padding-bottom: 20px;
    color: $vnf-blue-b800;
    margin-bottom: 25px;

    &:after {
      position: absolute;
      left: 10%;
      bottom: 0;
      font-size: 1.3rem;
      color: $vnf-green-b300;
      content: "\e91e";
      @include icon-font();
    }
  }

  .flexible-push-reporting--title {
    padding: 40px 10% 27px;
    color: white;
  }

  &--background {
    background-color: #3767CC;
    height: 240px;
    width: 100%;
    max-width: 1696px;
    position: absolute;
    left: 0px;
  }

  &--container {
    margin: 0 10%;
  }

  &--pushs {
    position: relative;
    z-index: 1;
  }

  &--push--title {
    color: $vnf-blue-a800;
    font-family: "Museo Regular", sans-serif;
    font-weight: 800;
    font-size: 31px;
    line-height: 30px;
    padding: 110px 0 0 25px;

    @media (max-width: 1342px) {
      padding: 20px 0 0;
    }

    &:after {
      content: "";
      display: block;
      height: 4px;
      width: 24px;
      background: #86EC8B;
      transform: rotate(-11deg);
      margin: 12px 0;
    }
  }

  &--push--text {
    font-family: "Roboto", sans-serif;
    color: $vnf-blue-a900;
    font-size: 15px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.65px;
    padding-left: 25px;
    padding-right: 20px;

    @media (max-width: 1342px) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &--push--image {
    overflow: hidden;
    max-width: 530px;

    @media (max-width: 1342px) {
      max-width: unset;
    }

    img {
      transition: all 0.2s ease-in-out;
    }
  }

  &--push--content {
    position: relative;
    flex: 1;
  }

  &--push--thematic {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 20px;
    left: -25px;

    @media (max-width: 1342px) {
      position: initial;
      flex-direction: row;
    }
  }

  &--push {
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 13px;
    margin-bottom: 20px;
    max-width: 940px;
    position: relative;

    &:hover,
    &:focus {
      .flexible-push-reporting--push--image {
        img {
          transform: scale(1.05);
        }
      }
    }

    .thematic-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 60px;
      background: linear-gradient(180deg, #1DCC95 0%, #4B9FDC 100%);

      img {
        max-width: 38px;
      }
    }

    .thematic-text {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: "Museo", sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      letter-spacing: 0.37px;
      background-color: $vnf-blue-a700;
      padding: 0 20px;
      height: 60px;
      max-width: 260px;

      p {
        margin: 0;
      }
    }

    &:nth-child(even) {
        flex-direction: row-reverse;

      .flexible-push-reporting--push--thematic {
        position: absolute;
        right: -25px;
        flex-direction: row-reverse;

        .thematic-text {
          text-align: right;
        }
      }

      .flexible-push-reporting--link,
      .flexible-reporting-link {
        &[rel="external"] {
          &:before {
            left: 14px;
            right: unset;

            @media (max-width: 1342px) {
              right: 14px;
              left: unset;
            }
          }
        }
      }
    }

    @media (max-width: 1342px) {
      flex-direction: column;

      &:nth-child(even) {
        flex-direction: column;

        .flexible-push-reporting--push--thematic {
          flex-direction: row;
          position: initial;

          .thematic-text {
            text-align: left;
          }
        }
      }
    }

    picture, img {
      object-fit: cover;
      width: 100%;
    }
  }

  &--link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0;
    display: block;
    z-index: 3;

    &[rel="external"] {
      &:before {
        content: '';
        background: url(/assets/images/icons/external-link.svg) no-repeat;
        background-size: cover;
        display: block;
        width: 17px;
        height: 16px;
        position: absolute;
        top: 14px;
        right: 14px;
        left: unset;


        @media (max-width: 1342px) {
          top: 360px;
        }
      }
    }
  }
}
