
.pagination {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 11px;
  margin-top: 3.0rem;

  @media (max-width: 768px) {
    gap: 0;
  }

  &--link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 2.0rem;
    color: $vnf-blue-a700;
    width: 38px;
    height: 38px;

    &.active {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      color: white;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 38px;
        height: 38px;
        background-color: $vnf-blue-a700;
        border-radius: 100%;
        z-index: -1;
      }
    }

    &.dots-options {
      font-size: 0;

      &:before {
        font-size: 1.6rem;
        font-family: vnf-icons-v2;
        content: '\e9cd';
        color: #B2B2B2;
      }
    }
  }

  &--link.previous,
  &--link.next {
    font-size: 0;
  }

  &--link.previous {
    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border-left: 2px solid $vnf-neutral-400;
      border-top: 2px solid $vnf-neutral-400;
      transform: rotate(-45deg);
    }
  }

  &--link.next {
    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border-left: 2px solid $vnf-neutral-400;
      border-top: 2px solid $vnf-neutral-400;
      transform: rotate(135deg);
    }
  }

  &--link.disabled {
    pointer-events: none;
    &:before {
      border-left: 2px solid $vnf-neutral-200;
      border-top: 2px solid $vnf-neutral-200;
    }
  }

  &--ellipsis {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 2.0rem;
    color: $vnf-blue-a700;
  }
}
