.no-scroll {
  height: 100%;
  overflow: hidden;
}

.mobile-only {
  display: none !important;

  @media (max-width: 768px) {
    display: block !important;
  }
}

.mobile-hidden {
  @media (max-width: 480px) {
    display: none;
  }
}

.desktop-only {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.text-align-center {
  text-align: center;
}
