.redirection {
  display: flex;
  flex-direction: row;
  position: relative;
  box-shadow: 2px 2px 6px 0 #00000014;
  background-color: white;
  padding: 25px 20px 20px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: 86px 64px;
  background-position: 20px 23px;
  height: 100%;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    box-shadow: 4px 4px 6px 0px #0000001F;

    .redirection {
      &--title,&--text {
        color: $vnf-blue-a700;
      }
    }
  }

  &--content {
    margin-left: 5px;
  }

  &--title {
    color: $vnf-blue-a800;
    font-family: "Roboto", sans-serif;
    font-size: 2.4rem;
    line-height: 1.167;
    font-weight: 700;
    margin-bottom: 10px;
    transition: all 0.2s ease-in-out;
  }

  &--link {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &--text {
    color: $vnf-blue-a800;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    line-height: 1.125;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
  }
}
