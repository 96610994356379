.mat-mdc-dialog {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;
    z-index: 10;
  }

  &-close-button {
    position: relative;
    display: block;
    min-width: 68px;
    height: 61px;
    border: none;
    cursor: pointer;
    padding: var(--mat-dialog-content-padding);
    align-items: center;
    justify-content: center;
    font-size: 0;
    margin-left: auto;

    /* Optionnel : Ajouter un arrondi pour un effet de bouton circulaire */
    border-radius: 0;

    &:focus {
      outline: none;
    }

    /* Styles pour les traits de la croix */
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 22px; /* La hauteur de la croix est égale à la hauteur du bouton */
      background-color: $vnf-blue-a400; /* Couleur de fond */
      top: 50%;
      left: 50%;
      pointer-events: none;
    }
    &::before {
      transform: translate(-50%,-50%) rotate(45deg); /* Rotation du premier trait */
    }

    &::after {
      transform: translate(-50%,-50%) rotate(-45deg); /* Rotation du second trait */
    }
  }

  &-container {
    .mat-mdc-dialog-title {
      @include essentielSubtitle();
      color: $vnf-blue-a800;

      &:before {
        display: none;
      }
    }
  }

  .flexible-video {
    display: flex;
    justify-content: center;
  }

  &-panel:has(.flexible-video) {
    max-width: 788px !important;
    align-items: center !important;
    width: 100%;

    .mat-mdc-dialog-container {
      max-width: 788px;
      height: auto;
      margin: 0 85px;

      @media (max-width: 768px) {
        margin: 0 5px;
      }
    }
  }
}

.mat-mdc-dialog-content img {
  width: 100%;
  height: 90%;
  object-fit: cover;
}

dialog-send-email {
  .mat-mdc-form-field-type-mat-input {
    width: 100%;
  }

  .mat-mdc-input-element {
    font-family: "Roboto", sans-serif !important;
    font-size: 14px !important;
    height: 33px !important;
  }

  .mdc-text-field--outlined .mdc-floating-label {
    font-size: 12px !important;
    color: #6D6D6D !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
    border-color: #CBCACA !important;
  }

  .mat-mdc-dialog-container .mat-mdc-dialog-content {
    color: red !important;
  }
}

.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
  padding-top: 10px !important;
}

.dialog-title {
  margin-top: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.cdk-overlay-pane {
  &.mat-mdc-dialog-panel {
    margin: 0 15px;
  }
}
