.on-pictures {
  position: relative;
  background-color: white;
  padding: 14px 0 60px 15px;
  margin-right: -14px;

  @media (min-width: 768px) {
    margin-right: 0;
  }

  &__title {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.1rem;
    color: $vnf-blue-a800;
  }

  swiper-slide {
    max-width: 266px;
    padding-bottom: 10px;

    &:last-child {
      margin-right: 15px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    max-width: 266px;
    width: 100%;
    box-shadow: 2px 2px 6px 0 #00000014;
    border-radius: 6px;

    .news-secondary-tag {
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-img {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      line-height: 1;
      position: relative;
      overflow: hidden;
    }

    img {
      width: 100%;
      object-fit: cover;
      transition: all 0.2s ease-in-out;
    }

    &-content {
      display: flex;
      padding: 15px;
      align-items: center;
      height: 100px;
      background-color: white;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      p {
        font-family: "Museo Regular", sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: $vnf-blue-a800;
      }

      &.video {
        p {
          position: relative;
          display: flex;
          gap: 10px;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 22px solid $vnf-blue-a300;
          }
        }
      }
    }

    &-link {
      position: absolute;
      top: 2px;
      left: 0;
      bottom: 9px;
      right: 0;
      font-size: 0;
      z-index: 1;

      &:hover,
      &:focus {
        + .on-pictures__card {
          box-shadow: 4px 4px 6px 0px #0000001F;

          picture, img {
            transform: scale(1.04);
          }
        }
      }
    }
  }

  .swiper-navigation {
    display: flex;
    position: absolute;
    left: 50%;
    margin-top: 10px;
    gap: 5px;
    transform: translateX(-29px);

    @media (min-width: 391px) {
      left: unset;
      right: 21px;
      transform: unset;
    }

    @media (min-width: 768px) {
      right: 35px;
      transform: unset;
    }

    @media (min-width: 1343px) {
      left: 50%;
      transform: translateX(-45px);
    }
  }

  .swiper-button-next {
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      @include icon-font;
      content: "\e916";
      font-size: 2rem;
      color: $vnf-grey-swiper-btn;
    }
  }

  .swiper-button-prev {
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    transform: rotate(180deg);
    justify-content: center;
    align-items: center;

    &:after {
      @include icon-font;
      content: "\e916";
      font-size: 2rem;
      color: $vnf-grey-swiper-btn;
    }
  }

  .swiper-button-disabled {
    cursor: initial;

    &:after {
      color: $vnf-neutral-300;
    }
  }
}

.col-pictures {
  @media (max-width: 576px) {
    .on-pictures__title {
      display: none;
    }

    .on-pictures {
      margin: 0;
      padding: 0;
      background-color: transparent;

      &__card {
        max-width: unset;
      }
    }

    .on-pictures__no-slider {
      &--item {
        position: relative;
        margin-bottom: 10px;
      }
    }
  }
}
