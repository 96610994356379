.section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  h2 {
    @include sectionTitle();
    margin-bottom: 0;
  }
}
