.list__push-icon {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;

  &.slider {
    margin-bottom: 0;

    .list__push-icon__item {
      margin-bottom: 30px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex: 0 0 100%;
    box-shadow: 2px 2px 6px 0px #00000014;
    transition: all 0.2s ease-in-out;
    border-radius: 6px;

    &:hover,
    &:focus {
      box-shadow: 4px 4px 6px 0px #0000001F;
    }

    &__link {
      &[rel="external"] {
        &:before {
          content: url('/assets/images/icons/external-link.svg');
          position: absolute;
          top: 7px;
          right: 7px;
        }
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 60px;
    width: 60px;
    background: linear-gradient(180deg, #1DCC95 0%, #4B9FDC 100%);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    .icon {
      font-size: 2.8rem;
      color: white;
    }
  }

  &__text {
    padding: 15px 25px;

    p {
      font-family: "Roboto", sans-serif;
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 20px;
      color: $vnf-blue-a800;
      line-height: 1.35;
      margin: 0;
    }
  }

  .swiper-container {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    height: fit-content !important;

    > swiper-slide {
      height: fit-content !important;
    }

    &.swiper-active {
      height: 82px !important;

      > swiper-slide {
        height: 82px !important;
      }
    }

    &::part(button-next) {
      top: unset;
      bottom: 0;
      transform: rotate(90deg);
      right: 50%;
    }

    &::part(button-prev) {
      top: unset;
      bottom: 0;
      transform: rotate(-90deg);
      left: 50%;
    }
  }

  &::part(container) .swiper-slide {
    max-height: 82px !important;
  }
}
