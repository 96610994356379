.swiper-item {
    height: auto;

    > div {
        height: 100%; /* same height for all slides inside a swiper slider */
    }

    &-item {
        height: auto;

        > div {
            height: 100%; /* same height for all slides inside a swiper slider */
        }
    }
}

swiper-container {
    &::part(button-prev),
    &::part(button-next) {
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        padding: 6px;
        margin: 0;
        color: var(--swiper-navigation-color, $vnf-grey-swiper-btn);

        &:after {
            @include icon-font;
            content: "\e916";
            font-size: 2rem;
        }
    }

    &::part(pagination) {
        bottom: var(--swiper-pagination-bottom,0px);
    }

    &::part(bullet) {
        background: $vnf-blue-a700;
        margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 15px);
        opacity: 1;
    }

    &::part(bullet-active) {
        background: $vnf-blue-a700;
        margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 15px);
        width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size, 13px));
        height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size, 13px));
        position: relative;
        top: 2px;
    }

    &:empty {
        display: none;
    }

    &.vertical {
        padding-bottom: 30px;

        &::part(button-prev) {
            top: inherit;
            left: inherit;
            bottom: 3px;
            right: calc(50% + 20px);
            transform: translateX(50%) rotate(-90deg);
        }

        &::part(button-next) {
            top: inherit;
            bottom: 3px;
            right: calc(50% - 20px);
            transform: translateX(50%) rotate(90deg);
        }
    }

    &.horizontal {
        &::part(container) {
            padding-bottom: 36px;
        }

        &::part(button-prev) {
            top: inherit;
            left: inherit;
            right: calc(50% + 20px);
            bottom: 0;
            transform: translateX(50%) rotate(180deg);

            @media (min-width: 391px) {
                right: 75px;
            }
        }

        &::part(button-next) {
            top: inherit;
            right: calc(50% - 20px);
            transform: translateX(50%);
            bottom: 0;

            @media (min-width: 391px) {
                right: 35px;
            }
        }
    }
}
