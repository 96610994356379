.events {
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  position: relative;
  background-color: $vnf-neutral-50;
  padding: 15px 0 18px 15px;
  column-gap: 10px;
  margin-bottom: 0;
  overflow: hidden;
  margin-right: -14px;

  @media (min-width: 768px) {
    margin-right: 0;
    padding: 15px 15px 18px 15px;
  }

  @media (min-width: 1343px) {
    flex-direction: row;
    padding: 15px 0 0 0;
  }

  h3 {
    width: 100%;

    @media (min-width: 1343px) {
      padding: 0 15px;
    }
  }

  swiper-container.vertical {
    @media (min-width: 1720px) {
      padding-bottom: 0;
    }
  }

  .event {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 270px;
    min-height: 120px;
    box-shadow: 2px 2px 6px 0 #00000014;
    border-radius: 6px;
    height: 95px;
    background-color: white;

    @media (min-width: 1720px) {
      height: 95px !important;
    }

    @media (min-width: 1343px) {
      margin: 0 15px;
      width: calc(100% - 30px);
      height: calc(50% - 7px);
      min-height: inherit;
    }

    &--date {
      display: flex;
      flex-direction: column;
      flex: 0 0 44px;
      width: 44px;
      background: rgb(121,164,255);
      background: linear-gradient(0deg, rgba(121,164,255,1) 4%, rgba(55,103,204,1) 4%);
      color: white;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      padding: 7px;

      .day {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 2.7rem;
        line-height: 1.185;
        letter-spacing: -0.09125rem;
      }

      .month {
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 1.077;
        text-transform: uppercase;
        margin-bottom: 3px;
      }

      .year {
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 1.182;
        letter-spacing: 0.0275rem;
      }
    }

    &--content {
      padding: 10px;
      padding-right: 40px;
    }

    &--tag {
      margin-bottom: 4px;
    }

    &--paragraph {
      @include cardTextSmall();
      font-size: 1.5rem;
      line-height: 1.2;
      font-family: "Museo Regular", sans-serif;
      margin: 0;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 1343px) {
        -webkit-line-clamp: 4;
      }
    }

    &--options-opener {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 0;
      width: 30px;
      height: 30px;

      &:before {
        content: url('/assets/images/icons/options.svg')
      }
    }

    &--alert-message {
      position: absolute;
      bottom: 10px; /* place le message en bas de la card */
      left: 50%;
      transform: translateX(-50%);
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
      animation: slideInFromBottom 0.7s ease-out forwards;

      &.success {
        background-color: #28a745;
      }

      &.error {
        background-color: #dc3545;
      }
    }

    @keyframes slideInFromBottom {
      from {
        transform: translateX(-50%) translateY(100%);
        opacity: 0;
      }
      to {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
      }
    }


  }

  .swiper-container {
    height: auto;
    max-width: 100%;
    overflow: hidden;

    &.vertical {
      &::part(container) {
        position: static;
        height: 225px;

        @media (min-width: 1720px) {
          height: 140px;
        }
      }

      .swiper-item {
        width: 100%;

        @media (min-width: 1720px) {
          display: flex;
          width: 100%;
        }

        .event {
          width: calc(100% - 30px);
          height: calc(50% - 7px);

          @media (min-width: 1720px) {
            max-width: 50%;
            height: 100%;
            margin: 0 15px 0 5px;
          }

          &:first-child {
            margin-bottom: 10px;

            @media (min-width: 1720px) {
              margin: 0 5px 0 15px;
            }
          }

          &:only-child {
            @media (min-width: 1720px) {
              max-width: calc(50% - 20px);
            }
          }
        }
      }
    }
  }

  .swiper-item {
    padding-bottom: 10px;
    padding-top: 5px;
    width: 270px;
  }

  swiper-container.horizontal .swiper-wrapper {
    transform: unset !important;
  }
}

.col-agenda {
  @media (max-width: 576px) {
    h3 {
      display: none;
    }
  .events {
      margin-right: 0;
      padding: 0;
      background-color: rgba(238, 238, 238, 1);

      .swiper-item {
        width: 100%;
      }
    }
  }
}

.events_no-swiper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.event_no-swiper {
  position: relative;
  background-color: #fff;
  border-radius: 6px;

  app-event {
    display: flex;
    flex-direction: row;

    .event--date {
      flex: 0 0 44px;
      width: 44px;
    }
  }
}
