.flexible-text-img {
  &-picture {
    overflow: hidden;
  }

  img {
    transition: 0.2s ease-in-out all;
    display: block;
    max-width: 100%;
    height: auto;
  }

  &--link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 0;

    &[rel="external"] {
      &:after {
        content: "";
        position: absolute;
        right: 14px;
        top: 14px;
        display: block;
        background-image: url("/assets/images/icons/external-link-white.svg");
        background-size: 14px 14px;
        background-repeat: no-repeat;
        width: 15px;
        height: 14px;
        margin-top: 2px;
      }
    }
  }

  figure {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    @media (min-width: 576px) {
      flex-direction: row;
      max-width: 930px;

      & > * {
        flex: 1 0 50%;
      }
    }

    &.right {
      .flexible-text-img--link {
        &[rel="external"] {
          &:after {
            content: "";
            position: absolute;
            left: 14px;
            top: 14px;
            display: block;
            background-image: url("/assets/images/icons/external-link-white.svg");
            background-size: 14px 14px;
            background-repeat: no-repeat;
            width: 15px;
            height: 14px;
            margin-top: 2px;
          }
        }
      }
    }

    figcaption {
      background: $vnf-blue-b700;
      color: white;
      display: block;
      padding: 20px;

      @media (min-width: 576px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      p {
        font-family: "Roboto", sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.21;
        color: $vnf-blue-a800;
        color: white;
        margin-bottom: 3px;

        @media (min-width: 768px) {
          font-size: 1.6rem;
          line-height: 1.5;
        }
      }

      span {
        align-self: center;
        width: auto;
        flex: 0 0 auto;
      }

      h3 {
        margin-bottom: 0;
      }
    }

    .right {
      order: 2;
      text-align: right;

      @media (min-width: 576px) {
        order: unset;
      }

      span {
        align-self: flex-end;
      }
    }

    .left {
      text-align: left;

      span {
        align-self: flex-start;
      }
    }

    .background-darker-blue {
      background: $vnf-blue-a800;
    }

    .background-green {
      background: $vnf-green-b800;
    }

    .background-blue {
      background: $vnf-blue-b800;
    }

    .btn {
      border-color: #FFF;
      color: #FFF;
      margin-top: 16px;
    }

    picture {
      overflow: hidden;
    }
  }

  figure:hover picture img,
  figure:focus picture img {
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }

  &-title {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: white;

    @media (min-width: 768px) {
      font-size: 2.7rem;
      line-height: 1.5;
      font-weight: 400;
    }

    time {
      display: block;
      font-weight: 300;

      @media (min-width: 768px) {
        font-size: 1.6rem;
      }
    }
  }
}
