.rte {
  display: flex;
  flex-direction: column;
  height: 100%;

  .icon {
    font-size: 1.9rem;
    margin-right: 10px;
  }

  > div {
    margin-bottom: 30px;
  }

  .icon-text {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: #234BA3;
  }

  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #234BA3;

    &.mb {
      margin-bottom: 10px;
    }
  }

  h1,h2,h3,h4 {
    color: #234BA3;
  }

  ul {
    margin-bottom: 10px;
    color: #234BA3;
  }

  li {
    font-size: 1.2rem;
    list-style: disc;
    list-style-position: inside;
  }

  .btn {
    display: block;
    margin: auto auto 0;
    width: fit-content;
  }
}
