.searchbar {
  padding: 15px 15px 4px 15px;
  background-color: $vnf-blue-a800;
  border-radius: 6px;
  margin-bottom: 38px;

  @media (max-width: 768px) {
    border-radius: 0;
  }

  &-layout {
    padding: 30px 30px 0 30px;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: 1342px) {
      max-width: 740px;
      padding: 77px 15px 0;
      margin: 0 auto;
    }

    @media (max-width: 768px) {
      padding: 60px 0 20px;
      margin-left: 0;
      margin-right: 0;
      max-width: unset;
    }
  }

  &__field {
    width: 100%;

    .mdc-text-field {
      position: relative;
      padding: 0 20px 0 50px;

      &:before {
        position: absolute;
        left: 15px;
        top: 10px;
        content: url("/assets/images/icons/icons-titles/magnifier.svg");
      }
    }

    &.mat-mdc-form-field {
      font-family: 'Roboto', sans-serif;
      font-size: 1.7rem;
      font-weight: 400;
      line-height: 2.0rem;
      color: #525252;
    }

    .mat-mdc-form-field-infix {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mdc-text-field--filled {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &__submit {
    &--container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: sticky;
      padding: 23px 20px;
      bottom: -10px;
      z-index: 1;
      background-color: #FFFFFF;

      @media (max-width: 768px) {
        display: none;
      }

      .submit-info {
        font-family: Roboto, sans-serif;
        margin: 0;
        font-size: 1.6rem;
        color: $vnf-neutral-600;
      }
    }
  }

  &__delete-search {
    font-size: 0;
    display: none;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    &:before {
      content: '\e958';
      display: block;
      font-family: vnf-icons-v2, sans-serif;
      font-size: 1.3rem;
      color: $vnf-neutral-700;
    }

    &.search-not-empty {
      display: flex;
    }
  }

  &__trends {
    padding-top: 11px;
    padding-bottom: 11px;
    width: 100%;
    min-height: 42px;
    overflow-x: auto;
    white-space: nowrap;

    @media (pointer: coarse) {
      /* Désactive la scroll bar si le touch est disponible */
      scrollbar-width: none;
    }

    &--list {
      display: flex;
      gap: 10px;
    }

    &--item {
      flex: 0 0 auto;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;

      &.hidden {
        opacity: 0;
      }
    }
  }
}

.cdk-overlay-pane {
  div.mat-mdc-autocomplete-panel {
    position: relative;
    transform: translateY(-10px);
    box-shadow: 2px 2px 6px 0px #00000014;
    max-height: 280px;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: calc(100% - 40px);
      left: 20px;
      height: 1px;
      top: 3px;
      background-color: #EEEEEE;
    }
  }

  .mat-mdc-option {
    padding: 0 20px 0 50px;

    .mdc-list-item__primary-text {
      font-size: 1.6rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1.8rem;

      .autocomplete-option-tags {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 5px;
        padding-left: 15px;
      }
    }

    &:before {
      font-family: vnf-icons-v2, sans-serif;
      content: '\e977';
      display: block;
      position: absolute;
      left: 16px;
      font-size: 1.7rem;
      color: $vnf-blue-a400;
    }

    &.previously-searched {
      &:before {
        content: '\e97b';
        left: 14px;
        font-size: 2.1rem;
        color: $vnf-blue-a400;
      }
    }
  }

  mat-option.mat-mdc-option {
    border-bottom-color: #faf9f9;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .mat-mdc-option:hover:not(.mdc-list-item--disabled),
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background-color: #F9F9F9 !important;
    color: #1A3175;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--mat-option-label-text-color, var(--mat-app-on-surface));
  }

  .mat-pseudo-checkbox {
    display: none;
  }

}

app-recherche {
  .searchbar {
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }
}
