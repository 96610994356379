.mes-apps {
  font-size: 1.5rem;
  margin: 30px;
  padding: 30px;
  display: block;
  background-color: white;

  @media (max-width: 768px) {
    margin: 50px 0 0;
  }

  .line-1 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.8rem;
    letter-spacing: 0.7px;
    color: $vnf-blue-a5;
  }

  .line-2 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.7px;
    color: $vnf-blue-a5;
    margin: 0 0 3rem;
  }

  &-list {
    display: grid;
    flex-wrap: wrap;
    gap: 20px;
    grid-template-columns: repeat(6,  1fr);
    margin-top: 55px;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3,  1fr);
    }


    @media (max-width: 576px) {
      grid-template-columns: repeat(2,  1fr);
    }

    p {
      cursor: pointer;
    }
  }

  &-list__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #CEE2FF;
    border-radius: 6px;
    height: 100px;

    p {
      display: -webkit-box;
      display: box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    &.favorite {
      .mes-apps__btn-favorite {
        &:before {
          background-image: url('/assets/images/icons/star-full.svg');
        }
      }
    }
  }

  .pagination {
    display: flex;
    position: relative;
    width: fit-content;
    margin: 30px auto;

    &--link.active {
      background-color: $vnf-blue-a2;
    }
  }

  &__btn-favorite {
    position: absolute;
    top: 7px;
    right: 5px;
    font-size: 0;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-image: url('/assets/images/icons/star.svg');
      background-repeat: no-repeat;
    }
  }
}
