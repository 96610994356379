
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$vnf-et-moi-2024-front-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($vnf-et-moi-2024-front-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($vnf-et-moi-2024-front-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($vnf-et-moi-2024-front-theme);
@import "00-base/tools";
@import "00-base/reset";
@import "00-base/colors";
@import "00-base/fonts";
@import "00-base/fonticons";
@import "00-base/typography";
@import "00-base/variables";
@import "00-base/buttons";

@import "01-atoms/action";
@import "01-atoms/section-title";
@import "01-atoms/tag";
@import "01-atoms/modales";
@import "01-atoms/card";
@import "01-atoms/breadcrumb";
@import "01-atoms/edito";
@import "01-atoms/options";
@import "01-atoms/pagination";
@import "01-atoms/bando_interation_top";
@import "01-atoms/bloc_title_select";

@import "02-molecules/flexible-content/flexible-general";
@import "02-molecules/flexible-content/flexible-text";
@import "02-molecules/flexible-content/flexible-img";
@import "02-molecules/flexible-content/flexible-panel";
@import "02-molecules/flexible-content/flexible-highlight";
@import "02-molecules/flexible-content/flexible-push-card";
@import "02-molecules/flexible-content/flexible-text-img";
@import "02-molecules/flexible-content/flexible-datavisualisation";
@import "02-molecules/flexible-content/flexible-profils-card";
@import "02-molecules/flexible-content/flexible-reporting";
@import "02-molecules/flexible-content/flexible-push-reporting";
@import "02-molecules/flexible-content/flexible-push-portrait";
@import "02-molecules/flexible-content/flexible-push-medias";
@import "02-molecules/flexible-content/flexible-push-page";
@import "02-molecules/flexible-content/flexible-video";
@import "02-molecules/flexible-content/flexible-arches";
@import "02-molecules/flexible-content/flexible-form-contact";
@import "02-molecules/flexible-content/flexible-push-service";
@import "02-molecules/flexible-content/flexible-flash-info";
@import "02-molecules/flexible-content/flexible-list-links";
@import "02-molecules/flexible-content/flexible-similar-articles";
@import "02-molecules/flexible-content/flexible-spacer";
@import "02-molecules/flexible-content/flexible-separateur";
@import "02-molecules/components/swiper";
@import "02-molecules/components/material-design/dialog";
@import "02-molecules/components/material-design/select";
@import "02-molecules/components/material-design/tabs";
@import "02-molecules/essentiel-hp";
@import "02-molecules/news";
@import "02-molecules/en-images";
@import "02-molecules/essentiel-module";
@import "02-molecules/essentiel-list";
@import "02-molecules/card-select-list";
@import "02-molecules/breves";
@import "02-molecules/events";
@import "02-molecules/header-page";
@import "02-molecules/zooms";
@import "02-molecules/tools";
@import "02-molecules/layercard";
@import "02-molecules/redirections";
@import "02-molecules/overlay";
@import "02-molecules/personal-list";
@import "02-molecules/contact-cta";
@import "02-molecules/suggestions";
@import "02-molecules/modales";
@import "02-molecules/mes-apps";
@import "02-molecules/mes-services";
@import "02-molecules/service-intermediaire";
@import "02-molecules/collaborateurs";
@import "02-molecules/listcard";
@import "02-molecules/card-img";
@import "02-molecules/search";
@import "02-molecules/rte";
@import "02-molecules/participation";
@import "02-molecules/article";
@import "02-molecules/push-icon";
@import "02-molecules/filters-dropdown";
@import "02-molecules/searchbar";
@import "02-molecules/hp/news-headline-hp";
@import "02-molecules/home-search";
@import "02-molecules/document-request";
@import "02-molecules/timeline";

@import "03-organisms/sidenav";
@import "03-organisms/layout";
@import "03-organisms/responsive";
@import "03-organisms/child-page";
@import "03-organisms/not-found";
@import "03-organisms/preview";
