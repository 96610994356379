.flexible_list_links {
    max-width: 740px;

    &_item {
        border-bottom: 1px solid $vnf-neutral-200;
        padding: 20px 0 20px 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    &_link {
        @include flexText();
        font-size: 1.9rem;
        color: $vnf-blue-a600;
        position: relative;
        margin: 0;
        padding: 0 15px 0 0;
        display: block;

        &[rel=external] {
            &:after {
                content: "";
                background: url(/assets/images/icons/external-link.svg) no-repeat;
                background-size: cover;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 17px;
                height: 16px;
            }
        }
    }

    &_text1 {
        font-weight: 600;

    }

    &_text2 {
        display: block;
    }
}

.mat-accordion:has(.flexible_list_links) {
    max-width: 740px;
}