.essentiel-hp {
  background-color: $vnf-neutral-50;
  margin: 0 -30px;
  padding: 50px 30px 100px;

  @media (min-width: 740px) and (max-width: 1342px) {
    margin: 0;
  }

  &__subtitle {
    position: relative;
  }

  &__title {
    position: relative;
    font-family: Roboto;
    font-size: 2.1rem;
    font-weight: 700;
    line-height: 2.4rem;
    letter-spacing: 0.7px;
    color: $vnf-blue-a600;
    text-align: center;
    margin: 0 auto 50px;
    padding: 10px 30px;

    &:before {
      content: url('/assets/images/icons/corners.svg');
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &:after {
      content: url('/assets/images/icons/icons-shapes/diamond_triangle_circle.svg');
      display: block;
    }
  }

  &__container {
    display: flex;
    gap: 30px;

    @media (max-width: 1342px) {
      flex-direction: column;
    }
  }

  &__col-left {
    display: flex;
    flex-direction: column;
    gap: 30px;

  }
  &__col-right {
    .list__push-icon__item {
      background-color: white;
    }
  }

  &__col-left,
  &__col-right {
    flex: 0 0 calc(50% - 15px);
    width: calc(50% - 15px);

    @media (max-width: 1342px) {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  &__diamond,
  &__triangle {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
    }
  }

  &__diamond {
    &:before {
      background-color: #FF9900;
    }
  }

  &__triangle {
    &:before {
      background-color: #79A4FF
    }
  }

  &__diamond,
  &__triangle {
    background-color: white;
  }

  &__circle {
    background-color: $vnf-blue-a100;
    padding-bottom: 30px;

    + .essentiel-hp__content {
      background-color: $vnf-blue-a100;

      .list__card__item::before {
        background-color: $vnf-blue-a700;
      }
    }
  }

  &__subtitle {
    display: flex;
    gap: 9px;
    padding: 15px 30px 15px;

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      font-family: 'Roboto', sans-serif;
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 2.8rem;
      color: $vnf-blue-a800;
      margin: 0;
    }

    &-text {
      grid-column-start: 2;
      grid-row-start: 2;
      font-family: 'Roboto', sans-serif;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.8rem;
      color: $vnf-blue-a800;
      margin-bottom: 0;
    }

    &-link {
      font-size: 0;
      display: block;
      position: absolute;
      top: 15px;
      left: 30px;
      right: 30px;
      bottom: 15px;

      &:hover,
      &:focus {
        + .essentiel-hp__subtitle-title {
          color: #3767CC;

          + essentiel-hp__subtitle-text {
            color: #3767CC;
          }
        }
      }
    }

    .essentiel-hp__subtitle-container:has(.essentiel-hp__subtitle-link:hover, .essentiel-hp__subtitle-link:focus) .essentiel-hp__subtitle-text {
      color: #3767CC;
    }

    &-container {
      display: grid;
      grid-template-rows: repeat(2, auto);
      grid-template-columns: 100px auto;
      width: 100%;

      @media (max-width: 480px) {
        display: flex;
        flex-wrap: wrap;

        .essentiel-hp__animation {
          position: absolute;
        }

        .essentiel-hp__subtitle-title {
          display: flex;
          align-items: center;
          padding-left: 110px;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        .essentiel-hp__subtitle-text {
          padding-top: 20px;
          flex: 1 0 100%;
        }

        .essentiel-hp__subtitle-content {
          justify-content: initial;
        }
      }

      @media (max-width: 320px) {
        .essentiel-hp__animation {
          position: initial;
        }

        .essentiel-hp__subtitle-title {
          padding-left: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
        .essentiel-hp__subtitle-text {
          padding-top: 0;
        }
      }
    }
  }

  &__circle {
    .essentiel-hp__subtitle {
      background-color: $vnf-blue-a700;

      &-title,
      &-text {
        color: white;
      }

      &-link {
        &:hover,
        &:focus {
          + .essentiel-hp__subtitle-title {
            color: #C2D6FF;
          }
        }
      }
    }

    .essentiel-hp__subtitle-container:has(.essentiel-hp__subtitle-link:hover, .essentiel-hp__subtitle-link:focus) .essentiel-hp__subtitle-text {
      color: #C2D6FF;
    }
  }


  &__content {
    padding: 0 30px 30px;

    @media (max-width: 768px) {
      padding: 0 15px 15px;
    }

    .card__item {
      margin-bottom: 30px;
    }

    .essentiel-hp__link-container {
      &:first-child {
        margin-top: -10px;

        @media (min-width: 992px) {
          margin-top: -20px;
        }
      }
    }
  }

  &__link-container {
    display: flex;
    justify-content: flex-end;
  }

  &__animation {
    display: block;
    float: left;
    margin-right: 10px;

    @media (max-width: 480px) {
      float: none;
      flex: 0 1 80px;
      margin-right: 6px;
    }
  }

  .list__card__item {
    max-width: 100%;
    flex: 0 0 100%;
    border-radius: 6px;

    p {
      @include flexText();
      color: $vnf-blue-a800;
      margin-bottom: 15px;
      line-height: 1.35;
    }
  }

  .tag {
    white-space: nowrap;
    border-radius: 11px;
    padding: 4px 9px;

    @media (max-width: 1342px) {
      padding: 17px 14px;
      border-radius: 30px;
    }

    &_list {
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 30px;

      @media (max-width: 1342px) {
        gap: 10px 6px;
      }
    }
  }

  .card__item__image {
    @media (min-width: 1343px) and (max-width: 1600px) {
      width: initial;
      height: initial;
    }
  }
}
