.flexible-form-contact {
  display: flex;
  flex-direction: column;
  max-width: 740px;
  width: 100%;

  &__title {
    @include titleFormStep();
  }


  .mat-mdc-text-field-wrapper {
    background-color: white;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
    border-color: white;
  }
}
