.btn {
  @include btn();

  &.btn--primary {
    position: relative;
    border: 1px solid $vnf-blue-a700;
    color: $vnf-blue-a700;

    &:hover,
    &:focus {
      color: white;
      background-color: $vnf-blue-a700;

      &[rel="external"] {
        &:after {
          content: url('/assets/images/icons/external-link-white.svg');
        }
      }
    }

    &[disabled] {
      background-color: white;
      color: $vnf-neutral-600;
      border-color: $vnf-neutral-600;
      cursor: initial;
      pointer-events: none;
    }

    &[rel="external"] {
      &:after {
        content: url('/assets/images/icons/external-link.svg');
        margin-left: 10px;
      }
    }
  }

  &.btn--secondary {
    border: 1px solid $vnf-blue-a700;
    background-color: $vnf-blue-a700;
    color: white;
  }

  &.btn--light {
    border: 1px solid white;
    background-color: transparent;
    color: white;
  }

  &.btn--outline-blue {
    font-family: Roboto, sans-serif;
    font-size: 1.4rem;
    color: $vnf-neutral-0;
    cursor: pointer;
    padding: 11px 15px;
    border: 1px solid $vnf-blue-a700;
    border-radius: 6px;
    background-color: transparent;

    &:hover {
      color: $vnf-neutral-0;
      border: 1px solid $vnf-neutral-0;
    }
  }

  &.btn--gradient {
    background: white;
    border: 1px solid transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    color: $vnf-blue-a800;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      background: linear-gradient(180deg, #1DCC95 0%, #4B9FDC 100%);
      z-index: -1;
    }

    &:hover,
    &:focus {
      color: white;
      background-color: $vnf-blue-a700;

      &:before {
        background: $vnf-blue-a700;
      }

      &[rel="external"] {
        &:after {
          content: url('/assets/images/icons/external-link-white.svg');
        }
      }
    }

    @media (max-width: 768px) {
      padding: 15px 20px;
    }

    &[rel="external"] {
      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        content: url('/assets/images/icons/external-link.svg');
      }
    }
  }

  &.btn-outline-big {
    width: 100%;
    text-align: center;
    justify-content: center;
    padding: 15px;
    width: 100%;
    max-width: 360px;
  }

  &[disabled] {
    cursor: initial;
    background-color: $vnf-neutral-600;
    border: 1px solid $vnf-neutral-600;
  }
}

.btn-xl {
  @include btn-xl();

  &[rel="external"] {
    &:after {
      content: url('/assets/images/icons/external-link-white.svg');
    }
  }

  &.btn--primary {
    background-color: $vnf-blue-a700;
    color: white;
    padding: 27px 67px;

    &:hover {
      background-color: #3C64B5;
    }

    &:focus {
      background-color: #30549E;
    }
  }
}

.wrapper-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  &.align-left {
    justify-content: flex-start;
  }

  &.align-right {
    justify-content: flex-end;
  }
}