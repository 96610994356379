.flexible-push-medias {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 16px;
  max-width: 100% !important;

  @media (max-width: 720px) {
    justify-content: center;
  }

  .flexible-push-media {
    cursor: pointer;
    box-shadow: 1px 1px 4px 0px #00000040;
    border-radius: 6px;
    flex: 0 1 338px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.2s ease-in-out;
    }

    &--img {
      width: 338px;
      height: 226px;
      border-radius: 6px;
      overflow: hidden;
    }

    &.video {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 50px solid white;
        z-index: 1;
        top: 85px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
      }
    }

    &:hover,
    &:focus {
      .flexible-push-media--img {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  .flexible-push-media-title {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 4px 0px #00000040;
    border-radius: 6px;
    flex: 0 1 338px;
    height: 338px;

    @media (max-width: 1342px) {
      height: unset;
    }

    &--img {
      position: relative;
      overflow: hidden;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      flex: 1;
    }

    img {
      width: 100%;
      object-fit: cover;
      transition: all 0.2s ease-in-out;
    }

    &--content {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      height: 112px;
      align-items: center;
      display: flex;

      p {
        color: white;
        font-size: 2.3rem;
        font-family: "Museo Regular", sans-serif;
        line-height: 2.7rem;
        text-align: center;
        margin: 0 auto;
        padding: 12px;
        overflow: hidden;
      }

      &.background-darker-blue {
        background-color: $vnf-blue-a800;
      }

      &.background-green {
        background-color: $vnf-green-b800;
      }

      &.background-blue {
        background-color: $vnf-blue-b800;
      }
    }

    &.video {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 50px solid white;
        z-index: 1;
        top: 85px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
      }
    }

    &:hover,
    &:focus {
      .flexible-push-media-title--img {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
}
