.news {
  &-hp {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media(min-width: 1600px) {
      .news-highlight {
        width: 100%;
      }
    }

    .news-highlight-tags-container {
      bottom: 110px;
    }

    .swiper-container {
      height: auto;
      max-width: 100%;
      overflow: hidden;

      &::part(container) {
        width: 100%;
      }

      &::part(button-next) {
        top: unset;
        bottom: -6px;
        left: unset;
        right: 0;
      }

      &::part(button-prev) {
        top: unset;
        bottom: -6px;
        left: unset;
        right: 30px;
        transform: rotate(180deg);
      }

      @media (max-width: 576px) {
          &::part(button-next),
          &::part(button-prev) {
            display: none;
          }
      }

      &::part(wrapper) {
        padding-bottom: 30px;

        @media (max-width: 768px) {
          padding-bottom: 0;
        }
      }

      .swiper-slide {
        min-width: 266px;
        max-width: 432px;
        padding-bottom: 40px;

        @media (max-width: 576px) {
          margin-right: 10px;
          width: 266px;
          padding-bottom: 0;
        }
      }
    }
  }

  &-highlight {
    position: relative;
    border-radius: 6px;
    flex: 1 1 auto;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      height: 146px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
      border-radius: 6px;
      z-index: 1;
    }

    picture, img {
      border-radius: 6px;
      display: block;
      transition: 0.25s linear;
    }

    &:hover,
    &:focus {
      box-shadow: 4px 4px 6px 0px #0000001F;

      picture, img {
        transform: scale(1.05);
      }
    }

    &-title {
      font-family: "Museo Bold", sans-serif;
      font-size: 2.8rem;
      line-height: 2.9rem;
      font-weight: 700;
      color: white;
      position: absolute;
      bottom: 30px;
      left: 30px;
      right: 30px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-content {
      position: absolute;
      z-index: 4;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: linear-gradient( 0.54deg, rgba(0, 0, 0, 0.58) 28.96%, rgba(0, 0, 0, 0) 48.04%);      }
    }

    @media (max-width: 576px) {
      width: 100%;
      height: 310px;

      img {
        height: 310px;
      }
    }

    &-tags-container {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &-important {
      margin-right: 100%;
    }
  }

  &-highlight,
  &-secondary {
    &-day {
      font-family: "Roboto", sans-serif;
      font-size: 2.7rem;
      line-height: 3.2rem;
    }

    &-month {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 1.4rem;
    }

    &-year {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.3rem;
      letter-spacing: 0.44px;
    }

    &-tags-container {
      position: absolute;
      bottom: 15px;
      left: 30px;
      display: flex;
      gap: 7px;
      align-items: flex-start;
    }

    &-thematic {
      font-family: "Roboto Condensed", sans-serif;
      color: white;
      background-color: $vnf-blue-a700;
      padding: 2px 4px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 1;
      letter-spacing: 0.5px;
    }

    &-important {
      display: inline-block;
      position: relative;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 2.5rem;
      text-transform: uppercase;
      color: $vnf-blue-a800;
      padding: 8px 8px 8px 14px;
      background-color: $vnf-neutral-50;
      margin-bottom: 10px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 5px;
        background-color: #FF9900;
      }
    }

    &-date {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 44px;
      height: 70px;
      background-color: $vnf-blue-a700;
      border-top-right-radius: 6px;
      text-align: center;
      color: white;
      border-bottom: 5px solid $vnf-green-b300;
    }

    &-date {
      time {
        display: flex;
        flex-direction: column;
      }
    }

    &-tag {
      font-family: "Roboto Condensed", sans-serif;
      color: white;
      background-color: $vnf-blue-a900;
      padding: 2px 4px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 1;
      letter-spacing: 0.5px;
    }

    picture, img {
      width: 100%;
      object-fit: cover;
      transition: all 0.2s ease-in-out;
    }

    &-link {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  &-secondary {
    position: relative;
    box-shadow: 2px 2px 6px 0px #00000014;
    background-color: white;
    border-radius: 6px;
    height: 100%;

    @media (max-width: 576px) {
      height: 310px;
    }

    picture, img {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      transition: all .25s linear;
    }

    .flexible-similar-articles-img-wrapper {
      overflow: hidden;
      height: 190px;
    }

    &:hover {
      box-shadow: 4px 4px 6px 0px #0000001F;

      picture, img {
        transform: scale(1.04);
      }
    }


    &-title {
      font-family: "Museo Regular", sans-serif;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 21px;
      color: $vnf-blue-a800;
      padding: 16px;
    }

    &-tags-container {
      flex-direction: column;
    }
  }

  &-headline,
  &-feed {
    background-color: $vnf-neutral-50;
    padding: 15px;

    @media (max-width: 767px) {
      background-color: transparent;
      padding: 15px 0;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__link {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      font-size: 0;
      z-index: 1;

      &:hover,
      &:focus {
        + .news-headline__img,
        + .news-feed__img {
          img {
            transform: scale(1.05);
          }
        }
      }
    }

    &__item {
      position: relative;
      display: flex;
      border-radius: 6px;
      background-color: white;
    }

    &__text {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 1.4rem;
      color: $vnf-blue-a800;
    }

    &__img {
      display: flex;
      position: relative;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      overflow: hidden;

      img {
        height: 100%;
        width: 181px;
        object-fit: cover;
        transition: all 0.2s ease-in-out;

        @media (max-width: 576px) {
          border-top-right-radius: 6px;
          border-bottom-left-radius: 0px;
          width: 100%;
          height: 176px;
        }
      }
    }

    &__date {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      width: 44px;
      height: 70px;
      background-color: $vnf-blue-a700;
      border-top-left-radius: 6px;
      text-align: center;
      color: white;
      border-bottom: 5px solid $vnf-green-b300;
      z-index: 1;
      padding-top: 2px;

      time {
        display: flex;
        flex-direction: column;
      }
    }

    &__day {
      font-family: "Roboto", sans-serif;
      font-size: 2.7rem;
      line-height: 3.2rem;
    }

    &__month {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 1.4rem;
      text-transform: uppercase;
    }

    &__year {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.3rem;
      letter-spacing: 0.44px;
    }

    &__content {
      background-color: white;

      .options {
        left: unset;
        right: 0;
        top: 30px;
        transform: translate(-40%, -10%);

        @media (max-width: 768px) {
          transform: unset;
          top: unset;
        }

        &-opener {
          z-index: 2;
        }
      }
    }

    .tag_list {
      margin-bottom: 10px;
    }
  }

  &-headline {

    @media (max-width: 767px) {
      padding: 0 0 20px;
    }

    &__title {
      font-family: "Museo Bold", sans-serif;
      font-weight: 600;
      font-size: 1.9rem;
      line-height: 2.2rem;
      color: $vnf-blue-a800;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__text {
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__item {
      position: relative;
      box-shadow: 2px 2px 6px 0px #00000014;

      &:hover,
      &:focus {
        box-shadow: 4px 4px 6px 0px #0000001F;

        @media (min-width: 769px) {
          .news-headline__content {
            background-color: $vnf-blue-a10;
          }
        }
      }

      @media (max-width: 576px) {
        flex-direction: column;
      }
    }

    &__link {
      font-size: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__item.important {
      .news-headline__img {
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 15px;
          background-color: #FF9900;

          @media (max-width: 576px) {
            width: 100%;
            height: 15px;
            background-color: #FF9900;
            top: unset;
            bottom: 0;
          }
        }
      }
    }

    &__content {
      margin: 10px 48px 10px 15px;
      padding: 24px 10px;
      border-radius: 6px;
      flex: 1;

      @media (max-width: 576px) {
        margin: 5px 28px 0px 5px;

        .options-opener {
          top: 190px;
        }

        .tag_list {
          position: absolute;
          top: 135px;
          height: 20px;
          overflow: hidden;
        }
      }
    }

    &__important {
      display: inline-block;
      position: relative;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 2.5rem;
      text-transform: uppercase;
      color: $vnf-blue-a800;
      padding: 8px 18px 8px 14px;
      background-color: $vnf-neutral-50;
      margin-bottom: 10px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 5px;
        background-color: #FF9900;
      }

      @media (max-width: 576px) {
        position: absolute;
        top: 85px;
      }
    }
  }

  &-feed {
    &__title {
      font-family: "Museo Bold", sans-serif;
      font-weight: 600;
      font-size: 1.7rem;
      color: $vnf-blue-a800;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 4px;

      @media (max-width: 767px) {
        -webkit-line-clamp: 2;
      }
    }

    &__date {
      @media (max-width: 767px) {
        height: 100%;
        align-items: flex-start;
        padding-top: 10px;
        border-bottom-left-radius: 6px;
      }
    }

    &__img {
      @media (max-width: 767px) {
        flex: 0 0 44px;

        img {
          display: none;
        }
      }
    }

    &__item {
      position: relative;
      box-shadow: 2px 2px 6px 0px #00000014;
      height: 130px;

      @media (max-width: 767px) {
        height: unset;
      }

      @media (max-width: 576px) {
        height: unset;
      }

      &:hover,
      &:focus {
        box-shadow: 4px 4px 6px 0px #0000001F;

        @media (min-width: 767px) {
          .news-feed__content {
            background-color: $vnf-blue-a10;
          }
        }
      }

      &.important {
        .news-feed__content {
          @media (max-width: 768px) {
            padding-left: 10px;
          }
        }

        .news-feed__img {
          position: relative;

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 15px;
            background-color: #FF9900;

            @media (max-width: 768px) {
              transform: translateX(100%);
            }
          }
        }

        .news-feed__text {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
    }

    &__link {
      font-size: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__important {
      display: inline-block;
      position: relative;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-transform: uppercase;
      color: $vnf-blue-a800;
      padding: 4px 4px 4px 8px;
      background-color: $vnf-neutral-50;
      margin-bottom: 3px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 5px;
        background-color: #FF9900;
      }
    }

    &__text {
      display: -webkit-box;
      display: box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }

    &__content {
      flex: 1;
      margin: 8px 48px 8px 15px;
      padding: 10px;
      border-radius: 6px;


      @media (max-width: 768px) {
        padding-left: 0;
      }

      @media (max-width: 576px) {
        margin: 8px 28px 8px 15px;
      }
    }
  }
}
