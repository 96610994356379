.mat-mdc-tab {
    &-group {
        width: 100%;
    }

    &-list {
        background: $vnf-blue-a700;
    }

    &-body {
        &-wrapper {
            background: $vnf-neutral-50;
            margin-bottom: 30px;

            .flexible-content {
                max-width: 740px;
                margin-inline: 30px;
                margin-top: 30px;
                margin-bottom: 20px;

              &.flexible-flash-info {
                max-width: 100%;
              }

              &.flexible-img > * {
                    padding-left: 0;
                    padding-right: 0;
                }

              &.flexible-img {
                margin-top: 0;
                padding-bottom: 0;
              }

              &:not(mat-mdc-tab-group) {
                max-width: calc(100% - 60px);
              }
            }

            .flexible-text {
                p,
                h2,
                h3,
                ul,
                li {
                    color: $vnf-blue-a800;
                }
            }

            .bloc_title_select {
                position: relative;
                padding-top: 60px;

                &:before {
                    content: "";
                    display: block;
                    height: 30px;
                    background: #eee;
                    position: absolute;
                    z-index: 10;
                    top: 0;
                    left: -15px;
                    right: -15px;

                    @media (min-width: 1343px) {
                        left: -30px;
                        right: -30px;
                    }
                }
            }
        }
    }

    &:hover,
    &:focus {
        .mdc-tab__ripple {
            &:before {
                opacity: 0 !important;
            }

            &:after {
                display: none !important;
            }
        }
    }

    &-ripple {
        display: none;
    }
}

.mdc-tab {
    &__text-label {
        @include sectionTitle();
        font-size: 1.6rem;
        text-transform: uppercase;
        color: white;
    }
}

.full-width-tabs {
    .mdc-tab {
      flex: 1; // equal width tab
      max-width: none;
      text-align: center;
      white-space: normal;
    }

    .mat-mdc-tab-labels {
      justify-content: space-between;
    }

    .mat-mdc-tab {
        height: auto;
        padding-top: 13px;
        padding-bottom: 13px;
    }
}

.main-layout {
    .mat-mdc-tab-header,
    .mat-mdc-tab-body-wrapper {
        @media (max-width: 768px) {
            margin-left: -14px;
            margin-right: -14px;
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }
}
