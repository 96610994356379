.flexible-flash-info {
    background: $vnf-blue-a50;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
      background-color: $vnf-blue-a100;
      box-shadow: 2px 2px 6px 0px #00000014;

      a {
        &:before {
          background: $vnf-blue-a500;
        }
      }
    }

    margin: 0 0 30px 0;

    &-description {
        margin: 0;
    }

    &-intro {
        text-transform: uppercase;
    }

    a {
        @include flexText();
        color: $vnf-blue-a800;
        padding: 10px 10px 10px 70px;
        margin: 0;
        min-height: 65px;
        display: flex;
        align-items: center;

        p {
            margin: 0;
        }

        &:before {
            @include icon-font();
            content: "\e919";
            color: white;
            width: 60px;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background: $vnf-blue-a400;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2.5rem;
            transition: all 0.2s ease-in-out;
        }

        &[rel=external] {
            padding: 10px 35px 10px 70px;

            &:after {
                content: "";
                background: url(/assets/images/icons/external-link.svg) no-repeat;
                background-size: cover;
                display: block;
                position: absolute;
                top: 12px;
                right: 12px;
                width: 15px;
                height: 14px;
            }
        }
    }
}
