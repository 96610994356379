.previous-page,
.share-page-btn {
  font-size: 0;
}

.share-page-btn {
  position: absolute;
  right: 0;

  &:before {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-image: url('/assets/images/icons/share.svg');
    background-repeat: no-repeat;
    background-position-x: right;
  }
}

.previous-page {
  font-size: 0;
  &:before {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-image: url('/assets/images/icons/arrow_left.svg');
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: 6px;
    background-size: 21px 17px;
  }
}
