.header-page {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 30px 20px;
  background-color: white;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding-top: 54px;
    padding: 54px 20px 30px 15px;
  }

  &:after {
    content: 'L\'Essentiel';
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 0.04375rem;
    line-height: 1.186;
    display: block;
    position: absolute;
    top: 25px;
    right: 25px;
    color: $vnf-blue-a600;
    width: 145px;
    background-image: url('/assets/images/shapes.svg');
    background-repeat: no-repeat;
    background-position: center right;
  }

  &--title {
    color: $vnf-blue-a800;
    font-family: "Roboto", sans-serif;
    font-size: 2.4rem;
    line-height: 1.167;
    font-weight: 700;
    margin-bottom: 10px;
    transition: 0.2s ease-in-out all;
  }

  &--text {
    color: $vnf-blue-a800;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 400;
    transition: 0.2s ease-in-out all;
  }

  &--links {
    position: relative;
    flex: 0 0 auto;
    width: 200px;
    height: 112px;
    background-position: top right;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      background-size: 86px 64px;
      width: 86px;
      height: 64px;
    }

    &.diamond {
      background-image: url('/assets/images/header-diamond.svg');

      .link-inf {
        bottom: 0;
        right: 55px;
      }

      .link-sup {
        top: 2px;
        left: -3px;
      }
    }

    &.triangle {
      background-image: url('/assets/images/header-triangle.svg');

      .link-inf {
        bottom: -6px;
        right: 50px;
      }

      .link-sup {
        top: -2px;
        left: -5px;
      }
    }

    &.circle {
      background-image: url('/assets/images/header-circle.svg');

      .link-inf {
        bottom: -4px;
        right: 97px;
      }

      .link-sup {
        top: -26px;
        left: -7px;
      }
    }

    .link-inf,
    .link-sup {
      font-size: 1.3rem;
      position: absolute;
      color: $vnf-neutral-700;
      transition: all 0.2s ease-in-out;
      text-decoration: none;
      padding: 5px 10px;
      border-radius: 16px;

      &:hover,
      &:focus {
        color: $vnf-blue-a800;
        background-color: $vnf-blue-a10;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:hover,
  &:focus {
    .header-page--title,
    .header-page--text {
      color: $vnf-blue-a700;
    }
  }
}
