.contact-cta,
.no-results-cta {
  margin-top: 60px;
  display: flex;
  padding: 25px 25px 35px;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  box-shadow: 2px 2px 6px 0px #00000014;
  background-color: white;

  .icon {
    font-size: 5.0rem;
    color: #3767CC;
  }

  p {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: $vnf-blue-a800;
    margin-top: 10px;
  }

  .btn {
    padding: 15px 20px;
    margin-bottom: 5px;
  }
}
