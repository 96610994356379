.flexible-profil {
    max-width: 100%;
    &-interaction {
        height: 30px;
        display: flex;
        justify-content: space-between;
    }

    &-link {
        font-size: 0;
        display: block;
        height: 30px;
        width: 30px;
        position: absolute;
        top: 3px;
        right: 3px;

        &:before {
            content: "";
            position: absolute;
            right: 8px;
            top: 14px;
            background-color: $vnf-blue-a400;
            width: 14px;
            height: 2px;
          }

          &:after {
            content: "";
            position: absolute;
            right: 14px;
            top: 8px;
            background-color: $vnf-blue-a400;
            height: 14px;
            width: 2px;
          }
    }

    &-opener {
        font-size: 0;
        display: block;
        height: 30px;
        width: 30px;
        position: absolute;
        top: 3px;
        left: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            @include icon-font();
            content: "\e917";
            color: $vnf-blue-a400;
            font-size: 2rem;
            position: relative;
        }
    }

    &-img {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 6px; /* épaisseur de la bordure */
        background: linear-gradient(180deg, #1DCC95 0%, #4B9FDC 100%);
        border-radius: 50%; /* arrondir la bordure si nécessaire */
        width: 80px;
        height: 80px;
        margin: 0 auto 20px;

      &:before {
        content: '';
        display: block;
        background-color: white;
        width: 68px;
        height: 68px;
        border-radius: 100%;
      }

      &:after {
        content: '';
        display: block;
        background-image: url('/assets/images/icons/picto-profil.svg');
        background-size: contain;
        z-index: 1;
        position: absolute;
        width: 34px;
        height: 42px;
        top: 50%;
        transform: translate(-50%,-50%);
        left: 50%;
      }

        img {
            z-index: 2;
            display: block;
            margin: 0;
            max-width: 100%;
            height: auto;
            border-radius: 50%;
        }
    }

    &-legend {
        p {
            //@include cardTextSmall();
            @include essentielTitle();
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    p {
        font-family: 'Roboto', sans-serif;
        font-size: 1.6rem;
        text-transform: none;
        font-weight: 400;
        line-height: 1.05;
        color: $vnf-blue-a800;
        overflow-wrap: break-word;

        &.line-1 {
            font-weight: 600;
        }

        &.line-2 {
            font-size: 1.4rem;
            font-weight: 400;
            margin-bottom: 15px;
        }

        &.line-3 {
            font-weight: 400;
            display: flex;
            text-align: center;
            justify-content: center;
            margin-bottom: 15px;
            transition: 0.25s all;

            &:hover,
            &:focus {
                text-decoration: underline;
                transition: 0.25s all;
            }
        }

        &.line-4 {
            color: $vnf-blue-a600;
            text-decoration: underline;
            transition: 0.25s all;

            &:hover,
            &:focus {
                text-decoration: none;
                transition: 0.25s all;
            }
        }
    }

    &-slider {
        position: relative;
        margin-bottom: 20px;

        .swiper-navigation {
            display: flex;
            position: absolute;
            gap: 5px;
            right: 21px;
        }

        .swiper-button-next {
            cursor: pointer;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                @include icon-font;
                content: "\e916";
                font-size: 2rem;
                color: $vnf-grey-swiper-btn;
            }
        }

        .swiper-button-prev {
            cursor: pointer;
            width: 32px;
            height: 32px;
            display: flex;
            transform: rotate(180deg);
            justify-content: center;
            align-items: center;

            &:after {
                @include icon-font;
                content: "\e916";
                font-size: 2rem;
                color: $vnf-grey-swiper-btn;
            }
        }

        .swiper-button-disabled {
            &:after {
                color: $vnf-neutral-300;
            }
        }

        swiper-slide {
          > div {
            height: 100%;
          }

          .card-item {
            margin-bottom: 5px;
          }

          @media (max-width: 768px) {
            max-width: 240px;
          }
        }
    }
}

.card-item {
    &:has(.flexible-profil) {
        height: 286px;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 30px 20px;
    }
}
