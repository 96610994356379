:root {
  /* Material dialog */
  --mdc-dialog-container-shape: 6px;
  --mat-dialog-headline-padding: 0 30px 15px;
  --mat-dialog-with-actions-content-padding: 0 30px 15px;
  --mdc-dialog-subhead-color: #{$vnf-blue-a800};
  --mdc-dialog-subhead-font: "Roboto", sans-serif;
  --mdc-dialog-subhead-size: 2rem;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-supporting-text-font: "Museo", sans-serif;
  --mdc-dialog-supporting-text-color: #{$vnf-blue-a800};
  --mdc-dialog-supporting-text-size: 1.8rem;
  --mdc-dialog-supporting-text-line-height: 2.5rem;
  --mat-dialog-actions-padding: 0 30px 20px 30px;
  /* Material Select */
  --mdc-filled-text-field-container-color: white;
  --mdc-filled-text-field-container-shape: 0;
  --mdc-filled-text-field-active-indicator-height: 0;
  --mdc-filled-text-field-container-shape: 5px;
  --mat-form-field-hover-state-layer-opacity: 0;
  --mdc-filled-text-field-focus-active-indicator-height: 0;
  --mdc-filled-text-field-label-text-font: "Roboto", sans-serif;
  --mdc-filled-text-field-label-text-size: 1.2rem;
  --mdc-filled-text-field-label-text-weight: 700;
  --mdc-filled-text-field-label-text-color: #{$vnf-blue-a800};
  --mdc-filled-text-field-hover-label-text-color: #{$vnf-blue-a800};
  --mdc-filled-text-field-focus-label-text-color: #{$vnf-blue-a800};
  --mat-form-field-container-height: 48px;
  --mat-form-field-container-tiny-height: 30px;
  --mat-form-field-filled-with-label-container-padding-top: 0;
  --mat-form-field-filled-with-label-container-padding-bottom: 0;
  --mat-select-enabled-arrow-color: #{$vnf-blue-a800};
  --mat-select-arrow-transform: 0;
  --mat-select-panel-background-color: white;
  --mat-select-container-elevation-shadow: 4px 4px 6px 0 #00000014;
  --mat-select-trigger-text-size: 1.4rem;
  --mat-select-enabled-trigger-text-color: #{$vnf-blue-a800};
  --mat-option-label-text-color: #{$vnf-blue-a800};
  --mat-option-label-text-size: 1.4rem;
  --mat-option-selected-state-layer-color: #{$vnf-blue-a800};
  --mat-option-selected-state-label-text-color: white;
  --mat-option-hover-state-layer-color: white;
  --mat-option-hover-state-layer-color: #{$vnf-blue-a800};
  --mat-option-focus-state-layer-color: #{$vnf-grey-select};
  --mat-form-field-subscript-text-line-height: 0;
  /* Material tabs */
  --mat-tab-header-inactive-label-text-color: white;
  --mat-tab-header-active-label-text-color: white;
  --mat-tab-header-active-hover-label-text-color: white;
  --mat-tab-header-active-focus-label-text-color: white;
  --mat-tab-header-inactive-focus-label-text-color: white;
  --mdc-tab-indicator-active-indicator-color: #{$vnf-green-b300};
  --mat-tab-header-active-hover-indicator-color: #{$vnf-green-b300};
  --mat-tab-header-active-focus-indicator-color: #{$vnf-green-b300};
  --mat-tab-header-active-ripple-color: #{$vnf-green-b300};
  --mat-tab-header-inactive-hover-label-text-color: white;
  --mdc-tab-indicator-active-indicator-height: 5px;
  /* Material input */
  --mdc-outlined-text-field-label-text-color: $vnf-neutral-700;
  --mat-autocomplete-background-color: white;
  --mat-form-field-container-vertical-padding: 6px;
  --mat-form-field-container-height: 44px;

  /* Material calendar */
  --mdc-text-button-label-text-size: 1.4rem;
  --mat-datepicker-calendar-header-text-size: 1.4rem;
  --mat-datepicker-calendar-body-label-text-size: 1.4rem;

  --scrollbar-thumb-color: #FFFFFFB3;
  --scrollbar-thickness: 8;
  --scrollbar-border-radius: 20;
}