/* Reset de base pour garantir une base uniforme à travers les navigateurs */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html, body {
  height: 100%;
  font-family: Arial, sans-serif;
}

body {
  font-size: 1rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0 0 1rem;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 1rem;
  font-weight: normal;
  line-height: 1.25;
}

ul, ol {
  margin: 0;
  padding-left: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  outline: inherit;
}

button:focus {
  outline: none;
}
