.layercard {
    &__wrapper {
        background-color: $vnf-neutral-50;
        width: 100%;
        padding: 15px 15px 10px;
        margin: 0 0 30px 0;

        @media (min-width: 768px) {
            padding: 30px;
        }

        h3 {
            @include cardText();
            margin-bottom: 20px;
        }

        p {
            @include flexText();
            color: $vnf-blue-a800;
            margin-bottom: 15px;
            line-height: 1.35;

            b,
            strong {
                font-weight: 700;
            }
        }

        .flexible-flash-info {
            margin-bottom: 20px;
        }
    }

    &__2cols {
        @media (min-width: 768px) {
            column-count: 2;
            column-gap: 20px;

            .layercard__item {
                margin: 0;
                margin-bottom: 0px;
                display: grid;
                grid-template-rows: 1fr auto;
                margin-bottom: 20px;
                break-inside: avoid;
            }
        }
    }

    &__item {
        box-shadow: 2px 2px 6px 0 #00000014;
        background-color: white;
        border-radius: 10px;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;
        transition: all 0.2s ease-in-out;

      picture {
        img {
          transition: all .25s linear;
        }
      }

      &:hover,
      &:focus {
        box-shadow: 4px 4px 6px 0 #0000001F;

        picture {
          img {
            transform: scale(1.04);
          }
        }
      }

        &.blue {
            transition: all 0.2s ease-in-out;
            background: $vnf-blue-a800;

            .layercard__item__title {
                color: white;
            }

            p {
                color: white;
            }

          &:hover,
          &:focus {
            background: $vnf-blue-a900;
            box-shadow: 2px 2px 6px 0px #00000014;
          }
          
          .layercard__item__link {
            &[rel="external"] {
              &:before {
                content: url(/assets/images/icons/external-link-white.svg);
                position: absolute;
                top: 7px;
                right: 7px;
              }
            }
          }
        }

        picture {
          overflow: hidden;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            transition: all 0.2s ease-in-out;
        }

        &__link {
            position: absolute;
            font-size: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;

          &[rel="external"] {
            &:before {
              content: url(/assets/images/icons/external-link.svg);
              position: absolute;
              top: 7px;
              right: 7px;
            }
          }
        }

        &__title {
            @include essentielTitle();
            font-size: 1.6rem;
            margin-bottom: 0;
            position: relative;
            text-align: left;
            align-self: flex-start;

            &:after {
                content: "";
                display: block;
                height: 4px;
                width: 24px;
                background: #86EC8B;
                transform: rotate(-11deg);
                position: absolute;
                bottom: -10px;
            }

            &__bloc {
                display: flex;
                margin-bottom: 15px;

                img {
                    width: auto;
                    height: auto;
                    display: inline-flex;
                    margin-right: 10px;
                }
            }
        }

        &__inner {
            padding: 25px 15px 10px;
        }
    }
}
