.layout-error {
  .error {
    display: flex;
    flex-direction: column;
    max-width: 1163px;
    margin-top: 100px;

    @media (max-width: 768px) {
      margin-top: 0;
    }

    &--visual {
      flex: 0 0 180px;
      height: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, #1DCC95 0%, #4B9FDC 100%);

      @media (max-width: 768px) {
        flex: 0 0 80px;
        height: 130px;
      }
    }

    &--content {
      background: linear-gradient(180deg, #1DCC95 0%, #4B9FDC 100%);
      display: flex;
      height: 240px;
      margin-bottom: 200px;

      @media (max-width: 768px) {
        height: 130px;
        margin-bottom: 50px;
      }
    }
  }

  h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 4.2rem;
    line-height: 1.172;
    align-self: baseline;
    color: #FFFFFF;
    margin: 80px 0 0 35px;
    position: relative;

    @media (max-width: 768px) {
      font-size: 2.8rem;
      margin-top: 30px;
    }

    &:after {
      bottom: -30px;
      position: absolute;
      left: 4px;
      font-size: 2.85rem;
      color: $vnf-green-b300;
      content: "\e92c";
      @include icon-font();
    }
  }

  .icon {
    font-size: 12rem;
    display: block;
    color: #FFFFFF;

    @media (max-width: 768px) {
      font-size: 5rem;
    }
  }

  p {
    font-family: "Museo", sans-serif;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.2rem;
    color: #234BA3;
    text-align: center;
  }

  a {
    width: fit-content;
    margin: 100px auto 0;

    @media (max-width: 768px) {
      margin: 30px auto 0;
    }
  }
}
