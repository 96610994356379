
.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-condensed-regular {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-condensed-bold {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Regular';
  src: url('../../fonts/Museo300-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Bold';
  src: url('../../fonts/Museo700-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
