.flexible-push-card {
    position: relative;
    width: 207px;
    max-width: 207px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 2px 2px 6px 0 #00000014;

    &:has(a[rel="external"]) {
        &:before {
            content: '';
            background-image: url('/assets/images/icons/external-link-white.svg');
            position: absolute;
            top: 12px;
            right: 12px;
            z-index: 4;
            pointer-events: none;
            width: 15px;
            height: 14px;
            display: block;
            background-size: cover;
        }
    }

    p {
        @include flexText();
        line-height: 1.3;
        margin-bottom: 0;
        padding: 5px 0;
        color: $vnf-blue-a800;
    }

  &:hover,
  &:focus {
    .flexible-push-card__img {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }

    &.flexible-push-doc {
        width: 100%;

      &:hover,
      &:focus {
        .flexible-push-card__img {
          img {
            transform: scale(1.05);
          }
        }
      }

        .flexible-push-card__img {
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $vnf-degrade-simple;
        }

        .flexible-push-card__bottom {
            background: $vnf-green-a100;
        }

         .flexible-push-card__img {
          &:before {
            content: '';
            background-image: url('/assets/images/icons/document.svg');
            width: 29px;
            height: 42px;
            display: block;
            background-size: cover;
            opacity: 50%;
          }

          &:has(img) {
            &:before {
              content: none;
            }
          }
        }
    }

    &.flexible-push-page {
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }

    &__top {
        position: relative;
        height: 140px;
    }

    &__bottom {
        background: $vnf-blue-a10;
        height: 136px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__tag {
        font-family: "Roboto Condensed", sans-serif;
        color: $vnf-blue-a800;
        background-color: white;
        padding: 5px 4px 4px 5px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 1;
        letter-spacing: 0.5px;

        &__thematic {
            font-family: "Roboto Condensed", sans-serif;
            color: white;
            background-color: $vnf-blue-a800;
            padding: 3px 4px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.3rem;
            line-height: 1;
            letter-spacing: 0.5px;
        }

        &__container {
            position: absolute;
            bottom: 10px;
            left: 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }
    }

    &__link {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 0;
    }

  &__img {
    position: relative;
    overflow: hidden;
    height: 100%;
    background: $vnf-degrade-simple;

    img {
      transition: all 0.2s ease-in-out;
    }
  }
}
