.home-search {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    position: static;
  }

  .profil {
    background: linear-gradient(180deg, #00cd83 -7.26%, #67bcce 42.69%, #419adb 83.01%, #00cdb4 99.26%) !important;
    height: 48px;
    width: 48px;
    border-radius: 100%;
    display: inline-block;

    @media (max-width: 480px) {
      position: fixed;
      top: 5px;
      right: 10px;
      z-index: 1001;
    }

    @media (max-width: 390px) {
      z-index: 999;
    }

    .profil-picture {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  .profil-onboard {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 2.2rem;
    line-height: 2.6rem;

    @media (max-width: 480px) {
      display: none;
    }
  }

  h1 {
    color: #fff;
    display: inline-block;
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .date {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    flex: 1 0 100%;
    text-align: right;
    color: #fff;
    margin: 0;

    @media (max-width: 480px) {
      display: none;
    }
  }
}
