app-actualites {
  .filters-dropdown__list {
    @media (max-width: 576px) {
      display: none;
    }
  }

  .filters-dropdown__mobile-actions {
    display: none;
  }
}

.filters-dropdown__list {
  &.loading {
    background-color: white;
    border-radius: 6px;
    position: relative;
    box-shadow: 2px 2px 6px 0 #00000014;
    overflow: hidden;
    background-image: none !important;
    pointer-events: none;
    cursor: unset;
    height: 83px;

    &::after {
      content: "";
      position: absolute;
      background-color: #EEE;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      top: 15px;
      left: 15px;
      border-radius: 3px;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(
                      90deg,
                      transparent,
                      rgba(255, 255, 255, 0.9),
                      transparent
      );
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      animation: loading 1.5s infinite;
    }

    .filters-dropdown, .filters-dropdown__date {
      display: none;
    }

  }
}

app-recherche {
  @media (max-width: 768px) {
    .filters-dropdown__list {
      display: none;
      flex-direction: column;
      position: fixed;
      z-index: 999;
      width: 100vw;
      left: 0;
      border-radius: 0;
      bottom: 0;
      top: 134px;
      overflow: hidden;

      &.opened {
        display: flex;
      }
    }

    .filters-dropdown {
      max-height: 50px;
    }

    .filters-dropdown__opener::after {
      transform: rotate(-90deg);
    }

    .filters-dropdown__popin {
      display: block;
      transform: translateX(100%) !important;
      left: 0 !important;
      transition: all 0.2s ease-in-out;
      width: 100vw !important;
      max-width: 100vw !important;
      right: unset !important;
      top: -20px;
      box-shadow: unset;

      &:before {
        content: none;
      }
    }

    .filters-dropdown__filters-list.double {
      grid-template-columns: 1fr;
    }

    .filters-dropdown__opener.open + .filters-dropdown__popin {
      transform: translateX(-10px) !important;
    }

    .filters-dropdown {
      &[data-type="theme"] {
        .filters-dropdown__popin {
          top: -10px !important;
        }
      }

      &[data-type="type-de-document"] {
        .filters-dropdown__popin {
          top: -80px !important;
        }
      }
    }

    .mat-mdc-form-field-infix {
      display: block;
    }

    .mat-date-range-input-inner {
      font-family: "Roboto", sans-serif;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.44px;
      color: #3767CC;
      padding: 10px 12px;
      border-radius: 4px;
      border: 1px solid rgba(55, 103, 204, 0.1019607843);
      width: 100%;
      text-align: left;
      height: 40px;
    }

    .mat-date-range-input-wrapper {
      overflow: visible;
      width: 100%;
      height: 100%;
    }

    .mdc-text-field--outlined .mat-mdc-form-field-infix,
    .mdc-text-field--no-label .mat-mdc-form-field-infix {
      padding: 0;
    }

    .mat-date-range-input-separator {
      font-size: 0;
    }
  }
}

.filters-dropdown {

  /* Add this to your component's CSS file */
  .date-selected-indicator {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
  }

  &__mobile-actions {
    position: absolute;
    justify-content: space-between;
    align-items: center;
    display: none;
    padding: 20px 10px 20px;
    width: 100%;
    background-color: $vnf-blue-a800;
    bottom: 0;
    left: 0;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  /* parent */
  &__list {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 40px;
    background: $vnf-neutral-50;
    border-radius: 6px;
    padding: 20px 10px 10px;
    gap: 10px;

    .mat-mdc-form-field-infix {
      display: none;
    }
  }

  flex: 1 0 auto;
  position: relative;
  gap: 10px;

  &:after {
    content: '';
  }


  /* self */
  &[data-type="theme"] {
    .filters-dropdown__popin {
      max-width: 870px;
      width: 100%;

      @media (max-width: 1342px) {
        max-width: 430px;
        width: calc(100vw - 140px);
      }
    }
  }

  &[data-type="perimetre"] {
    .filters-dropdown__popin {
      max-width: 550px;
      width: calc(100% + 200px);

      @media (max-width: 1342px) {
        max-width: 600px;
        width: calc(100vw - 140px);
      }
    }
  }

  &[data-type="type-de-document"] {
    .filters-dropdown__popin {
      max-width: 500px;
      width: calc(100% + 190px);

      @media (max-width: 1342px) {
        max-width: 590px;
        width: calc(100vw - 140px);
      }
    }
  }


  &[data-type="type-de-document"] {
    .filters-dropdown__popin {
      right: 0;
      left: unset;

      &::before {
        right: 20px;
        left: unset;
      }
    }
  }

  &[data-type="theme"] {
    @media (max-width: 1342px) {
      .filters-dropdown__popin {
        left: 50%;
        transform: translateX(-25%);
        right: unset;

        &::before {
          right: unset;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  &__item--dropdown {
    .filters-dropdown__item--dropdown-opener {
      position: relative;
      cursor: pointer;
      font-family: "Roboto Condensed", sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.7px;
      font-size: 1.4rem;
      padding: 11px 55px;
      display: block;
      width: 100%;
      background-color: #143477;
      color: white;

      &:before {
        position: absolute;
        left: 18px;
        content: '\e92b';
        font-family: 'vnf-icons-v2';
        font-size: 1.5rem;
        color: white;
        transform: translateY(-2px) rotate(-90deg);
        transition: all 0.2s ease-in-out;
      }

      &.active {
        &:before {
          transform: translateY(-2px);
        }

        + .filters-dropdown__item--sublevel {
          display: flex;
        }
      }
    }

    .filters-dropdown__item--sublevel {
      flex-wrap: wrap;

      .sublevel__item {
        position: relative;
        flex: 0 0 50%;
        padding: 8px 45px;
        font-family: 'Roboto', sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.44px;
        text-align: left;
        color: $vnf-blue-a800;

        &:before {
          content: "";
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 13.5px;
          height: 13.5px;
          border: 1px solid $vnf-blue-a800;
          border-radius: 2px;
        }
      }

      .sublevel__check-checkbox {
        position: absolute;
        width: 0;
        font-size: 0;
        opacity: 0;

        &:checked {
          + .sublevel__check-label {
            &:after {
              font-family: "vnf-icons-v2";
              content: '\e958';
              color: $vnf-blue-a800;
              position: absolute;
              left: 20.5px;
              font-size: 1.3rem;
            }
          }
        }
      }

      .sublevel__check-label {
        display: block;
        text-wrap: nowrap;
        width: 30px;

        &:before {
          cursor: pointer;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }

    .filters-dropdown__item--sublevel {
      display: none;
    }
  }

  &__label {
    position: absolute;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4rem;
    letter-spacing: 0.4px;
    top: -6px;
    left: 12px;
    color: $vnf-blue-a700;
  }

  &__popin {
    display: none;
    background-color: white;
    box-shadow: 1px 1px 8px 0px #00000040;
    top: 47px;
    position: absolute;
    padding: 20px 10px 10px;
    z-index: 11111;

    &:before {
      content: "";
      display: block;
      top: -10px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 11px solid white;
      position: absolute;
    }

    @media (max-width: 768px) {
      background-color: #F9F9F9;
    }
  }

  &__popin-mobile-header {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  &__popin-mobile-label {
    font-size: 1.9rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: $vnf-blue-a900;
    margin: unset;
  }

  &__popin-mobile-close {
    font-size: 0;
    display: block;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin: unset;

    &:before {
      content: '\e958';
      display: block;
      font-family: vnf-icons-v2, sans-serif;
      font-size: 1.3rem;
      color: $vnf-blue-a900;
    }
  }

  &__opener {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.44px;
    color: $vnf-blue-a700;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #3767CC1A;
    width: 100%;
    text-align: left;

    &:after {
      position: absolute;
      top: 11px;
      right: 20px;
      font-family: vnf-icons-v2;
      content: '\e93c';
      display: block;
    }


    &.open {
      &:after {
        content: '\e99b';
      }

      + .filters-dropdown__popin {
        display: block;
      }
    }
  }

  &__select-all,
  &__unselect-all {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: 0.4px;
    color: $vnf-blue-a800;

    @media (max-width: 768px) {
      color: white;
    }
  }

  &__filters-list__container {
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $vnf-neutral-200;
      margin-bottom: 24px;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &__filters-list {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 1px;

    &.double {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1px 10px;
    }
  }

  &__filters-list__container {
    @media (max-width: 768px) {
      max-height: 500px;
      overflow: auto;
    }
  }

  &__item {
    position: relative;
  }

  &__check-label {
    cursor: pointer;
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.7px;
    font-size: 1.4rem;
    padding: 11px 55px;
    display: block;
    width: 100%;
    background-color: $vnf-blue-a900;
    color: white;

    &:before {
      content: '';
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 13.5px;
      height: 13.5px;
      border: 1px solid white;
      border-radius: 2px;
    }
  }

  &__check-checkbox {
    position: absolute;
    width: 0;
    font-size: 0;
    opacity: 0;

    &:checked {
      + .filters-dropdown__check-label {
        &:after {
          font-family: "vnf-icons-v2";
          content: '\e958';
          color: white;
          position: absolute;
          left: 20.5px;
          font-size: 1.3rem;
        }
      }
    }
  }

  &__select-all,
  &__unselect-all {
    &[disabled] {
      cursor: initial;
      color: $vnf-neutral-700;
    }
  }

  &__date {
    position: relative;

    .date-selected-indicator {
      position: absolute;
      top: 22px;
      left: 23px;
      width: 10px;
      height: 10px;
      background-color: $vnf-green-b300;
      border-radius: 50%;
      z-index: 200;
    }
  }
}

app-actualites {
  .filters-dropdown[data-type="perimetre"] .filters-dropdown__popin {
    @media (max-width: 1342px) {
      transform: translateX(-66%);
    }
  }

  .cols-container:not(.cols-news) {
    @media (max-width: 767px) {
      flex-direction: row !important;
      gap: 15px !important;
    }

    @media (max-width: 576px) {
      flex-direction: column !important;
      gap: 10px !important;
    }

  }

  .col-half {
    @media (max-width: 767px) {
      flex: 0 0 calc(50% - 15px) !important;
      max-width: calc(50% - 15px) !important;
    }

    @media (max-width: 576px) {
      flex: unset !important;
      max-width: 100% !important;
    }
  }
}

.mat-datepicker-toggle-default-icon {
  color: #3767CC;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper,
.mat-mdc-form-field-icon-suffix {
  padding-left: 0 !important;
  background-color: $vnf-neutral-50;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}


.mat-calendar, .mat-calendar-body-cell-content, .mat-calendar-header, .mat-calendar-table, .mat-calendar-table-header, .mat-calendar-table-header-row, .mat-calendar-table-row, .mat-calendar-table-cell {
  font-size: 1.4rem;
}

.mat-datepicker-content-container {
  font-size: 1.4rem;
  background-color: white;
  box-shadow: 1px 1px 4px 0px #00000040;

  .mat-datepicker-actions {
    justify-content: inherit;

    button {
      border: 1px solid #3767CC;
      background-color: #3767CC;
      color: white;
      padding: 7px 20px;
    }

    .clear {
      float: left;
      position: relative;
      border: 1px solid #3767CC;
      color: #3767CC;
      background-color: white;

      &:hover {
        color: white;
        background-color: #3767CC;
      }
    }
  }
}