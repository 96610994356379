.modale {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000066;
  z-index: 1000;

  &.breve--modale {
    display: none;

    &.show {
      display: flex;
    }

    .modale--content {
      margin: 0 15px;
      max-width: 740px;
      padding: 35px 30px 20px;

      .modale--date {
        font-size: 1.5rem;
      }

      .modale--paragraph {
        font-size: 1.8rem;
        line-height: 1.5;
      }

      .btn {
        float: right;

        @media (max-width: 1342px) {
          padding: 14px 20px;
        }

        @media (max-width: 768px) {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  &--close {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 18px;
      background-color: $vnf-blue-a400;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &--date {
    display: block;
    margin-bottom: 5px;
    @include date();
  }

  &--paragraph {
    @include cardTextSmall();
  }

  &--content {
    position: relative;
    background-color: white;
    border-radius: 6px;
  }
}

.infography-content {
  padding-bottom: 10px;
}